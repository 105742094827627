import interact from 'interactjs';
import type { Interactable } from '@interactjs/core/Interactable';
import { EdgeOptions } from '@interactjs/types/index';
import { toDraggable } from './positioning';
import { toResizable } from './transform';

const baseModifiers = {
  square: interact.modifiers.aspectRatio({
    ratio: 1
  }),
  container: (restriction: any) =>
    interact.modifiers.restrictRect({
      restriction: restriction,
      endOnly: true
    })
};

export class InteractableElement {
  element: Interactable;

  constructor(selector: string) {
    this.element = interact(selector);
  }

  makeDraggable(restriction = 'parent', callback?: (event: any) => void) {
    this.element.draggable(toDraggable(restriction, callback));
  }

  makeResizable(
    callback?: (event: any) => void,
    square?: boolean,
    edges: EdgeOptions = { left: true, right: true, bottom: true, top: true },
    minSize = { width: 30, height: 30 }
  ) {
    const resizableDefinition: any = toResizable(minSize, callback, edges);
    if (square) resizableDefinition.modifiers.push(baseModifiers.square);
    this.element.resizable(resizableDefinition);
  }

  setAttribute(attribute: string, value: any) {
    const selector = this.element.target as string;
    const target = document.querySelector(selector);
    (target as any).setAttribute(attribute, value);
  }
}
