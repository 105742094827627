import { Module } from 'vuex';

import { actionsFactory } from './actions';
import { gettersFactory } from './getters';
import state, { pageInformationState } from './state';
import mutations from './mutations';
import VueI18n from 'vue-i18n';

export const pageModuleFactory = <S>(
  resources: {
    PAGE_INFORMATION: string;
  },
  i18n: VueI18n
): Module<pageInformationState, S> => ({
  namespaced: true,
  actions: actionsFactory(resources),
  getters: gettersFactory(i18n),
  mutations,
  state
});
