import { nully } from '@ligo/shared/utils';

const singleStep = (data?: number, limit?: number) => {
  if (data === undefined) return 1;
  else {
    if (limit && data > limit) return limit;
    else return data;
  }
};

export const capitalize = (text: string) => {
  if (text) return text[0].toUpperCase() + text.substr(1);
};

const filter = (value: string) => {
  if (value) return value.replace(/(BV|B.V.)$/g, '').trim();
};

export const getSteps = (uuid?: string, data?: any) => {
  let step = singleStep(data.step);
  let temporalStep = singleStep(data.temporal_step);
  let substep = singleStep(data.substep);
  if (uuid) {
    const steps = localStorage.getItem('stepsInfo');
    const stepsInfo: { [id: string]: any } = JSON.parse(steps ? steps : '{}');
    const stepData = stepsInfo[uuid];
    if (stepData?.step) {
      if (stepData.step > step + 1) {
        stepsInfo[uuid] = {
          step,
          temporalStep,
          substep
        };
        localStorage.setItem('stepsInfo', JSON.stringify(stepsInfo));
      } else {
        step = stepData.step;
        temporalStep = stepData.temporalStep || 0;
        substep = stepData.substep || 0;
      }
    }
  }
  return { step, temporalStep, substep };
};

export const addBVtoNewCompanies = (shareholderData) => {
  if (
    shareholderData['is_new_company'] &&
    !nully(shareholderData['company_name'])
  ) {
    const name = filter(shareholderData['company_name']) + ' B.V.';
    shareholderData['company_name'] = name;
  }
  return shareholderData;
};
