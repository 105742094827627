import { MutationTree } from 'vuex';
import { AssistantState } from './state';

const mutations: MutationTree<AssistantState> = {
  setPrepareDocumentAssistantActive(state, to: boolean) {
    state.prepareDocumentAssistant.isActive = to;
    localStorage.setItem('prepareDocumentAssistant', to.toString());
  },
  setBookkeepingAssistantActive(state, to: boolean) {
    state.bookkeepingAssistant.isActive = to;
    localStorage.setItem('bookkeepingAssistant', to.toString());
  }
};

export default mutations;
