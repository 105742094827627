




























































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { buildRule } from '@ligo/shared/mvc';
import { Dictionary } from '@ligo/shared/utils';

export default defineComponent({
  name: 'EmailConfirmation',
  props: {
    texts: {
      type: Object as PropType<Dictionary>,
      default: () => ({
        emailLabel: 'user.labels.email',
        emailPlaceholder: 'user.placeholders.email'
      })
    }
  },
  setup(props, { root, emit }) {
    const email = ref('');
    const confirmationSent = ref(false);

    const sendConfirmationEmail = () => {
      confirmationSent.value = true;
      root.$store.dispatch('authentication/cleanErrors');
      emit('send-confirmation-email', email.value);
    };

    const emailRules = [
      buildRule('required', 'validations.required_generic'),
      buildRule('email', 'validations.email_format')
    ];

    return {
      sendConfirmationEmail,
      emailRules,
      confirmationSent,
      email
    };
  }
});
