




















import { defineComponent } from '@vue/composition-api';
import BrandedI18n from './BrandedI18n.vue';
export default defineComponent({
  name: 'NewLogin',
  components: { BrandedI18n },
  props: {
    brand: {
      type: String,
      required: true
    },
    brandIcon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: String
  }
});
