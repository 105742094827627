export default {
  paid: 'Betaald',
  unpaid: 'Concept',
  open: 'In behandeling',
  library: {
    search: 'Zoek',
    go_back: 'Terug naar bibliotheek',
    use_template: 'Gebruik dit contract',
    empty: {
      title: 'We kunnen momenteel geen voorbeeld van dit document laden.',
      subtitle:
        'Je kan dit contract nog steeds genereren en gebruiken door op de groene knop te klikken.'
    }
  },
  template: {
    start_free: 'Gratis krijgen',
    start: 'Start hier',
    open_all: 'Open alle mappen',
    free_dialog: {
      title: 'Goede keuze. Dit eerste contract krijg je van ons!',
      subtitle: 'Word lid en ontgrendel 100+ contracten',
      btn_label: 'Maak document'
    },
    all_contract: 'Alle documenten',
    panel_title: 'Gebruik een document uit de contractenbibliotheek.',
    panel_subtitle:
      "Bescherm jouw bedrijf door te kiezen uit 100+ contracten gemaakt door Ligo's ervaren juristen.",
    lib_title: 'Templates',
    lib_subtitle:
      "Bescherm jouw bedrijf door te kiezen uit 100+ contracten gemaakt door Ligo's ervaren juristen.",
    show_all: 'Contractenbibliotheek'
  },
  actives: {
    title: 'Contracten',
    subtitle: 'Maak, onderteken en bewaar juridische documenten',
    create: 'Nieuw document',
    open: 'Open',
    intermediate: 'Intermediate',
    submitted: 'Submitted',
    delete_confirmation:
      'Weet u zeker dat u dit contract wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt en alle wijzigingen gaan verloren.',
    table: {
      name: 'Naam',
      status: 'Status',
      last_modified: 'Laatstgewijzigd',
      delete: 'Verwijderen',
      copy: 'Kopie maken'
    },
    all_contracts: 'Alle contracten'
  },
  preview: {
    declined: 'Declined',
    sign_now: 'Onderteken nu',
    download: 'Download',
    buy_contract: 'Buy contract',
    upgrade: 'Kies je nieuwe plan',
    prepare: 'Prepare and send for signature',
    sign_btn: 'Onderteken',
    revoke_sign: 'Verwijder aanvraag ondertekening',
    download_log: 'Signing log downloaden',
    pdf: 'PDF',
    pdf_signed: 'Signed PDF',
    word: 'Word',
    refresh_msg:
      'Je document wordt gegenereerd. Herlaad de pagina als deze niet binnen 10 seconden laadt.',
    upload: 'Upload nieuwe versie',
    simple_upload: 'Upload',
    edit: 'Bewerk',
    use_credit: 'Maak contract',
    signed: 'Ondertekend',
    contract_unlimited: {
      title: 'Je hebt nu onbeperkte tegoeden'
    },
    contract_left: {
      title: 'contract(en) over om gratis te maken',
      subtitle_1: 'Je kan nog',
      subtitle_2:
        'contract(en) uit de Ligo bibliotheek gratis maken. Maak het contract en krijg toegang.'
    },
    credit_excedeed: {
      title:
        'Upgrade om toegang te krijgen en onderteken dit contract template.',
      subtitle:
        '{name}, je hebt al reeds gebruik gemaakt van 1 gratis contract dat is inbegrepen in je huidige {plan} plan.'
    },
    signatures_exceeded: {
      title: 'Upgrade to sign this document',
      subtitle:
        '{name}, je hebt al reeds gebruik gemaakt van de {amount} contract templates die zijn inbegrepen in je huidige {plan} plan.'
    },
    signed_manually: {
      title: 'Digitaal ondertekenen',
      subtitle:
        'Je hebt dit document handmatig gemarkeerd als ondertekend. Dit betekent dat je het document buiten Ligo om hebt ondertekend.'
    },
    sign_digitally: {
      title: 'Onderteken',
      subtitle: 'Bewerk, e-sign en bewaar je document veilig online.'
    },
    card_footer: {
      title: 'Wil je niet upgraden?',
      subtitle: 'Je kan dit contract ook los kopen.',
      cta: 'Koop template contract voor €{price}'
    },
    signed_on: 'Ondertekend op',
    not_signed: 'Niet ondertekend voor de vervaldatum',
    signed_yet: 'Is nog niet ondertekend',
    out_for_signing: 'Verzonden voor ondertekening',
    expired: 'Expired',
    rename_title: 'Hernoem document',
    delete: {
      title: 'Verwijder document',
      subtitle: 'Weet je zeker dat je dit document wil verwijderen?',
      cta: 'Verwijder'
    },
    revoke_modal: {
      title: 'Weet je het zeker?',
      subtitle:
        'weet je het zeker dat je het tekenverzoek wil annuleren voor dit document?',
      cta: 'Verwijder aanvraag ondertekening'
    },
    upload_version: {
      title: 'Bewerk document',
      subtitle:
        'Dit een document in PDF formaat en PDF documenten kan je niet bewerken in je browser. Je kan een nieuwe PDF versie uploaden met je verwerkte aanpassingen, of een .doc versie die je wel kan bewerken hier in je browser.',
      cta: 'Upload'
    },
    cancel: 'Annuleer',
    save: 'Opslaan',
    created: 'aangemaakt',
    updated: 'Laatst gewijzigd',
    expires_on: 'Verzoek verloopt op',
    decline_on: 'Contract afgewezen op',
    cant_edit:
      'Het document kan niet bewerkt worden omdat het in PDF formaat is. <br> Bewerkingen worden alleen ondersteund door .doc documenten. <br> Je kunt het document  op jouw apparaat bewerken en een nieuwe versie <br> ervan uploaden, of een .doc-versie uploaden en beginnen met bewerken in Ligo.',
    cant_edit_need_upgrade:
      'Je moet een Ligo-abonnement hebben of dit <br> contract kopen om te kunnen bewerken.',
    cant_edit_cancel_signing:
      'Je moet het ondertekeningsverzoek annuleren <br> voordat je dit document kan bewerken.',
    cant_edit_been_signed:
      'Je kan dit document niet bewerken <br> omdat het al is ondertekend.',
    cant_upload_need_upgrade:
      'Je moet een Ligo-abonnement hebben of dit <br> contract kopen om te kunnen uploaden.',
    cant_upload_cancel_signing:
      'Je moet het ondertekeningsverzoek annuleren <br> om een nieuwe versie te uploaden.',
    cant_upload_been_signed:
      'Je kan van dit document geen nieuwe versie <br> uploaden omdat het al is ondertekend.'
  },
  upload: {
    title: 'Upload document',
    placeholder: 'Selecteer een document',
    send: 'Verstuur',
    success: 'Het contract is succesvol geüpload',
    error: 'Er is een fout opgetreden bij het uploaden van het contract',
    required_file: 'Contractbestand is required',
    required_name: 'Contract naam is required',
    format:
      'Ongeldig contract. Het moet pdf of word zijn, niet groter dan 15 MB',
    name: 'Contract naam',
    is_signed: 'Dit document is ondertekend',
    doc_name: 'Document Naam'
  },
  empty_banner: {
    title: 'Maak, onderteken en beheer jouw juridische documenten.',
    subtitle:
      "Start door te kiezen voor een document uit Ligo's contractenbibliotheek, begin vanaf 0 of upload je eigen document.",
    btn_label: 'Nieuw document'
  },
  contract_menu: {
    download_as_doc: 'Download als .doc',
    download_as_signed_pdf: 'Downloaden als getekende .pdf',
    download_as_unsigned_pdf: 'Download niet-ondertekend .pdf',
    print: 'Afdrukken',
    rename: 'Naam wijzigen',
    delete: 'Verwijderen',
    make_copy: {
      title: 'Kopie maken',
      required: 'Documentnaam is required',
      document_name: 'Document naam',
      new_name_title: 'Hoe wil je dit document noemen?',
      created: '{copyName} gemaakt',
      next: 'Kopie maken',
      cancel: 'Cancel',
      copy: '{contractName} - kopie',
      error: 'Er is een fout opgetreden',
      copying: 'Contract kopiëren ...'
    }
  },
  reminders: {
    title: 'Taken',
    unlock: 'Upgrade om deze functie te ontgrendelen',
    no_items:
      "Mis nooit meer een deadline of belangrijke verplichting. Met Taken kun je je to-do's gemakkelijk beheren en je concentreren op wat echt belangrijk is.",
    add: 'Nieuwe toevoegen',
    full_list_empty: 'Er zijn geen geplande taken.',
    overdue: 'Verlopen',
    complete_badge: 'Voltooid',
    mark_as_incomplete: 'Markeer als onvoltooid',
    new_reminder: 'Nieuwe taak',
    paid: {
      title: 'Upgrade om toegang te krijgen',
      subtitle: 'Deze taak met advies toegankelijk met een betaald Legal Plan.',
      cta: 'Upgrade'
    },
    modals: {
      complete_title: 'Afgeronde herinneringen',
      description_placeholder: 'Type een beschrijving',
      remind_on: 'Herinner op',
      event_date: 'Verloopt op',
      add_date: 'Kies datum',
      assignee: 'Assignee',
      add_assignee: 'Persoon toevoegen',
      create: 'Bewaar',
      delete: 'Verwijder',
      cancel: 'Annuleer',
      save: 'Bewaar',
      complete: 'Markeren als voltooid',
      done: 'Markeren als gedaan',
      success_create_reminder: 'Taak is succesvol aangemaakt',
      error_create_reminder: 'Fout bij het aanmaken van taak',
      success_update_reminder: 'Taak is succesvol bijgewerkt',
      error_update_reminder: 'Fout bij het updaten van taak',
      success_delete_reminder: 'Taak succesvol verwijderd',
      error_delete_reminder: 'Fout bij verwijderen van taak',
      select_employee: 'Selecteer een persoon uit je werkruimte',
      close: 'Bevestig',
      remind_me_next_year: 'Herinner me volgend jaar',
      remind_me_again: 'Herinner me opnieuw',
      time_interval: {
        month: 'volgende maand',
        quarter: 'volgend kwartaal',
        year: 'volgend jaar'
      },
      delete_confirmation: {
        title: 'Weet je zeker dat je deze taak wil verwijderen?',
        title_recurrent:
          'Weet je zeker dat je deze herhalende taak wil verwijderen?',
        subtitle: 'De taak zal verwijderd worden uit je dashboard.',
        subtitle_recurrent:
          'Dit is een herhalende taak die je periodiek moet uitvoeren. Als je dit verwijdert, worden ook alle volgende taken in de toekomst verwijderd.',
        confirm: 'Verwijder',
        cancel: 'Annuleer'
      }
    },
    table: {
      filters: {
        search: 'Zoek op naam',
        status: 'Status',
        due_date: 'Vervaldatum'
      },
      columns: {
        name: 'Naam taak',
        status: 'Status',
        due_date: 'Vervaldatum',
        category: 'Categorie'
      },
      status: {
        pending: 'Uitstaand',
        completed: 'Afgerond',
        due_date: 'Vervaldatum'
      },
      menu: {
        complete: 'Markeren als gedaan',
        delete: 'Verwijder'
      },
      field: {
        view_task: 'Bekijk taak'
      }
    }
  },
  status: {
    all: 'Alle',
    editing: 'Klaar voor ondertekening',
    approved: 'Goedgekeurd',
    sent_for_signing: 'Verzonden voor ondertekening',
    active: 'Ondertekend',
    expired: 'Verlopen',
    declined: 'Afgewezen',
    draft: 'Vragenlijst',
    create: 'Creating'
  },
  filters: {
    status: {
      name: 'Staat'
    },
    date: {
      name: 'Laatst gewijzigd',
      any_time: 'Altijd',
      today: 'Vandaag',
      yesterday: 'Gisteren',
      last_7_days: 'Afgelopen 7 dagen',
      last_30_days: 'Afgelopen 30 dagen',
      last_90_days: 'Afgelopen 90 dagen',
      custom: 'Aangepaste periode'
    }
  },
  table: {
    filters: {
      search: 'Zoek op naam',
      status: 'Status',
      due_date: 'Laatst gewijzigd'
    },
    columns: {
      name: 'Name',
      status: 'Status',
      due_date: 'Laatst gewijzigd'
    },
    field: {
      view_contract: 'Bekijk contract'
    }
  },
  signature_widget: {
    preparing: 'Bewerking',
    drafted: 'Opgesteld',
    sent: 'Verzonden',
    signed: 'Ondertekend',
    declined: 'Afgewezen'
  }
};
