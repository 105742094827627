



































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { PropType } from 'vue';
import { SimpleField, SimpleSelectableField } from './models/simpleForm.models';
import BaseSimpleTextField from './fields/BaseSimpleTextField.vue';
import BaseLanguageSelect from './fields/BaseLanguageSelect.vue';

import BaseSimpleSelectField from './fields/BaseSimpleSelectField.vue';
import { useSimpleField } from './hooks/simpleField.hooks';
import { useI18n } from '../../../composables';
import BaseSimplePhoneField from './fields/BaseSimplePhoneField.vue';

export default defineComponent({
  name: 'BaseSimpleField',
  components: {
    BaseSimpleTextField,
    BaseSimpleSelectField,
    BaseSimplePhoneField,
    BaseLanguageSelect
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {},
    config: {
      type: Object as PropType<SimpleField>,
      required: true
    },
    loading: {
      type: Boolean
    },
    label: {
      type: String
    },
    tooltipInfo: {
      type: String
    },
    tooltip: {
      type: String
    },
    disableText: {
      type: String
    },
    placeholder: {
      type: String
    },
    hint: {
      type: String
    },
    formValue: {
      type: Object
    },
    tooltipLabelClass: {
      type: String,
      default: 'bg-purple-1 text-purple font-13'
    },
    tooltipClass: {
      type: String,
      default: 'q-mb-md full-width text-dark font-12 q-pa-xs bg-surface-subdued'
    },
    labelClass: {
      type: String,
      default: 'font-14 q-mb-sm text-dark'
    },
    fieldClass: {
      type: String,
      default: 'font-14 q-mb-sm text-dark'
    },
    lastUpdateKey: {
      type: String
    },
    showTooltipByDefault: {
      type: Boolean
    }
  },
  setup(props) {
    const i18n = useI18n();
    const baseField = ref(null);

    const { computedRules, isDisabled, isReadonly } = useSimpleField(
      props.config,
      props,
      i18n
    );

    const showTooltip = ref(props.showTooltipByDefault || false);

    const isSelectableField = (field: any): field is SimpleSelectableField =>
      !!field.selectable;

    const computedLabel = computed(() => {
      if (props.config.labelBuilder)
        return props.config.labelBuilder(
          props.label,
          props.formValue,
          props.config
        );
      return props.label;
    });

    return {
      computedRules,
      showTooltip,
      isDisabled,
      isReadonly,
      computedLabel,
      isSelectableField,
      baseField
    };
  }
});
