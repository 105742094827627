


























import { defineComponent, computed } from '@vue/composition-api';
import { isExternalURL } from '@ligo/shared/utils';

const toTypes: Array<any> = [String, Object];
const eventTypes: Array<any> = [String, Array];

export default defineComponent({
  name: 'AppLink',
  inheritAttrs: false,

  props: {
    to: {
      type: toTypes,
      required: true
    },
    tag: {
      type: String,
      default: 'a'
    },
    custom: Boolean,
    exact: Boolean,
    exactPath: Boolean,
    append: Boolean,
    replace: Boolean,
    activeClass: String,
    exactActiveClass: String,
    ariaCurrentValue: {
      type: String,
      default: 'page'
    },
    event: {
      type: eventTypes,
      default: 'click'
    },
    inactiveClass: String,
    post: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const isExternalLink = computed(() => {
      return typeof props.to === 'string' && isExternalURL(props.to);
    });

    function submitForm() {
      document.forms['app-link-submit-form'].submit();
    }
    return { props, isExternalLink, submitForm };
  }
});
