import { computed, ref, Ref } from '@vue/composition-api';
import {
  Contract,
  LiteContract,
  ContractService,
  ContractStatus,
  ContractBadgeStatus,
  EXTERNAL_RESOURCES
} from '@ligo/dashboard/customer/store';
import { ApiService, RootContext } from '@ligo/shared/utils';
import {
  hasPdfDocument,
  hasWordDocument,
  downloadContract
} from './contract-functions';
import {
  SQTableRow,
  SQTableColumn,
  SQTableAction,
  SQTableFilter,
  SQTablePagination
} from '../../components/base/tables/s-q-table.hook';
import moment from 'moment';

export { hasPdfDocument, hasWordDocument };

export function ShowCloneContract(status: ContractStatus) {
  return ![ContractStatus.INTERMEDIATE, ContractStatus.OPEN].includes(status);
}

enum StatusType {
  editing,
  sent_for_signing,
  active,
  declined,
  drafting
}

type Status = {
  [key in keyof typeof StatusType]: {
    text: string;
    textColor: string;
    background: string;
    value: {
      statuses: ContractStatus[];
      badge_statuses: ContractBadgeStatus[];
    };
  };
};

const statusBadges: Status = {
  drafting: {
    text: 'contract.status.draft',
    textColor: 'indigo',
    background: 'indigo-1',
    value: {
      statuses: [ContractStatus.OPEN, ContractStatus.INTERMEDIATE],
      badge_statuses: [ContractBadgeStatus.EDITING]
    }
  },
  editing: {
    text: 'contract.status.editing',
    textColor: 'subdued',
    background: 'grey-light',
    value: {
      statuses: [ContractStatus.SUBMITTED],
      badge_statuses: [ContractBadgeStatus.EDITING]
    }
  },
  sent_for_signing: {
    text: 'contract.status.sent_for_signing',
    textColor: 'yellow',
    background: 'yellow-light',
    value: {
      statuses: [],
      badge_statuses: [ContractBadgeStatus.SENT_FOR_SIGNING]
    }
  },
  active: {
    text: 'contract.status.active',
    textColor: 'green',
    background: 'green-light',
    value: {
      statuses: [],
      badge_statuses: [ContractBadgeStatus.ACTIVE]
    }
  },
  declined: {
    text: 'contract.status.declined',
    textColor: 'red',
    background: 'red-light',
    value: {
      statuses: [],
      badge_statuses: [ContractBadgeStatus.DECLINED]
    }
  }
};

function getContractStatus(contract: Contract | LiteContract) {
  const status_badge = contract.contract_status;
  const status = contract.status;

  switch (true) {
    case [ContractStatus.INTERMEDIATE, ContractStatus.OPEN].includes(status):
      return statusBadges.drafting;
    case [ContractStatus.SUBMITTED].includes(status) &&
      [ContractBadgeStatus.EDITING].includes(status_badge):
      return statusBadges.editing;
    case [ContractBadgeStatus.SENT_FOR_SIGNING].includes(status_badge):
      return statusBadges.sent_for_signing;
    case [ContractBadgeStatus.ACTIVE].includes(status_badge):
      return statusBadges.active;
    case [ContractBadgeStatus.DECLINED].includes(status_badge):
      return statusBadges.declined;
    default:
      return undefined;
  }
}

export function useActiveContracts(root: RootContext) {
  const items = ref<LiteContract[]>([]);
  const showDelete = ref(false);
  const showMakeCopy = ref(false);
  const contractToCopy = ref();
  const selectedContractIdx = ref(0);
  const loading = ref(false);
  const itemsTotal = ref(0);
  const signatureWidget = ref({
    preparing: {
      total: 0,
      icon: 'fal fa-file',
      label: root.$t('contract.signature_widget.preparing')
    },
    drafted: {
      total: 0,
      icon: 'fal fa-file-lines',
      label: root.$t('contract.signature_widget.drafted')
    },
    sent: {
      total: 0,
      icon: 'fal fa-file-export',
      label: root.$t('contract.signature_widget.sent')
    },
    signed: {
      total: 0,
      icon: 'fal fa-file-signature',
      label: root.$t('contract.signature_widget.signed')
    },
    declined: {
      total: 0,
      icon: 'fal fa-file-xmark',
      label: root.$t('contract.signature_widget.declined')
    }
  });

  function itemsToRows(item: LiteContract): SQTableRow {
    const contractStatus = getContractStatus(item);
    return {
      id: item.uuid.toString(),
      name: item.name,
      status: {
        slug: item.status,
        value: root.$t(contractStatus?.text) || '',
        textColor: contractStatus?.textColor || 'subdued',
        color: contractStatus?.background || 'grey-5',
        radius: 10
      },
      due_date: moment(item.updated_at).format('DD MMM YYYY'),
      goTo: item.status != ContractStatus.SUBMITTED ? 'continue' : 'detail'
    };
  }

  const columns: SQTableColumn[] = [
    {
      name: 'name',
      label: root.$t('contract.table.columns.name'),
      align: 'left',
      field: 'name',
      sortable: true
    },
    {
      name: 'status',
      label: root.$t('contract.table.columns.status'),
      align: 'left',
      field: 'status'
    },
    {
      name: 'due_date',
      label: root.$t('contract.table.columns.due_date'),
      align: 'left',
      field: 'due_date'
    }
  ];

  const pagination: Ref<SQTablePagination> = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
  });

  const query = ref({
    query: undefined,
    badge_statuses: undefined,
    statuses: undefined,
    'last_modified_date_filter[gte]': '',
    'last_modified_date_filter[lte]': '',
    page: pagination.value.page,
    per_page: pagination.value.rowsPerPage,
    uploaded: false
  });

  const filters: SQTableFilter[] = [
    {
      type: 'query',
      name: 'query',
      label: root.$t('contract.table.filters.search'),
      model: ''
    },
    {
      type: 'single-select',
      name: 'status',
      label: `${root.$t('contract.table.filters.status')}`,
      model: '',
      options: Object.keys(statusBadges).map((key) => ({
        value: statusBadges[key].value,
        label: root.$t(statusBadges[key].text),
        key: key
      })),
      optionClass: (opt) => {
        return `
        bg-${statusBadges[opt.key].background}
        text-${statusBadges[opt.key].textColor}
        q-px-sm
        q-py-xs
        border-radius-10
        `;
      },
      selectedClass:
        'q-px-sm q-py-xs border-radius-10 bg-surface-default text-default'
    },
    {
      type: 'date-range',
      name: 'due_date',
      label: root.$t('contract.table.filters.due_date'),
      model: {
        from: '',
        to: ''
      }
    }
  ];

  const actions: SQTableAction[] = [
    {
      label: root.$t('contract.contract_menu.make_copy.title'),
      icon: 'fas fa-copy',
      color: 'subdued',
      conditional(row) {
        return ShowCloneContract(row.status.slug);
      },
      emit: 'make-a-copy'
    },
    {
      label: root.$t('contract.contract_menu.delete'),
      icon: 'fas fa-trash-can',
      color: 'subdued',
      emit: 'delete'
    }
  ];

  const rows = computed((): SQTableRow[] => {
    return items.value.map(itemsToRows);
  });

  function onShowDelete(id: string) {
    selectedContractIdx.value = items.value.findIndex((c) => c.uuid == id);
    showDelete.value = true;
  }
  function onDelete() {
    ContractService.delete(items.value[selectedContractIdx.value].uuid)
      .then(() => {
        getContracts();
      })
      .catch((e) => {
        console.log('The contract could not be deleted', e);
      });
  }

  async function onContinue(id: string) {
    const contr = items.value.find((c) => c.uuid == id);
    const contract_url = EXTERNAL_RESOURCES.CONTRACTS(
      contr.contract_type.slug,
      contr.uuid
    );
    const path = EXTERNAL_RESOURCES.AUTHENTICATED_REDIRECTION(contract_url);
    const response = await ApiService.auth_redirection(path);
    window.location.assign(response.request.responseURL);
  }

  function onShowMakeCopy(id: string) {
    contractToCopy.value = items.value.find((c) => c.uuid == id);
    root.$nextTick(() => {
      showMakeCopy.value = true;
    });
  }

  function onGoToDetails(contractUuid) {
    root.$router.push({
      name: 'ContractPreview',
      params: { uuid: contractUuid }
    });
  }

  function getContractStats() {
    ContractService.contractStats().then(({ data }) => {
      function filterStats(
        badgeStatus: Array<ContractBadgeStatus>,
        status: Array<ContractStatus>
      ) {
        return data
          .filter(
            (stat) =>
              !status.length || status.includes(stat.status as ContractStatus)
          )
          .filter(
            (stat) =>
              !badgeStatus.length ||
              badgeStatus.includes(stat.badge_status as ContractBadgeStatus)
          )
          .reduce((acc, stat) => acc + stat.count, 0);
      }
      signatureWidget.value.preparing.total = filterStats(
        [ContractBadgeStatus.EDITING],
        [ContractStatus.OPEN, ContractStatus.INTERMEDIATE]
      );

      signatureWidget.value.drafted.total = filterStats(
        [ContractBadgeStatus.EDITING],
        [ContractStatus.SUBMITTED]
      );

      signatureWidget.value.sent.total = filterStats(
        [ContractBadgeStatus.SENT_FOR_SIGNING],
        []
      );

      signatureWidget.value.signed.total = filterStats(
        [ContractBadgeStatus.ACTIVE],
        []
      );

      signatureWidget.value.declined.total = filterStats(
        [ContractBadgeStatus.DECLINED],
        []
      );

      itemsTotal.value =
        signatureWidget.value.preparing.total +
        signatureWidget.value.drafted.total +
        signatureWidget.value.sent.total +
        signatureWidget.value.signed.total +
        signatureWidget.value.declined.total;
    });
  }

  function getContracts() {
    loading.value = true;
    ContractService.list(query.value)
      .then((response) => {
        pagination.value.rowsNumber = parseInt(response.headers['x-total']);
        items.value = response.data;
      })
      .catch((e) => {
        console.log('Could not get contracts', e);
      })
      .finally(() => {
        loading.value = false;
      });
  }

  function search(filter, page, rowsPerPage, sortBy, descending) {
    query.value.query = filter['query'].model || undefined;
    query.value.badge_statuses =
      (filter['status'].model &&
        filter['status'].model.value.badge_statuses.length &&
        filter['status'].model.value.badge_statuses) ||
      undefined;

    query.value.statuses =
      (filter['status'].model &&
        filter['status'].model.value.statuses.length &&
        filter['status'].model.value.statuses) ||
      undefined;

    const from =
      filter['due_date']?.model &&
      new Date(filter['due_date']?.model?.from || filter['due_date']?.model);
    const to = new Date(filter['due_date']?.model?.to);

    const isValidFrom = from && !isNaN(from.getTime());
    const isValidTo = to && !isNaN(to.getTime());

    isValidTo && to.setDate(to.getDate() + 1);

    query.value['last_modified_date_filter[gte]'] =
      (isValidFrom && moment(from).format('YYYY-MM-DD')) || undefined;
    query.value['last_modified_date_filter[lte]'] =
      (isValidTo && moment(to).format('YYYY-MM-DD')) || undefined;

    query.value.page = page;
    query.value['per_page'] = rowsPerPage;

    pagination.value.sortBy = sortBy;
    pagination.value.descending = descending;
    pagination.value.page = page;
    pagination.value.rowsPerPage = rowsPerPage;

    getContracts();
  }

  function onItemClicked(item: SQTableRow) {
    switch (item.goTo) {
      case 'continue':
        onContinue(item.id.toString());
        break;
      default:
        onGoToDetails(item.id.toString());
        break;
    }
  }

  getContracts();
  getContractStats();

  return {
    columns,
    rows,
    showDelete,
    showMakeCopy,
    loading,
    contractToCopy,
    pagination,
    filters,
    actions,
    itemsTotal,
    signatureWidget,
    getContracts,
    onItemClicked,
    search,
    onShowDelete,
    onDelete,
    onShowMakeCopy,
    onContinue,
    onGoToDetails
  };
}
