/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ref, Ref, computed, watch } from '@vue/composition-api';
import { ESignField, FieldTypes, CheckboxField } from '../../../models';
import SignaturePad from 'signature_pad';
import {
  resizeEventListener,
  useCanvasEngine,
  InteractableElement
} from '@ligo/shared/utils';
import { signatureService } from '@ligo/dashboard/customer/store';
import Vue from 'vue';
import { awaitingProcess } from './utils/loaders';
import { useLocale } from '../../account/user-settings.hook';

export function useSigningDocumentConfig(
  contractUuid: string,
  signerUuid: string,
  sessionUuid: string,
  origin?: boolean
) {
  const selectedField = ref() as Ref<ESignField>;
  const pageRect = ref<{ width: number; height: number }>();
  const { current, locales } = useLocale(false, false);
  const [EN, NL] = locales.value.map((locale) => locale.value);

  const signDialog = ref(false);
  const dateDialog = ref(false);
  const textDialog = ref(false);
  const checkboxDialog = ref(false);
  const showConfirmDialog = ref(false);
  const showDeclineDialog = ref(false);
  const showSuccessDialog = ref(false);
  const showSuccessWithoutAccountDialog = ref(false);

  const signaturePad = ref(null);
  const canvas = ref(null);
  const canvasEngine = useCanvasEngine(300, 300);

  const signSuccessfully = ref(false);
  const documentDeclined = ref(false);

  const resizing = ref(false);

  const {
    contract,
    loading: loadingContracts
  } = signatureService.get.staticContractPreview(contractUuid);

  const {
    start: startResizeListener,
    disconnect: disconnectResizeListener,
    loading: pageLoading
  } = resizeEventListener('.contract-page', (node) => {
    Vue.nextTick(() => {
      pageRect.value = node.getBoundingClientRect();
    });
  });

  const { signer, loading: loadingFields } = signatureService.get.parsedFields(
    contractUuid,
    signerUuid,
    sessionUuid
  );

  function getPageFields(pageIndex: number) {
    return signer.value!.fields.filter(
      (field) => field.pageIndex === pageIndex
    );
  }

  function scale(dimension: number, newScale: number, oldScale: number) {
    return (dimension * newScale) / oldScale;
  }

  function getFieldMeasures(field: ESignField) {
    const y = scale(
      field.coordinates.y,
      pageRect.value.height,
      field.documentPageSize.height
    );
    const x = scale(
      field.coordinates.x,
      pageRect.value.width,
      field.documentPageSize.width
    );
    const width = scale(
      field.dimensions.width,
      pageRect.value.width,
      field.documentPageSize.width
    );
    const height = scale(
      field.dimensions.height,
      pageRect.value.height,
      field.documentPageSize.height
    );

    return {
      coordinates: { x, y },
      dimensions: { width, height }
    };
  }

  function onFieldClick(field: ESignField) {
    switch (field.type) {
      case FieldTypes.SIGNATURE:
        initSignaturePad(field);
        break;
      case FieldTypes.DATE:
        onFillDate(field);
        break;
      case FieldTypes.TEXT:
        onFillText(field);
        break;
      default:
        onCheck(field);
    }
  }

  function onFillDate(field: ESignField) {
    selectedField.value = field;
    Vue.nextTick(() => {
      dateDialog.value = true;
    });
  }

  function onFillText(field: ESignField) {
    selectedField.value = field;
    Vue.nextTick(() => {
      textDialog.value = true;
    });
  }

  function onCheck(field: ESignField) {
    selectedField.value = field;
    Vue.nextTick(() => {
      checkboxDialog.value = true;
    });
  }

  function createImgText(text: string) {
    const fieldRec = document
      .getElementById(`field-to-fill-${selectedField.value.uuid}`)
      .getBoundingClientRect();

    canvasEngine.clearCanvas();
    canvasEngine.setDimensions(fieldRec.width, fieldRec.height);
    canvasEngine.setTextFullHeight(text);
    const metrics = canvasEngine.getTextMetrics(text);

    return {
      img: canvasEngine.getImage(),
      width: metrics.width
    };
  }

  function initSignaturePad(field: ESignField) {
    signDialog.value = true;
    selectedField.value = field;
    Vue.nextTick(() => {
      canvas.value = document.getElementById(
        'signature-pad'
      ) as HTMLCanvasElement;
      signaturePad.value = new SignaturePad(canvas.value);
    });
  }

  function onSetText(text: string) {
    const { img, width } = createImgText(text);
    selectedField.value.content = img;
    selectedField.value.dimensions.width = scale(
      width,
      selectedField.value.documentPageSize.width,
      pageRect.value.width
    );
    textDialog.value = false;
    updateFields();
  }

  function onSetDate(text: string) {
    const { img, width } = createImgText(text);
    selectedField.value.content = img;
    selectedField.value.dimensions.width = scale(
      width,
      selectedField.value.documentPageSize.width,
      pageRect.value.width
    );
    dateDialog.value = false;
    updateFields();
  }

  function onCheckingBox(value: boolean) {
    const fieldRec = document
      .getElementById(`field-to-fill-${selectedField.value.uuid}`)
      .getBoundingClientRect();
    const img = new Image();
    img.onload = () => {
      canvasEngine.clearCanvas();
      canvasEngine.setDimensions(fieldRec.width, fieldRec.height);
      canvasEngine.drawImage(img);
      selectedField.value.content = canvasEngine.getImage();
      selectedField.value.auxData = value;
      checkboxDialog.value = false;
      updateFields();
    };
    img.src = value ? CheckboxField.CHECKED : CheckboxField.UNCHECKED;
  }

  function onDeleteSignature(content: string, index: number) {
    signatureService.patch
      .archiveSignature(content, signer.value!.email)
      .then((r) => {
        const data = r.data[r.data.length - 1];

        const drawingSignatures = signer.value!.drawing_signatures;

        drawingSignatures.splice(index, 1);

        if (!!data && drawingSignatures && !drawingSignatures.includes(data)) {
          drawingSignatures.push(data);
          signer.value!.drawing_signatures = drawingSignatures.slice(0, 3);
        }
      });
  }

  function onSign(content?: string) {
    const data = content || signaturePad.value.toDataURL('image/png');
    selectedField.value.content = data;
    signDialog.value = false;

    const drawingSignatures = signer.value!.drawing_signatures;

    if (!!data && drawingSignatures && !drawingSignatures.includes(data)) {
      drawingSignatures.splice(0, 0, data);
      signer.value!.drawing_signatures = drawingSignatures.slice(0, 3);
    }
    updateFields();
  }

  function updateFields() {
    signatureService.patch
      .signerWithInvertedCoordinates(contractUuid, signer.value!)
      .finally(() => {
        const length = signer.value!.fields.length;
        signer.value!.fields.splice(length);
      });
  }

  function onSave() {
    showConfirmDialog.value = false;
    awaitingProcess.start();
    signatureService.post
      .singDocument(contractUuid, signerUuid, sessionUuid)
      .then(() => {
        awaitingProcess.stop();
        if (signerEmail.value === contract.value.member.email) {
          showSuccessDialog.value = false;
          Vue['Router'].push({
            name: 'ContractPreview',
            params: {
              uuid: contractUuid
            }
          });
        } else {
          showSuccessWithoutAccountDialog.value = true;
        }
      });
  }

  function goBack() {
    Vue['Router'].push({
      name: 'ContractPreview',
      params: {
        uuid: contractUuid
      }
    });
  }

  function onClear() {
    signaturePad.value.clear();
  }

  function onGoToField(field: ESignField) {
    const element = document.querySelector(`#field-to-fill-${field.uuid}`);
    const header = document.querySelector('.q-header');
    const rec = element.getBoundingClientRect();
    const headerRec = header.getBoundingClientRect();
    window.scrollTo({
      behavior: 'smooth',
      top: rec.top + window.scrollY - headerRec.height
    });
  }

  function onDeclineConfirmation() {
    showDeclineDialog.value = false;
    awaitingProcess.start();
    signatureService.post
      .decline(contractUuid, signerUuid, sessionUuid)
      .then(() => {
        awaitingProcess.stop();
        documentDeclined.value = true;
      });
  }

  function focusNextField() {
    const field = fields.value.find((field) => !field.content);
    if (field) onGoToField(field);
  }

  const amountPendingSignatures = computed(() => {
    return fields.value.filter((field) => field.required && !field.content)
      .length;
  });

  const fields = computed(() => {
    return signer.value?.fields || [];
  });

  const signerEmail = computed(() => {
    return signer.value?.email || '';
  });

  const usedSignatures = computed(() => {
    return signer.value?.drawing_signatures || [];
  });

  const loading = computed(() => loadingContracts.value || loadingFields.value);

  const atLeastOneFieldWithoutSignature = computed(() => {
    return fields.value.some((field) => !field.content);
  });

  const showDeclineCta = computed(() => {
    return (
      contract.value.member.email !== signerEmail.value &&
      !documentDeclined.value
    );
  });

  const showBackCta = computed(() => {
    return contract.value.member.email === signerEmail.value;
  });

  const missingRequiredSigned = computed(() => {
    return fields.value.some((field) => field.required && !field.content);
  });

  watch(loading, (value) => {
    if (value) disconnectResizeListener();
    else startResizeListener();
  });

  watch(
    pageLoading,
    (value) => {
      if (!value) {
        fields.value.forEach((field) => {
          const interactableElement = new InteractableElement(
            `#field-to-fill-${field.uuid}`
          );
          interactableElement.makeResizable(
            (event) => {
              resizing.value = true;
              const top = scale(
                parseFloat(event.target.style.top),
                field.documentPageSize.height,
                pageRect.value.height
              );
              const left = scale(
                parseFloat(event.target.style.left),
                field.documentPageSize.width,
                pageRect.value.width
              );
              const width = scale(
                event.rect.width,
                field.documentPageSize.width,
                pageRect.value.width
              );
              const height = scale(
                event.rect.height,
                field.documentPageSize.height,
                pageRect.value.height
              );
              field.coordinates.y = top;
              field.coordinates.x = left;
              field.dimensions.width = width;
              field.dimensions.height = height;
            },
            undefined,
            {
              left: false,
              top: false,
              bottom: '.bottom-edge',
              right: '.right-edge'
            }
          );
        });
      }
    },
    { immediate: true }
  );

  watch(contract, (value) => {
    if (value && !origin) {
      current.value = value.locale == NL ? NL : EN;
    }
  });

  document.addEventListener('mouseup', () => {
    if (resizing.value) {
      updateFields();
      resizing.value = false;
    }
  });

  return {
    contract,
    loading,
    signDialog,
    dateDialog,
    textDialog,
    checkboxDialog,
    fields,
    pageLoading,
    atLeastOneFieldWithoutSignature,
    showConfirmDialog,
    showDeclineDialog,
    showSuccessDialog,
    showSuccessWithoutAccountDialog,
    signerEmail,
    signSuccessfully,
    documentDeclined,
    showDeclineCta,
    showBackCta,
    amountPendingSignatures,
    missingRequiredSigned,
    usedSignatures,
    onDeleteSignature,
    onDeclineConfirmation,
    onCheckingBox,
    onSetDate,
    onSetText,
    onFieldClick,
    goBack,
    onSign,
    onSave,
    onClear,
    onGoToField,
    getPageFields,
    getFieldMeasures,
    initSignaturePad,
    focusNextField
  };
}
