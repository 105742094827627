








































































































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { SocialBtn } from '../../models';
import { RESOURCES } from '@ligo/bv-flow/store';
import { registrationFormFields, fieldRules } from '../hooks/auth';
import { Notify } from 'quasar';

const TERMS_AND_CONDITION = RESOURCES.TERMS_AND_CONDITION;
const CHECKBOX_SIZE = '45px';

export default defineComponent({
  name: 'RegisterForm',
  props: {
    socialBtns: {
      type: Array as PropType<Array<SocialBtn>>,
      default: () => []
    },
    brand: {
      type: String,
      default: 'ligo'
    }
  },
  setup(_, { root, emit }) {
    const termsOfService = ref(false);
    const keepMePosted = ref(false);

    const onSubmit = () => {
      if (termsOfService.value) {
        emit('perform-register', registerValues.value);
      } else {
        Notify.create({
          message: root.$t('auth.register_checkmarks') as string,
          color: 'red',
          position: 'top',
          icon: 'mdi-alert'
        });
      }
    };

    const registerValues = ref({});
    const passwordVisibility = ref(false);

    return {
      onSubmit,
      passwordVisibility,
      fieldRules,
      registrationFormFields,
      registerValues,
      termsOfService,
      keepMePosted,
      TERMS_AND_CONDITION,
      CHECKBOX_SIZE
    };
  }
});
