import { Ref, ref } from '@vue/composition-api';
import { ApiService, Dictionary } from '@ligo/shared/utils';
import { useI18n } from '../../composables';
import { RESOURCES, Event } from '@ligo/dashboard/customer/store';
import {
  SQTableRow,
  SQTableColumn,
  SQTableAction,
  SQTableFilter,
  SQTablePagination
} from '../../components/base/tables/s-q-table.hook';
import moment from 'moment';

enum STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed'
}

export function useGetReminders(
  defaultQueryObj: Dictionary = {},
  emit: (event: string, ...args: any[]) => void
) {
  const i18n = useI18n();

  const loading = ref(true);
  const showConfirmationDialog = ref(false);
  const selectedReminder: Ref<Event> = ref();
  const totalReminders = ref(0);

  const columns: SQTableColumn[] = [
    {
      name: 'task_name',
      label: i18n.t('contract.reminders.table.columns.name').toString(),
      align: 'left',
      field: 'name'
    },
    {
      name: 'status',
      label: i18n.t('contract.reminders.table.columns.status').toString(),
      align: 'left',
      field: 'status'
    },
    {
      name: 'category',
      label: i18n.t('contract.reminders.table.columns.category').toString(),
      align: 'left',
      field: (value) => {
        const locale = i18n.locale == 'en-us' ? 'en' : 'nl';
        return value?.category[locale] || '';
      }
    },
    {
      name: 'due_date',
      label: i18n.t('contract.reminders.table.columns.due_date').toString(),
      align: 'left',
      field: 'due_date',
      sortable: true
    }
  ];

  const rows: Ref<SQTableRow[]> = ref([]);

  const actions: SQTableAction[] = [
    {
      label: i18n.t('contract.reminders.table.menu.complete').toString(),
      icon: 'far fa-circle-check',
      color: 'subdued',
      emit: 'complete',
      conditional: (row) => !row.name.completed && row.visible
    },
    {
      label: i18n.t('contract.reminders.table.menu.delete').toString(),
      icon: 'fas fa-trash-can',
      color: 'subdued',
      emit: 'delete'
    }
  ];

  const filters: SQTableFilter[] = [
    {
      type: 'query',
      name: 'query',
      label: i18n.t('contract.reminders.table.filters.search').toString(),
      model: ''
    },
    {
      type: 'single-select',
      name: 'status',
      label: `${i18n.t('contract.reminders.table.filters.status').toString()}`,
      model: '',
      options: [
        {
          value: STATUS.COMPLETED,
          label: i18n
            .t(`contract.reminders.table.status.${STATUS.COMPLETED}`)
            .toString()
        },
        {
          value: STATUS.PENDING,
          label: i18n
            .t(`contract.reminders.table.status.${STATUS.PENDING}`)
            .toString()
        }
      ],
      optionClass: (opt) => {
        return `
        bg-${opt.value == STATUS.PENDING ? 'yellow' : 'green'}-light
        text-${opt.value == STATUS.PENDING ? 'yellow' : 'green'}
        q-px-sm
        q-py-xs
        border-radius-10
        text-capitalize
        `;
      },
      selectedClass:
        'q-px-sm q-py-xs border-radius-10 bg-surface-default text-default'
    },
    {
      type: 'date-range',
      name: 'due_date',
      label: i18n.t('contract.reminders.table.filters.due_date').toString(),
      model: {
        from: '',
        to: ''
      }
    }
  ];

  const pagination: Ref<SQTablePagination> = ref({
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 10
  });

  const remindersList: Ref<Event[]> = ref([]);

  const query = ref({
    query: undefined,
    status: undefined,
    'event_date_range[gte]': undefined,
    'event_date_range[lte]': undefined,
    page: pagination.value.page,
    per_page: pagination.value.rowsPerPage,
    ...defaultQueryObj,
    order_field: undefined,
    order: undefined
  });

  function getTotalReminders() {
    ApiService.get(RESOURCES.EVENTS, {}).then((response) => {
      totalReminders.value = parseInt(response.headers['x-total']);
    });
  }

  function getReminders() {
    loading.value = true;
    ApiService.get<Event[]>(RESOURCES.EVENTS, {
      ...query.value,
      ...defaultQueryObj
    })
      .then((response) => {
        pagination.value.rowsNumber = response.headers['x-total'];
        emit('change-total', response.headers['x-total']);
        remindersList.value = response.data;
        rows.value = remindersList.value.map(itemsToRows);
      })
      .finally(() => {
        loading.value = false;
        if (selectedReminder.value) {
          const reminder = remindersList.value.find(
            (reminder) => reminder.id == selectedReminder.value.id
          );
          selectedReminder.value = reminder;
        }
      })
      .catch((e) => {
        console.log('There was an error while fetching the reminders : ', e);
      });
  }

  function search(filter, page, rowsPerPage, sortBy, descending) {
    query.value.query = filter['query'].model || undefined;
    query.value.status =
      (filter['status'].model && filter['status'].model.value) || undefined;

    const from =
      filter['due_date']?.model &&
      new Date(filter['due_date']?.model?.from || filter['due_date']?.model);
    const to = new Date(filter['due_date']?.model?.to);

    const isValidFrom = from && !isNaN(from.getTime());
    const isValidTo = to && !isNaN(to.getTime());

    isValidTo && to.setDate(to.getDate() + 1);

    query.value['event_date_range[gte]'] =
      (isValidFrom && moment(from).format('YYYY-MM-DD')) || undefined;
    query.value['event_date_range[lte]'] =
      (isValidTo && moment(to).format('YYYY-MM-DD')) || undefined;

    query.value.page = page;
    query.value['per_page'] = rowsPerPage;
    query.value.order_field = sortBy == 'due_date' ? 'event_date' : undefined;
    query.value.order = descending ? 'desc' : undefined;

    pagination.value.sortBy = sortBy;
    pagination.value.descending = descending;
    pagination.value.page = page;
    pagination.value.rowsPerPage = rowsPerPage;

    getReminders();
  }

  function itemsToRows(item: any): SQTableRow {
    return {
      id: item.id,
      name: {
        value: i18n.locale == 'nl' ? item.nl.event_name : item.en.event_name,
        customClass: item.status == STATUS.COMPLETED ? 'text-strike' : '',
        headerColumn: true,
        visible: item.visible,
        completed: item.status == STATUS.COMPLETED,
        id: item.id
      },
      status: {
        value:
          item.status == STATUS.COMPLETED
            ? i18n
                .t(`contract.reminders.table.status.${STATUS.COMPLETED}`)
                .toString()
            : i18n
                .t(`contract.reminders.table.status.${STATUS.PENDING}`)
                .toString(),
        textColor: item.status == STATUS.COMPLETED ? 'green' : 'yellow',
        color: item.status == STATUS.COMPLETED ? 'green-light' : 'yellow-light',
        radius: 10
      },
      category: item.event_type?.event_type_category || {},
      visible: item.visible,
      due_date: moment(item.event_date).format('D MMM YYYY')
    };
  }

  getReminders();
  getTotalReminders();

  return {
    pagination,
    loading,
    columns,
    rows,
    actions,
    filters,
    remindersList,
    showConfirmationDialog,
    selectedReminder,
    totalReminders,
    search,
    getReminders
  };
}
