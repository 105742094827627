






















import {
  defineComponent,
  getCurrentInstance,
  computed
} from '@vue/composition-api';
import APrimaryBtn from '../../base/app/buttons/APrimaryBtn.vue';

export default defineComponent({
  name: 'ServicesTypesCard',
  components: { APrimaryBtn },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    ctaLabel: {
      type: String
    },
    ctaId: {
      type: String
    }
  },
  setup(props) {
    const instanceUuid = (getCurrentInstance() as any)._uid;
    const buttonId = computed(() => props.ctaId || `cta-${instanceUuid}`);

    return { buttonId };
  }
});
