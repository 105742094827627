export const sellProductOptions = [
  'consumers',
  'companies',
  'consumers_and_businesses',
  'none'
];

export const whereSoldOptions = [
  'store_or_kiosk',
  'market',
  'street_trade',
  'internet',
  'from_home',
  'postorder',
  'not_applicable'
];

export const representationOptions = ['one_signature', 'two_signatures'];

export const financial_year = ['this_year', 'next_year'];

export const converted_from = ['vof', 'proprietorship'];

export const sharesRightDatesOptions = ['date_of_transfer', 'other_date'];

export const accountantOptions = ['yes', 'no', 'will_be_arranged_soon'];

export const notarialCostsOptions = ['seller', 'buyer'];

export const typeBvOptions = ['standard', 'private_savings'];

export const sharetype = [
  'common_stock',
  'cumulative_shares',
  'preference_shares',
  'priority_stocks'
];

export const conversionTypeOptions = [
  'asset_liability',
  'tax_free_transfer',
  'transfer_subject_to_tax',
  'none'
];

export const industryTypeOptions = [
  'business_services',
  'construction',
  'health',
  'retail',
  'culture_sport_recreation',
  'ict_media',
  'wholesale',
  'agriculture',
  'manufacturing',
  'logistics',
  'hospitality',
  'financial_services',
  'energy_water_environment',
  'other'
];
