import { Dictionary } from '@ligo/shared/utils';
import {
  BasicFieldDcl,
  BasicField,
  NumericField,
  CheckboxField,
  SelectableField,
  TextField,
  DateTimeField,
  BasicFieldInterface,
  GConstructor,
  DateTimeFieldDcl,
  SelectableFieldDcl,
  TextFieldDcl,
  NumericFieldDcl,
  SelectableOptions
} from '@ligo/shared/mvc';

export type CalculatorOptionModel = {
  value: any;
  label: string;
  subtitle?: string;
  badgeText?: string;
};

export interface TransitionCondition {
  fieldKey: string;
  transitionType: 'not-answered' | 'positive' | 'negative' | 'equals';
  value?: any;
  perShareholder?: boolean;
}

export interface FieldTransition {
  fieldKey: string;
  conditions?: TransitionCondition[];
}

export type OriginCalculatorType = 'bv-product' | 'holding';

export interface i18nKeys {
  label?: string;
  tooltip?: string;
  subtitle?: string;
  options?: SelectableOptions;
}

export interface i18nData {
  en: i18nKeys;
  nl: i18nKeys;
}

export interface CalculatorBasicFieldDcl<T> extends BasicFieldDcl<T> {
  subtitle?: string;
  skip?: boolean;
  readonly?: boolean;
  transitions?: FieldTransition[];
  tail?: TransitionCondition;
  root?: boolean | OriginCalculatorType;
  required?: boolean;
  perShareholder?: boolean | TransitionCondition[];
  shareholderFilter?: TransitionCondition[];
  i18n?: i18nData;
  /**
   * Custom component for replace de CalculatorQuestionCard
   * Same props of CalculatorQuestionCard
   * Emit 'on-answer(value: Dictionary, key: string)'
   * Require add the custom component on CarculatorWrapperQuestionsCard
   * We strongly recommend that you use the question card and redefine the slots.
   * Examples on calculatorQuestions folder
   */
  customQuestionComponent?: string;
}

interface CalculatorOptionFieldsDcl extends SelectableFieldDcl<any> {
  boolean: boolean;
}

export type CalculatorDcl<T> = CalculatorBasicFieldDcl<T> &
  (
    | SelectableFieldDcl<T>
    | DateTimeFieldDcl
    | TextFieldDcl
    | NumericFieldDcl
    | CalculatorOptionFieldsDcl
  );

export class CalculatorBasicField<T> extends BasicField<T> {
  subtitle?: string;
  skip?: boolean;
  readonly?: boolean;
  transitions?: FieldTransition[];
  tail?: TransitionCondition;
  disable?: boolean;
  required = true;
  root: boolean | string = false;
  perShareholder = false;
  shareholderFilter?: TransitionCondition[];
  i18n?: i18nData;
  id: string;

  initializeLocaleValues() {
    const localeKey = this.key.split('.').pop();
    this.localePrefix = `${this.localePrefix}.${localeKey}`;
    this.label = `${this.localePrefix}.label`;
    this.subtitle = `${this.localePrefix}.subtitle`;
    this.labelTooltip = `${this.localePrefix}.label_tooltip.`;
  }
}

export class CalculatorTextField extends TextField(
  CalculatorBasicField
)<string> {
  initializeLocaleValues() {
    super.initializeLocaleValues();
    this.placeholder = `${this.localePrefix}.placeholder`;
  }
}

export class BlockerField extends CalculatorBasicField<boolean> {
  type: 'blocker';
}

export class CalculatorNumericField extends NumericField(
  CalculatorBasicField
)<number> {}

export class CalculatorCheckboxField extends CheckboxField(
  CalculatorBasicField
)<boolean> {}

export class CalculatorDateTimeField extends DateTimeField(
  CalculatorBasicField
)<string> {}

export class CalculatorFieldSelect extends SelectableField(
  CalculatorBasicField
)<any> {
  type: 'select';
  options: CalculatorOptionModel[];
  optionKeys?: string[];
  multiple = false;
}

export class CalculatorFieldOptions extends SelectableField(
  CalculatorBasicField
)<any> {
  type: 'options';
  options: CalculatorOptionModel[];
  boolean = false;
  multiple = false;
  columns = 5;
  optionKeys?: string[];
  optionsConfig?: {
    classes?: string;
    styles?: Dictionary<string>;
  };
}

export type CalculatorField =
  | CalculatorBasicField<any>
  | CalculatorTextField
  | CalculatorNumericField
  | CalculatorCheckboxField
  | CalculatorDateTimeField
  | CalculatorFieldOptions
  | CalculatorFieldSelect
  | BlockerField;

export const CalculatorFieldClassMap: Dictionary<
  GConstructor<BasicFieldInterface>
> = {
  text: CalculatorTextField,
  bool: CalculatorTextField,
  textarea: CalculatorTextField,
  number: CalculatorNumericField,
  timepicker: CalculatorDateTimeField,
  datepicker: CalculatorDateTimeField,
  select: CalculatorFieldSelect,
  options: CalculatorFieldOptions,
  password: CalculatorTextField,
  checkbox: CalculatorCheckboxField
};
