import {
  NotaryProduct,
  NotaryProductResource,
  Step
} from '@ligo/bv-flow/store';
import { ApiService } from '@ligo/shared/utils';
import { HoldingResource } from './definitions/holding';
import { useMainResource } from './resources/main';
import { shareholderResources } from './resources/shareholders';
import { paymentResource, ShareholderType } from '../../share';
import { getSteps } from '../../tools';
import { acceptShareholder, NotaryProductSlug } from '../../product_structure';

const steps: Array<Step> = [
  {
    name: 'holding.company_name.name',
    completed: false
  },
  {
    name: 'holding.shareholder_information.name',
    parts: 2,
    currentPart: 0,
    completed: false
  },
  {
    name: 'holding.holding_upsell.name',
    parts: 3,
    currentPart: 0,
    completed: false
  },
  {
    name: 'holding.preview_documents.name',
    completed: false
  }
];

interface HoldingAPIResponse {
  product_data: HoldingResource;
  upsell_package: any;
  calculation: { uuid: string };
}

const url = 'holding/';
const path = 'holding';
const locale = 'holding';
const typeId = 13;
const slug = NotaryProductSlug.HOLDING_OPRICHTEN;
const finalStep = 6;

const saveToAPI = async (product: NotaryProduct, sendStep = true) => {
  const data = product.resources['main'].fieldData();
  if (acceptShareholder(product.resources['p1']))
    data['p1'] = product.resources['p1'].fieldData();
  const nextStep = sendStep ? product.getStepIncrement() : 0;
  data['step'] = product.step + nextStep;
  data['temporal_step'] = product.temporalStep + nextStep;
  data['substep'] = product.resources['main'].substep;
  const request = product.url + product.uuid;
  console.log('PayLoad', data);
  try {
    await ApiService.patch(request, data);
  } catch (error) {
    console.log('PATCH ERROR', error);
  }
};

const loadFromAPI = async (product: NotaryProduct) => {
  if (product.uuid) {
    const response = await ApiService.get<HoldingAPIResponse>(
      product.url + product.uuid
    );
    product.upsellPackageData = response.data.upsell_package;
    product.calculationUuid = response.data.calculation?.uuid;
    const { step, temporalStep, substep } = getSteps(
      product.uuid,
      response.data.product_data
    );
    product.step = step;
    product.temporalStep = temporalStep;
    product.substep = substep;
    product.updateFromJSON(response.data);
  }
};

export const useHolding = (uuid?: string) => {
  const resources = {
    ...useMainResource(),
    ...shareholderResources(),
    ...paymentResource(locale)
  };
  return new NotaryProduct(
    resources,
    locale,
    url,
    path,
    saveToAPI,
    loadFromAPI,
    'holding-handler',
    steps,
    typeId,
    slug,
    finalStep,
    uuid,
    false
  );
};
