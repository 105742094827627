export interface BankingRequest {
  uuid: string;
  created_at: string;
  aasm_state: BankingRequestStatuses;
  onboarding_url: string;
  notary_product: {
    name: string;
    stage: string;
    uuid: string;
    notary_product_type: {
      name: string;
    };
  };
}

export enum BankingRequestStatuses {
  WAITING_FOR_INCORPORATION = 'waiting_for_incorporation',
  WAITING_FOR_DATA = 'waiting_for_data',
  WAITING_FOR_DOCUMENTS = 'waiting_for_documents',
  PENDING_REVIEW = 'pending_review',
  REVIEW_APPROVED = 'review_approved',
  REVIEW_REJECTED = 'review_rejected',
  REVIEW_CANCELLED = 'review_cancelled',
  COMPLETED = 'completed'
}
