












































































































import { defineComponent } from '@vue/composition-api';
import { SQTable, SQMainHeader } from '../base';
import { useActiveContracts } from './active-contracts-list';
import MakeCopyModal from '../../components/documents/contract-preview/modals/MakeCopyModal.vue';
import Panel from './panel/Panel.handler.vue';
import { ASecondaryBtn } from '../base/app/buttons';

export default defineComponent({
  name: 'ActiveContractsList',
  components: {
    Panel,
    MakeCopyModal,
    SQTable,
    SQMainHeader,
    ASecondaryBtn
  },
  setup(_, { root }) {
    const {
      columns,
      rows,
      showMakeCopy,
      showDelete,
      contractToCopy,
      loading,
      pagination,
      filters,
      actions,
      itemsTotal,
      signatureWidget,
      getContracts,
      onItemClicked,
      search,
      onShowDelete,
      onDelete,
      onShowMakeCopy
    } = useActiveContracts(root);

    return {
      columns,
      rows,
      showDelete,
      showMakeCopy,
      contractToCopy,
      loading,
      pagination,
      filters,
      actions,
      itemsTotal,
      signatureWidget,
      getContracts,
      onItemClicked,
      search,
      onShowMakeCopy,
      onShowDelete,
      onDelete
    };
  }
});
