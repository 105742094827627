import { ApiService } from '@ligo/shared/utils';
import { TokenService, AuthToken, TokenInterface } from './token.service';
import { RESOURCES } from '../resources';
import Vue from 'vue';

class AuthenticationError extends Error {
  constructor(public errorCode: any, public message: any, public data: any) {
    super(message);
    this.name = this.constructor.name;
    this.data = data;
  }
}

const error = (error: any) => {
  if (error.request.status == 401 && localStorage.getItem('jwt')) {
    TokenService.removeToken();
    ApiService.removeHeader();
    const path = window.location.pathname.slice(1);
    void Vue['Router'].push({ name: 'login', query: { redirect_url: path } });
    void Vue['Store'].commit('authentication/logoutSuccess');
  }
  throw error;
};

const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
   **/
  auth: async function (data: any, registration: boolean): Promise<any> {
    try {
      const url = registration ? RESOURCES.REGISTER : RESOURCES.LOGIN;
      const response = await ApiService.post(url, data);
      const token = new AuthToken(response);
      token.save();
      ApiService.setAuth();
      const me_response: any = await ApiService.get(RESOURCES.ME);
      token.userHash = me_response.data.user_hash;
      token.profileImage = me_response.data.profile_image;
      token.save();
      this.mountInterceptor(error);
      return token;
    } catch (error) {
      const errorCode: string = error.response.data.error_code;
      const errorMessage: Array<string> = registration
        ? error.response.data.errors.full_messages[0]
        : error.response.data.errors[0];
      console.log('Error on auth', errorCode, errorMessage);
      throw new AuthenticationError(
        errorCode,
        errorMessage,
        error.response.data
      );
    }
  },
  async redirection_auth(data: TokenInterface) {
    const token = new AuthToken(undefined, data);
    token.save();
    ApiService.setAuth();
    this.mountInterceptor(error);
    const response: any = await ApiService.get(RESOURCES.ME);
    token.firstname = response.data.firstname;
    token.lastname = response.data.lastname;
    token.userUuid = response.data.uuid;
    token.email = response.data.email;
    token.userHash = response.data.user_hash;
    token.userUuid = response.data.uuid;
    token.profileImage = response.data.profile_image;
    token.save();
    ApiService.setAuth();
    return token;
  },
  confirm: async function (token: any): Promise<boolean> {
    try {
      await ApiService.post(RESOURCES.CONFIRMATION, {
        token: token
      });
      return true;
    } catch (error) {
      return false;
    }
  },
  resetPassword: async function (data: any) {
    const response = ApiService.post(RESOURCES.RESET_PASSWORD, data);
    return response;
  },
  changePassword: async function (data: any) {
    const response = ApiService.post(RESOURCES.PASSWORD_CONFIRM, data);
    return response;
  },
  /**
   * Logout the current user by removing the token from storage.
   **/
  async logout() {
    const response = await ApiService.delete(RESOURCES.LOGOUT).catch(
      (error) => {
        console.log('__LOGOUT ERROR', error);
      }
    );
    TokenService.removeToken();
    ApiService.removeHeader();
    ApiService.unmount401Interceptor();
    Vue['Store'].commit('stepper/hideStepper');
    window.location.replace(RESOURCES.LIGO);
    return response;
  },
  mountInterceptor() {
    if (ApiService._401interceptor == 0) ApiService.mount401Interceptor(error);
  }
};

export default UserService;

export { UserService, AuthenticationError };
