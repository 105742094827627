























































































































































import { defineComponent } from '@vue/composition-api';
import { useSigningDocumentConfig } from './signing-document.hook';
import { ESignBanner, ESignDrawer, ESignFieldToFill } from './components';
import { SignDocumentBoard } from './components/boards';
import {
  ConfirmationModal,
  SignSuccessModal,
  DeclineModal,
  FillDateModal,
  FillTextModal,
  SuccessWithoutAccountModal,
  CheckboxModal,
  SignModal
} from './components/modals';
import { FieldTypes } from '../../../models';
import { setupSigningInterceptors } from '@ligo/dashboard/customer/store';
import PageLoader from '../../global/PageLoader.vue';
import { useRouter } from '../../../composables';
import { simpleClickEvent } from './utils/events';

const i18nContent = {
  board: {
    subtitle: 'e_signature.sign_document.board.subtitle',
    actions: {
      continue: 'e_signature.sign_document.board.actions.continue'
    },
    decline: {
      title: 'e_signature.sign_document.board.decline.title',
      text: 'e_signature.sign_document.board.decline.text'
    }
  },
  modals: {
    fillText: {
      title: 'e_signature.sign_document.modals.fill_text.title'
    },
    fillDate: {
      title: 'e_signature.sign_document.modals.fill_date.title'
    }
  }
};

export default defineComponent({
  name: 'SigningDocument',
  components: {
    ESignBanner,
    ESignDrawer,
    SignDocumentBoard,
    SignModal,
    ConfirmationModal,
    SignSuccessModal,
    DeclineModal,
    FillDateModal,
    FillTextModal,
    SuccessWithoutAccountModal,
    CheckboxModal,
    PageLoader,
    ESignFieldToFill
  },
  props: {
    contractUuid: {
      type: String,
      required: true
    },
    signerUuid: {
      type: String,
      required: true
    },
    sessionUuid: {
      type: String,
      required: true
    }
  },
  setup(props) {
    setupSigningInterceptors();
    const router = useRouter();
    const {
      loading,
      contract,
      signDialog,
      textDialog,
      dateDialog,
      checkboxDialog,
      fields,
      pageLoading,
      missingRequiredSigned,
      showConfirmDialog,
      showDeclineDialog,
      showSuccessDialog,
      showSuccessWithoutAccountDialog,
      signerEmail,
      signSuccessfully,
      documentDeclined,
      showDeclineCta,
      showBackCta,
      usedSignatures,
      amountPendingSignatures,
      onDeleteSignature,
      onDeclineConfirmation,
      onCheckingBox,
      onSetDate,
      onSetText,
      onFieldClick,
      goBack,
      onSign,
      onSave,
      onClear,
      onGoToField,
      getPageFields,
      getFieldMeasures,
      initSignaturePad,
      focusNextField
    } = useSigningDocumentConfig(
      props.contractUuid,
      props.signerUuid,
      props.sessionUuid,
      !!router.currentRoute.query['origin']
    );

    const { mouseClickDown: initClick, isSimpleClick } = simpleClickEvent();

    function handleCloseModalWithoutAccount(event) {
      showSuccessWithoutAccountDialog.value = event;
      onSave();
    }

    return {
      FieldTypes,
      loading,
      contract,
      signDialog,
      textDialog,
      dateDialog,
      checkboxDialog,
      fields,
      pageLoading,
      i18nContent,
      missingRequiredSigned,
      showConfirmDialog,
      showDeclineDialog,
      showSuccessDialog,
      showSuccessWithoutAccountDialog,
      signerEmail,
      signSuccessfully,
      documentDeclined,
      showDeclineCta,
      showBackCta,
      usedSignatures,
      amountPendingSignatures,
      initClick,
      isSimpleClick,
      onDeleteSignature,
      onDeclineConfirmation,
      onCheckingBox,
      onSetDate,
      onSetText,
      onFieldClick,
      goBack,
      onSign,
      onSave,
      onClear,
      onGoToField,
      getPageFields,
      getFieldMeasures,
      initSignaturePad,
      handleCloseModalWithoutAccount,
      focusNextField
    };
  }
});
