



















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'FormCard',
  props: {
    title: {
      type: String,
      required: true
    }
  }
});
