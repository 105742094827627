
































import { defineComponent } from '@vue/composition-api';
import { useAddDocument } from '../../components/documents/panel/add-document.hook';

import ActiveContractsList from '../../components/documents/ActiveContractsList.vue';
import BasePage from '../../components/base/BasePage.vue';
import AlertModal from '../../components/base/modals/AlertModal.vue';

export default defineComponent({
  name: 'ActiveContracts',
  components: {
    ActiveContractsList,
    BasePage,
    AlertModal
  },
  setup() {
    const {
      newDocumentDialog,
      signedDocDialog,
      fileInput,
      fileForUpload,
      uploading,
      fileName,
      onFileUpload,
      onSubmitFile
    } = useAddDocument();

    return {
      newDocumentDialog,
      signedDocDialog,
      fileForUpload,
      fileInput,
      uploading,
      fileName,
      onFileUpload,
      onSubmitFile
    };
  }
});
