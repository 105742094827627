import { Dictionary } from "./types";

export function lense(item: Dictionary, path: string) {
  const paths = path.split(".");
  return zoom(item, paths);
}

export function zoom(item: Dictionary, paths: string[]): any {
  if (paths.length === 1) {
    return item[paths[0]];
  }
  return item[paths[0]] && zoom(item[paths[0]], paths.slice(1));
}

export const lenseFactory = (path: string) => (value: Dictionary) =>
  lense(value, path);
