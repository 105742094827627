import { Dictionary, nully } from '@ligo/shared/utils';
import { BasicFieldClassMap, BasicFieldType, GConstructor } from './Field';
import { FieldDcl } from './FieldDcl';
import { TypeData } from './Resource';

export interface FormConfig<T = FieldDcl<any>> {
  sections?: { [id: number]: string };
  after_submit?: () => void;
  sendStep?: boolean;
  custom_fields?: { [id: string]: T };
  label?: string;
}

export type FieldSet<T> = {
  [P in keyof T]?: FieldDcl<T[P]>;
};

export interface ResourceInterface<T> {
  getFields: (id: string) => FieldSet<T>;
  getValues: (id: string) => TypeData<T>;
  getLocale: (id: string) => string;
  updateValues: (
    form: BasicForm<any>,
    send: boolean,
    config: FormConfig
  ) => Promise<void>;
}

export class BasicForm<TField extends BasicFieldType = BasicFieldType> {
  id: string;
  fields: { [id: string]: TField };
  layout: Array<Array<string>>;
  resource: ResourceInterface<any>;
  config: FormConfig;

  constructor(
    id: string,
    layout: Array<Array<string>>,
    resource: ResourceInterface<any>,
    config: FormConfig = { sendStep: true }
  ) {
    this.fields = {};
    this.id = id;
    this.config = config;
    this.resource = resource;
    this.layout = layout;
    this.initializeForm();
    this.instanceFields();
    this.processExtraValidations();
  }

  async save(send = true) {
    await this.resource.updateValues(this, send, this.config);
  }

  toDict() {
    const resp: { [id: string]: any } = {};
    this.keys().forEach((key) => {
      const field = this.fields[key];
      resp[field.key] = field.processValue(field.value);
    });
    return resp;
  }

  reset() {
    this.keys().forEach((key) => {
      this.fields[key].value =
        this.fields[key].defaultValue != null
          ? this.fields[key].defaultValue
          : null;
    });
  }

  keys() {
    return Object.keys(this.fields);
  }

  initializeForm() {
    this.config.label = this.config.label ?? 'next';
  }

  getFieldClassMap(): Dictionary<GConstructor<BasicFieldType>> {
    return BasicFieldClassMap as Dictionary<GConstructor<BasicFieldType>>;
  }

  processCustomFields() {
    const fieldDcls = Object.assign({}, this.resource.getFields(this.id));
    if (this.config?.custom_fields) {
      Object.keys(this.config.custom_fields).forEach((key) => {
        if (!nully(fieldDcls[key])) {
          const copy = Object.assign({}, fieldDcls[key]);
          fieldDcls[key] = Object.assign(copy, this.config.custom_fields[key]);
        }
      });
    }
    return fieldDcls;
  }

  instanceField(fieldDcl: FieldDcl<any>, key: string, value) {
    const fieldClassMap = this.getFieldClassMap();
    const fieldClass = fieldDcl.type
      ? fieldClassMap[fieldDcl.type]
      : fieldClassMap['text'];
    const field = new fieldClass(
      key,
      this.resource.getLocale(this.id),
      fieldDcl,
      value
    ) as TField;
    Object.assign(field, fieldDcl);
    return this.handleDefaultValue(field, fieldDcl);
  }

  handleDefaultValue(field: TField, fieldDcl: FieldDcl<any>) {
    const applyFilter = this.shouldApplyFilter(fieldDcl);
    if (fieldDcl.defaultValue != null && field.value === null) {
      field.value = fieldDcl.defaultValue;
    } else if (applyFilter) {
      field.value =
        applyFilter(this.resource.getValues(this.id)[field.key]) ?? null;
    }
    return field;
  }

  instanceFields() {
    const fieldDcls = this.processCustomFields();
    const values = this.resource.getValues(this.id);
    this.layout.forEach((row) => {
      row.forEach((field) => {
        const fieldDcl = fieldDcls[field];
        if (fieldDcl) {
          this.fields[field] = this.instanceField(
            fieldDcl,
            field,
            values[field]
          );
        }
      });
    });
  }

  shouldApplyFilter(field: FieldDcl<any>, custom?: FieldDcl<any>) {
    return (
      ('filter' in field && field.filter) ||
      (custom && 'filter' in custom && custom.filter)
    );
  }

  processExtraValidations() {
    return;
  }
}
