import { nully } from '@ligo/shared/utils';
import { ShareholderType } from '../share';
import { NotaryProductResource } from './NotaryProductResource';

export const productLocale = (
  notary_product: { locale: string },
  key: string
) => `${notary_product.locale}.${key}`;

export const acceptShareholder = (
  resource: NotaryProductResource<ShareholderType>
) => {
  return (
    resource.active &&
    !nully(resource.values['is_natural_person']) &&
    !nully(resource.values['is_existing_company']) &&
    !nully(resource.values['is_new_company'])
  );
};
