var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-item',{staticClass:"no-padding border-radius-8 text-default font-button",attrs:{"clickable":""}},[_c('q-card',{class:{
      'full-width': true,
      'border-primary-1100 text-default': _vm.active,
      'w-400 text-disabled': !_vm.active
    },attrs:{"flat":"","bordered":""}},[_c('q-item',[(_vm.icon)?_c('q-item-section',{staticClass:"no-padding min-width-10",attrs:{"avatar":""}},[_c('q-icon',{attrs:{"color":_vm.active ? _vm.color : 'grey-14',"name":_vm.icon}})],1):_vm._e(),_c('q-item-section',{class:{
          'no-padding': true,
          'q-ml-sm': _vm.icon,
          'text-center q-mx-sm': !_vm.icon && !_vm.deleteItem
        }},[_c('q-item-label',[_vm._v(_vm._s(_vm.label))])],1),(_vm.deleteItem)?_c('q-item-section',{staticClass:"no-padding",attrs:{"avatar":""}},[_c('q-icon',{attrs:{"color":_vm.iconColor,"name":"remove_circle_outline","size":"xs"},on:{"click":function($event){return _vm.emit('delete', _vm.index, $event)}}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }