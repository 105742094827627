



























































import { defineComponent } from '@vue/composition-api';
import {
  SLigoAssistant,
  SLigoAssistantChatBalloon,
  AssistantState
} from '@ligo/shared/components';
import { APrimaryBtn, ASecondaryBtn } from '../../app/buttons/';

export default defineComponent({
  name: 'CustomerAssistant',
  components: {
    SLigoAssistant,
    SLigoAssistantChatBalloon,
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    assistantStates: {
      type: Array as () => AssistantState[],
      required: true
    }
  }
});
