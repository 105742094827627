import { AxiosResponse } from 'axios';

const KEY = 'jwt';

export interface TokenInterface {
  client: string;
  uid: string;
  access_token: string;
  firstname: string;
  lastname: string;
  expiry: string;
  userPhone: string;
  userId: string;
  userUuid?: string;
  email?: string;
  userHash?: string;
  profileImage?: string;
}

export class AuthToken implements TokenInterface {
  client = '';
  uid = '';
  access_token = '';
  firstname = '';
  lastname = '';
  expiry = '';
  userPhone = '';
  userId = '';
  userUuid = '';
  email = '';
  userHash = '';
  profileImage = '';

  constructor(request?: AxiosResponse, data?: TokenInterface) {
    if (request) {
      this.fromResponse(request);
    } else {
      if (data) {
        this.fromData(data);
      }
    }
  }

  fromResponse(response: AxiosResponse) {
    this.client = response.headers['client'];
    this.uid = response.headers['uid'];
    this.expiry = response.headers['expiry'];
    if (response.headers['access-token'])
      this.access_token = response.headers['access-token'];

    this.firstname = response.data.data.firstname;
    this.lastname = response.data.data.lastname;
    this.userPhone = response.data.data.phone;
    this.userId = response.data.data.id;
    this.userUuid = response.data.data.uuid;
    this.email = response.data.data.email;
    this.userHash = response.data.data.user_hash;
    this.profileImage = response.data.data.profile_image;
  }

  fromData(data: TokenInterface) {
    this.client = data.client;
    this.uid = data.uid;
    this.expiry = data.expiry;
    this.access_token = data.access_token;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.userId = data.userId;
    this.userPhone = data.userPhone;
    this.userUuid = data.userUuid;
    this.email = data.email;
    this.userHash = data.userHash;
    this.profileImage = data.profileImage;
  }

  save() {
    if (this.access_token) {
      console.log('___SAVE TOKEN _______');
      localStorage.setItem(KEY, JSON.stringify(this));
    }
  }

  refresh() {
    if (this.access_token) {
      console.log('Should refresh token');
      // Has problems with API
      // ApiService.get(RESOURCES.REFRESH)
      //   .then(response => {
      //     console.log(
      //       '_____REFRESH RESPONSE______',
      //       response,
      //       response.headers
      //     );
      //     this.fromResponse(response);
      //     this.save();
      //     ApiService.setHeaders(this);
      //   })
      //   .catch(error => {
      //     console.log('______VALIDATION ERROR______', error);
      //   });
    }
  }
}

export const TokenService = {
  getToken(): AuthToken | null {
    const local_data: string | null = localStorage.getItem(KEY);
    return local_data
      ? new AuthToken(undefined, JSON.parse(local_data) as TokenInterface)
      : null;
  },
  removeToken(): void {
    localStorage.removeItem(KEY);
  },
  checkToken(): boolean {
    return localStorage.getItem(KEY) ? true : false;
  }
};
