export default {
  setting_page: 'Account',
  account_page: 'Plan en Facturen',
  plan: {
    title: 'Jouw Plan voor juridische documenten',
    subtitle: 'Je hebt momenteel een <b> {plan} </b> Plan',
    free: 'Je hebt momenteel een Gratis Account',
    upgrade: 'Upgrade',
    cancel: 'Plan annuleren',
    change: 'Plan wijzigen',
    renew: ' Nieuwe betaaltermijn {date}',
    downgrade: 'Je hebt dit plan opgezegd op {date}'
  },
  billing: {
    title: 'Factuurgegevens',
    save: 'Opslaan',
    cancel: 'Annuleren'
  },
  payment: {
    title: 'Betalingen en facturen',
    name: 'Beschrijving',
    date: 'Datum',
    total: 'Totaal',
    actions: {
      pay: 'Betalen',
      done: 'Betaald',
      view: 'Bekijk',
      error: 'Betalingsfout',
      open: 'Open',
      authorized: 'Geautoriseerd',
      refused: 'Geweigerd',
      cancelled: 'Geannuleerd',
      pending: 'In afwachting',
      chargedback: 'Gestorneerd'
    },
    filters: {
      aasm_state: 'Status',
      paid_at: 'Betaald bij'
    }
  },
  profile: {
    title: 'Bewerk mijn profiel',
    language: 'Bewerk mijn profiel',
    firstname: 'Voornaam',
    lastname: 'Achternaam',
    phone: 'Telefoonnummer',
    phone_required: 'Telefoonnummer is verplicht',
    firstname_required: 'Voornaam is verplicht',
    lastname_required: 'Achternaam is verplicht',
    email: 'E-mailadres',
    old_password: 'Huidig wachtwoord',
    old_password_required: 'Huidig ​​wachtwoord is vereist',
    new_password: 'Nieuw wachtwoord',
    new_password_required: 'Nieuw wachtwoord is vereist',
    new_password_confirmation: 'Bevestiging nieuw wachtwoord',
    new_password_confirmation_required:
      'Nieuwe wachtwoordbevestiging is vereist',
    change_info: 'Wijzig gegevens',
    change_password: 'Wijzig wachtwoord',
    current_password_placeholder: 'Voer je huidige wachtwoord in',
    new_password_placeholder: 'Voer je nieuwe wachtwoord in',
    confirm_placeholder: 'Herhaal je nieuwe wachtwoord',
    passwords_match: 'Wachtwoorden moeten overeenkomen',
    password_minimum: 'Wachtwoorden moeten minimaal 8 tekens hebben',
    info_success: 'Uw accountinstellingen zijn succesvol bijgewerkt',
    info_error:
      'Er is een fout opgetreden tijdens het updaten van je accountinstellingen',
    password_success: 'Je wachtwoord is succesvol bijgewerkt',
    password_error:
      'Er is een fout opgetreden tijdens het bijwerken van uw wachtwoord',

    personal_section: 'Persoonsgegevens',
    password_section: 'Account wachtwoord',
    locale_section: 'Taal',

    save_info: 'Wijzigingen opslaan',

    cancel_membership: {
      title_first_part: `Je hebt je`,
      title_middle: 'op',
      title_second_part: `geannuleerd. Kies een ander abonnement dat het beste bij je past om te blijven genieten van alle functies van Ligo!`
    }
  }
};
