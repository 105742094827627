import { ApiService } from '@ligo/shared/utils';
import { Step } from './Step';
import { BasicForm, Resource } from '@ligo/shared/mvc';
import { NotaryProductResource } from './NotaryProductResource';
import { ProductField } from './ProductField';

type apiFunction = (product: NotaryProduct) => Promise<void>;

export enum NotaryProductSlug {
  BV_OPRICHTEN = 'bv-oprichten',
  HOLDING_OPRICHTEN = 'persoonlijke-holding-oprichten',
  INCORPORATE_DUTCH_BV = 'incorporate-dutch-bv',
  OMZETTING = 'omzetting-naar-bv',
  STICHTING_OPRICHTEN = 'stichting-oprichten',
  ANBI_STICHTING = 'anbi-stichting-oprichten',
  TRANSFER_OF_SHARES = 'aandelenoverdracht',
  INCORPORATE_HOLDING = 'incorporate-holding'
}

export class NotaryProduct extends Resource<any> {
  uuid = '';
  url: string;
  locale: string;
  path: string;
  stepperDefinition: Array<Step>;
  step = 0;
  temporalStep = 0;
  substep = 0;
  completed = false;
  resources: { [id: string]: NotaryProductResource<any> };
  saveToAPI: (product: NotaryProduct, sendStep: boolean) => Promise<void>;
  loadFromAPI: apiFunction;
  handler: string;
  typeId: number;
  slug: NotaryProductSlug;
  upsellPackageData: any;
  finalStep: number;
  holdingUpsellStep: number;
  calculationUuid?: string;
  language = 'nl';

  constructor(
    resources: { [id: string]: NotaryProductResource<any> },
    locale: string,
    url: string,
    path: string,
    saveToAPI: (product: NotaryProduct, sendStep: boolean) => Promise<void>,
    loadFromAPI: apiFunction,
    handler: string,
    stepperDefinition: Array<Step>,
    typeId: number,
    slug: string,
    finalStep: number,
    uuid?: string,
    loadOnCreate = true,
    holdingUpsellStep?: number,
    language = 'nl'
  ) {
    super(
      { url: url, locale: locale, loadOnCreate: loadOnCreate },
      'notary-product',
      locale,
      uuid
    );
    this.resources = resources;
    this.saveToAPI = saveToAPI;
    this.loadFromAPI = loadFromAPI;
    this.path = path;
    this.handler = handler;
    this.stepperDefinition = stepperDefinition;
    this.typeId = typeId;
    this.slug = slug as NotaryProductSlug;
    this.finalStep = finalStep;
    this.holdingUpsellStep = holdingUpsellStep;
    this.language = language;
    if (uuid) {
      this.uuid = uuid;
      if (loadOnCreate) {
        void this.load();
      }
    }
  }

  async performSave(config = { sendStep: true }): Promise<void> {
    await this.saveToAPI(this, config.sendStep);
  }

  async save(send = true, config?: { sendStep: boolean }) {
    await super.save(send, config);
    this.saveToStore();
  }

  async performLoad(): Promise<void> {
    return this.loadFromAPI(this);
  }

  getFields(id: string) {
    return this.resources[id].fields;
  }

  getValues(id: string) {
    return this.resources[id].values;
  }

  getLocale(id: string) {
    return this.resources[id].locale;
  }

  async create(data = {}) {
    const response: any = await ApiService.post(this.url, data);
    this.uuid = response.data.uuid;
    this.calculationUuid = response.data.calculation?.uuid;
    this.updateFromJSON(response.data);
    return response;
  }

  async updateValues(
    form: BasicForm<ProductField>,
    send = false,
    config = { sendStep: true }
  ) {
    if (this.resources[form.id]) {
      this.resources[form.id].updateFromForm(form);
      if (send) await this.save(send, { sendStep: config.sendStep });
    }
  }

  getStepIncrement() {
    return this.holdingUpsellStep &&
      this.temporalStep + 1 == this.holdingUpsellStep &&
      !this.showHoldingStep()
      ? 2
      : 1;
  }

  nextStep() {
    this.temporalStep += this.getStepIncrement();
    this.step = this.temporalStep;
    this.saveToStore();
  }

  prevStep() {
    this.temporalStep = this.temporalStep > 0 ? this.temporalStep - 1 : 0;
    if (
      this.holdingUpsellStep &&
      this.temporalStep == this.holdingUpsellStep &&
      !this.showHoldingStep()
    )
      this.temporalStep -= 1;
    this.saveToStore();
  }

  goToStep(index: number) {
    this.temporalStep = index;
    this.saveToStore();
  }

  setStepBounds() {
    if (this.step < 0) this.step = 0;
    else if (this.step > this.finalStep + 2) this.step = this.finalStep + 2;
    this.saveToStore();
  }

  syncSteps() {
    this.step = this.temporalStep;
    this.saveToStore();
  }

  goToSubstep(index: number) {
    this.substep = index;
  }

  saveToStore() {
    const steps = localStorage.getItem('stepsInfo');
    const stepsInfo: { [id: string]: any } = JSON.parse(steps ? steps : '{}');
    stepsInfo[this.uuid] = {
      step: this.step,
      temporalStep: this.temporalStep,
      substep: this.substep
    };
    localStorage.setItem('stepsInfo', JSON.stringify(stepsInfo));
  }

  showHoldingStep() {
    for (const [key, value] of Object.entries(this.resources)) {
      if (
        key.length == 2 &&
        key[0] == 'p' &&
        value.active &&
        value.values.is_natural_person
      ) {
        return true;
      }
    }
  }

  async complete() {
    const request = `${this.url}${this.uuid}/complete`;
    const response = await ApiService.post(request);
    return response;
  }

  linkToContinue() {
    return `/questionnaire/${this.path}/${this.uuid}`;
  }

  showStepper() {
    return this.step <= this.finalStep && this.temporalStep <= this.finalStep;
  }

  updateFromJSON(data) {
    this.resources['main'].updateFromJSON(data.product_data);
    const shareholder_keys: Array<any> = ['p1', 'p2', 'p3', 'p4'];
    shareholder_keys.forEach((key) => {
      const shareholderData = data.product_data[key];
      if (shareholderData) {
        this.resources[key].active = true;
        this.resources[key].updateFromJSON(shareholderData);
        this.resources[key].substep = shareholderData['substep'] || 0;
      }
    });
  }
}
