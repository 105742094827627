










import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'MarkdownEditor',
  model: {
    prop: 'content',
    event: 'contentChange'
  },
  props: {
    content: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      default: () => {
        return {
          hideIcons: ['image', 'fullscreen', 'side-by-side', 'guide'],
          status: false,
          spellChecker: false,
          autofocus: true
        };
      }
    }
  },
  setup() {
    const markdownEditor = ref();

    return { markdownEditor };
  }
});
