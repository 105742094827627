import { IncorporateHoldingType } from '../definitions/incorporate_holding';
import { LocalResource, NotaryProductResource } from '@ligo/bv-flow/store';
import {
  commonProductFields,
  sharedBVProductFields
} from '../../shared/commonProductFields';

export const bvOprichten: LocalResource<IncorporateHoldingType> = {
  url: '',
  locale: 'main',
  fieldsDescriptions: {
    ...sharedBVProductFields,
    ...commonProductFields
  }
};

export function useMainResource() {
  return {
    main: new NotaryProductResource(bvOprichten, 'main', 'incorporate_holding')
  };
}
