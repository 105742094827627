









import {
  defineComponent,
  onMounted,
  getCurrentInstance
} from '@vue/composition-api';
export default defineComponent({
  name: 'SvgManipulation',
  props: {
    svgPath: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#000000'
    },
    height: String,
    width: String
  },
  setup(props) {
    const instanceId = (getCurrentInstance() as any)._uid;
    onMounted(() => {
      const objectElement = document.querySelector(
        `#SVG-${instanceId}`
      ) as HTMLObjectElement;
      objectElement.style.visibility = 'hidden';
      objectElement.onload = () => {
        const svgDoc = objectElement.contentDocument;
        const paths = svgDoc.getElementsByTagName('path');
        for (let i = 0; i < paths.length; i++) {
          paths[i].style.fill = props.color;
        }
        objectElement.style.visibility = 'visible';
      };
    });
    return { instanceId };
  }
});
