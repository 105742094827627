import { GetterTree, Module, ActionTree, MutationTree } from 'vuex';
import { CustomerDashboardState, CompanyState } from '../state';
import axios from 'axios';

export const COMPANY_KEY = 'Company-id';
export const COMPANIES_AMOUNT_KEY = 'companies-amount';

const state: CompanyState = {
  selectedId: localStorage.getItem(COMPANY_KEY),
  companiesAmount: Number(localStorage.getItem(COMPANIES_AMOUNT_KEY))
};

const getters: GetterTree<CompanyState, CustomerDashboardState> = {
  getCompanyId(state) {
    return state.selectedId;
  },
  getCompaniesAmount(state) {
    return state.companiesAmount;
  }
};

const actions: ActionTree<CompanyState, CustomerDashboardState> = {
  select({ commit }, id) {
    localStorage.setItem(COMPANY_KEY, id);
    axios.defaults.headers.common[COMPANY_KEY] = id;
    commit('setCompany', id);
  },
  setCompaniesAmount({ commit }, amount) {
    localStorage.setItem(COMPANIES_AMOUNT_KEY, amount);
    commit('setCompaniesAmount', amount);
  },
  cleanState({ commit }) {
    commit('clean');
  }
};

const mutations: MutationTree<CompanyState> = {
  setCompany(state, id: string) {
    state.selectedId = id;
  },
  setCompaniesAmount(state, amount: number) {
    state.companiesAmount = amount;
  },
  clean(state) {
    state.selectedId = null;
    state.companiesAmount = 0;
  }
};

export const company: Module<CompanyState, CustomerDashboardState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};
