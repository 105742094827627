import { useAwaitDOMNode } from './dom-mutation-observer';

const PRECISION = 0.03;
let observer;

function initDetection(element: HTMLElement, callback: () => void) {
  function onRefresh() {
    const rect = element.getBoundingClientRect();
    const y = rect.top + window.scrollY;
    const x = rect.left + window.scrollX;
    const w = rect.width;
    const h = rect.height;

    callback();

    const rootMargin = `${-y}px ${-(window.innerWidth - (x + w))}px ${-(
      window.innerHeight -
      (y + h)
    )}px ${-x}px`;

    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (Math.abs(1 - entry.intersectionRatio) >= PRECISION) {
            observer.disconnect();
            onRefresh();
          }
        });
      },
      {
        root: null,
        rootMargin,
        threshold: 1
      }
    );

    observer.observe(element);
  }

  onRefresh();
}

export function useMovementDetector(selector: string, callback: () => void);
export function useMovementDetector(
  selector: HTMLElement,
  callback: () => void
);

export function useMovementDetector(
  selector: any,
  callback = () => {
    return;
  }
) {
  if (typeof selector === 'string')
    useAwaitDOMNode([selector]).then(([node]) => {
      initDetection(node, callback);
    });
  else initDetection(selector, callback);

  function cleanUp() {
    observer?.disconnect();
  }

  return {
    cleanUp
  };
}
