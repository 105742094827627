





































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { Signer } from '../../../../../models';
import { MenuSelect, MenuCta, RequiredOption } from './base';

const i18nContent = {
  assigned: 'e_signature.prepare_document.edit_menu.assigned',
  actions: {
    title: 'e_signature.prepare_document.edit_menu.actions.title',
    copy: 'e_signature.prepare_document.edit_menu.actions.copy',
    delete: 'e_signature.prepare_document.edit_menu.actions.delete'
  },
  required: 'e_signature.prepare_document.menu.required'
};

export default defineComponent({
  name: 'FieldMenu',
  components: { MenuSelect, MenuCta, RequiredOption },
  props: {
    signers: {
      type: Array as PropType<Signer[]>,
      required: true
    },
    selectedSignerEmail: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const selectedSigner = ref(
      props.signers.find((s) => s.email === props.selectedSignerEmail)
    );

    function changeSigner(signer: Signer) {
      selectedSigner.value = signer;
      emit('change-signer', signer);
    }

    function handleRequire() {
      emit('change-required');
    }

    return { selectedSigner, changeSigner, i18nContent, handleRequire };
  }
});
