import { Ref, ref } from '@vue/composition-api';
import { ApiService } from '@ligo/shared/utils';
import { useContractDetail } from '../../../../components/src/lib/components/documents/contract-detail.hook';
import { Signer, SignerSession } from '../../../../components/src/lib/models';
import { SIGNATURE_RESOURCES } from '@ligo/dashboard/customer/store';
import { AxiosResponse } from 'axios';
import Vue from 'vue';

const FORBIDDEN_ERROR = 403;

interface ArchiveSignaturePatch {
  content: string;
  email: string;
}

const enum errors {
  SIGNING_SESSION_EXPIRED = 'SIGNING_SESSION_EXPIRED',
  SIGNING_SESSION_COMPLETED = 'SIGNING_SESSION_COMPLETED',
  CONTRACT_SENT_FOR_SIGNING = 'CONTRACT_SENT_FOR_SIGNING',
  SIGNING_SESSION_DECLINED = 'SIGNING_SESSION_DECLINED'
}

export function setupSigningInterceptors() {
  ApiService.useInterceptors((error) => {
    if (error.request.status == FORBIDDEN_ERROR) {
      switch (error.response.data.error) {
        case errors.SIGNING_SESSION_EXPIRED:
          Vue['Router'].push({
            name: 'ErrorExpired'
          });
          break;
        case errors.SIGNING_SESSION_COMPLETED:
          Vue['Router'].push({
            name: 'ErrorCompleted'
          });
          break;
        case errors.CONTRACT_SENT_FOR_SIGNING:
          Vue['Router'].push({
            name: 'ErrorSentForSigning'
          });
        case errors.SIGNING_SESSION_DECLINED:
          Vue['Router'].push({
            name: 'ErrorDeclined'
          });
        default:
          break;
      }
    } else {
      throw error;
    }
  });
}

function parseFields(fields) {
  return fields.map((field) => ({
    ...field,
    coordinates: {
      x: field.coordinates.x,
      y:
        field.documentPageSize.height -
        field.coordinates.y -
        field.dimensions.height
    }
  }));
}

export function parserSignersResponse(
  response: AxiosResponse<Signer[]>
): Signer[] {
  return response.data.map((signer: Signer) => {
    return {
      ...signer,
      fields: parseFields(signer.fields).map((field) => ({
        signerEmail: signer.email,
        ...field
      }))
    };
  });
}

export const signatureService = {
  get: {
    contractPreview(contractUuid: string) {
      return useContractDetail(contractUuid);
    },

    staticContractPreview(contractUuid: string) {
      const loading = ref(true);
      const contract = ref(null);
      ApiService.get(SIGNATURE_RESOURCES.GET_CONTRACT(contractUuid))
        .then((response) => {
          contract.value = response.data;
        })
        .catch((error) => {
          console.log(
            `There was an error while fetching contract ${contractUuid}`,
            error
          );
        })
        .finally(() => {
          loading.value = false;
        });
      return { contract, loading };
    },

    signers(contractUuid: string) {
      return ApiService.get<Signer[]>(
        SIGNATURE_RESOURCES.GET_SIGNERS(contractUuid)
      );
    },

    parsedSigners(contractUuid: string) {
      const signers: Ref<Signer[]> = ref([]);
      const loading = ref(true);

      const load = () => {
        loading.value = true;
        this.signers(contractUuid)
          .then((response) => {
            signers.value = parserSignersResponse(response);
          })
          .finally(() => {
            loading.value = false;
          });
      };

      load();

      return { signers, loading, load };
    },

    reloadSigners(
      signers: Ref<Signer[]>,
      contractUuid,
      loading?: Ref<boolean>
    ) {
      const reloadLoading = ref(true);
      if (loading) loading.value = true;
      this.signers(contractUuid)
        .then((response) => {
          signers.value = parserSignersResponse(response);
        })
        .catch((e) => {
          console.log(`There was an error reloading the signers: ${e}`);
        })
        .finally(() => {
          if (loading) loading.value = false;
        });
      return { reloadLoading };
    },

    signerSession(
      contractUuid: string,
      signerUuid: string,
      sessionUuid: string
    ) {
      return ApiService.get<SignerSession>(
        SIGNATURE_RESOURCES.GET_SIGNER_SESSION(
          contractUuid,
          signerUuid,
          sessionUuid
        )
      );
    },

    parsedFields(
      contractUuid: string,
      signerUuid: string,
      sessionUuid: string
    ) {
      const loading = ref(true);
      const signer = ref<Signer>();

      this.signerSession(contractUuid, signerUuid, sessionUuid)
        .then((response) => {
          signer.value = {
            ...response.data.signer,
            drawing_signatures: response.data.signer.drawing_signatures.slice(
              0,
              3
            ),
            fields: parseFields(response.data.signer.fields)
          };
        })
        .catch((e) =>
          console.log(`There was an error parsing the fields: ${e}`)
        )
        .finally(() => {
          loading.value = false;
        });

      return { signer, loading };
    }
  },
  post: {
    addSigner(contractUuid: string, email: string) {
      return ApiService.post(SIGNATURE_RESOURCES.ADD_SIGNER(contractUuid), {
        email
      });
    },

    addSignerMultipleAll(contractUuid: string, emails: string[]) {
      const promises: Promise<AxiosResponse<any>>[] = [];
      emails.forEach((email) => {
        promises.push(this.addSigner(contractUuid, email));
      });
      return Promise.all(promises);
    },

    completeDocumentPreparation(
      contractUuid: string,
      message?: string,
      signature_cc_recipients?: string[],
      application?: string
    ) {
      return ApiService.post<
        {
          message: string | undefined;
          signature_cc_recipients: string[] | undefined;
          application: string | undefined;
        },
        Signer[]
      >(SIGNATURE_RESOURCES.SIGNERS_COMPLETE(contractUuid), {
        message,
        signature_cc_recipients,
        application
      });
    },

    singDocument(
      contractUuid: string,
      signerUuid: string,
      sessionUuid: string
    ) {
      return ApiService.post(
        SIGNATURE_RESOURCES.SIGN_DOCUMENT(contractUuid, signerUuid, sessionUuid)
      );
    },
    decline(contractUuid: string, signerUuid: string, sessionUuid: string) {
      return ApiService.post(
        SIGNATURE_RESOURCES.DECLINE_DOCUMENT(
          contractUuid,
          signerUuid,
          sessionUuid
        )
      );
    }
  },
  patch: {
    signer(contractUuid: string, signer: Signer) {
      return ApiService.patch(
        SIGNATURE_RESOURCES.UPDATE_SIGNER(contractUuid, signer.uuid),
        signer
      );
    },

    signerWithInvertedCoordinates(contractUuid: string, signer: Signer) {
      return this.signer(contractUuid, {
        ...signer,
        fields: signer.fields.map((field) => ({
          ...field,
          coordinates: {
            x: field.coordinates.x,
            y:
              field.documentPageSize.height -
              field.coordinates.y -
              field.dimensions.height
          }
        }))
      });
    },

    multipleSigners(contractUuid: string, signers: any[]) {
      signers.forEach((signer) => {
        this.signer(contractUuid, signer);
      });
    },

    multipleSignersAll(contractUuid: string, signers: any[]) {
      const propmises: Promise<AxiosResponse>[] = [];
      signers.forEach((signer) => {
        propmises.push(this.signer(contractUuid, signer));
      });
      return Promise.all(propmises);
    },

    archiveSignature(content: string, email: string) {
      return ApiService.patch<ArchiveSignaturePatch, string[]>(
        SIGNATURE_RESOURCES.ARCHIVE_SIGNATURE,
        {
          content: encodeURI(content),
          email: email
        }
      );
    }
  },
  delete: {
    signer(contractUuid: string, signerUuid: string) {
      return ApiService.delete(
        SIGNATURE_RESOURCES.DELETE_SIGNER(contractUuid, signerUuid)
      );
    },

    multpleSigners(contractUuid: string, signers: Signer[]) {
      const promises: Promise<AxiosResponse>[] = [];
      signers.forEach((signer) => {
        promises.push(this.signer(contractUuid, signer.uuid));
      });
      return Promise.all(promises);
    }
  }
};
