/*!
 * froala_editor v4.1.2 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2023 Froala Labs
 */

!(function (e, t) {
  'object' == typeof exports && 'undefined' != typeof module
    ? t(require('froala-editor'))
    : 'function' == typeof define && define.amd
    ? define(['froala-editor'], t)
    : t(e.FroalaEditor);
})(this, function (e) {
  'use strict';
  ((e =
    e && e.hasOwnProperty('default')
      ? e['default']
      : e).PLUGINS.quote = function (r) {
    var c = r.$;
    function s(e) {
      for (; e.parentNode && e.parentNode != r.el; ) e = e.parentNode;
      return e;
    }
    return {
      apply: function t(e) {
        r.selection.save(),
          r.html.wrap(!0, !0, !0, !0),
          r.selection.restore(),
          'increase' == e
            ? (function o() {
                var e,
                  t = r.selection.blocks();
                for (e = 0; e < t.length; e++) t[e] = s(t[e]);
                r.selection.save();
                var n = c(document.createElement('blockquote'));
                for (n.insertBefore(t[0]), e = 0; e < t.length; e++)
                  n.append(t[e]);
                r.opts.trackChangesEnabled && r.track_changes.addQuote(n),
                  r.html.unwrap(),
                  r.selection.restore();
              })()
            : 'decrease' == e &&
              (function a() {
                var e,
                  t = r.opts.trackChangesEnabled,
                  n = r.selection.blocks();
                for (e = 0; e < n.length; e++)
                  'BLOCKQUOTE' != n[e].tagName &&
                    (n[e] =
                      (t &&
                        c(n[e])
                          .parentsUntil(r.$el, '[data-track-id^=pending]')
                          .get(0)) ||
                      c(n[e]).parentsUntil(r.$el, 'BLOCKQUOTE').get(0));
                for (r.selection.save(), e = 0; e < n.length; e++)
                  n[e] &&
                    (t
                      ? r.track_changes.removeQuote(c(n[e]), e)
                      : c(n[e]).replaceWith(n[e].innerHTML));
                r.html.unwrap(), r.selection.restore();
              })();
      }
    };
  }),
    e.RegisterShortcut(e.KEYCODE.SINGLE_QUOTE, 'quote', 'increase', "'"),
    e.RegisterShortcut(e.KEYCODE.SINGLE_QUOTE, 'quote', 'decrease', "'", !0),
    e.RegisterCommand('quote', {
      title: 'Quote',
      type: 'dropdown',
      html: function () {
        var e = '<ul class="fr-dropdown-list" role="presentation">',
          t = { increase: 'Increase', decrease: 'Decrease' };
        for (var n in t)
          if (t.hasOwnProperty(n)) {
            var o = this.shortcuts.get('quote.'.concat(n));
            e += '<li role="presentation"><a class="fr-command fr-active '
              .concat(
                n,
                '" tabIndex="-1" role="option" data-cmd="quote" data-param1="'
              )
              .concat(n, '" title="')
              .concat(t[n], '">')
              .concat(this.language.translate(t[n]))
              .concat(
                o ? '<span class="fr-shortcut">'.concat(o, '</span>') : '',
                '</a></li>'
              );
          }
        return (e += '</ul>');
      },
      callback: function (e, t) {
        this.quote.apply(t);
      },
      plugin: 'quote'
    }),
    e.DefineIcon('quote', { NAME: 'quote-left', SVG_KEY: 'blockquote' });
});
