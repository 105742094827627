import { VueConstructor } from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

export enum BugsnagStage {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production'
}

export const bugsnag = (token: string, stage: BugsnagStage = BugsnagStage.STAGING) => ({
  Vue
}: {
  Vue: VueConstructor;
}) => {
  Bugsnag.start({
    apiKey: token,
    releaseStage: stage,
    plugins: [new BugsnagPluginVue()],
    enabledReleaseStages: [ BugsnagStage.PRODUCTION, BugsnagStage.STAGING, BugsnagStage.DEVELOPMENT ],
  });
  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue.installVueErrorHandler(Vue);
};
