





























































































import { defineComponent, PropType, Ref, ref } from '@vue/composition-api';
import { SocialBtn } from '../../models';
import FormCard from './FormCard.vue';
import NewRegisterForm from './RegisterForm.vue';
import SocialCard from './SocialCard.vue';

export default defineComponent({
  name: 'NewRegister',
  components: { SocialCard, FormCard, NewRegisterForm },
  props: {
    socialBtns: {
      type: Array as PropType<Array<SocialBtn>>,
      default: () => []
    },
    brand: {
      type: String,
      default: 'ligo'
    },
    brandIcon: {
      type: String,
      required: true
    },
    privacy: {
      type: String,
      required: true
    },
    terms: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const baseForm: Ref<any> = ref();

    const performRegister = function (data) {
      emit('perform-register', data);
    };

    const isInSocialAuth = ref(true);

    function onOpenPrivacy() {
      window.open(props.privacy);
    }

    function onOpenTerms() {
      window.open(props.terms);
    }

    return {
      performRegister,
      baseForm,
      isInSocialAuth,
      onOpenPrivacy,
      onOpenTerms
    };
  }
});
