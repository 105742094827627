







































import { defineComponent, ref } from '@vue/composition-api';
import { BasicField } from '../../models/Field';
import { Dictionary } from '@ligo/shared/utils';

export default defineComponent({
  name: 'OptionsField',
  props: {
    value: {
      type: null as any,
      required: true
    },
    field: {
      type: BasicField
    }
  },
  setup(props, { emit }) {
    const updateValue = (a: any) => {
      emit('input', a);
    };
    const phone = ref<Dictionary>({});
    const field_input = ref<any>(null);
    function phoneRule(value) {
      return !!value && !!phone.value && !!phone.value.valid;
    }
    function onValidate(value: Dictionary) {
      phone.value = value;
      if (!!props.value) field_input.value?.validate();
    }
    return {
      field_input,
      phone,
      onValidate,
      updateValue,
      phoneRule
    };
  }
});
