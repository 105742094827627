import { StichtingTypes } from '../definitions/stichting';
import { LocalResource, NotaryProductResource } from '@ligo/bv-flow/store';
import { visible_if_true } from '../../shared/conditions';
import {
  commonProductFields,
  sharedEmployeeFields
} from '../../shared/commonProductFields';

export const bvOprichten: LocalResource<StichtingTypes> = {
  url: '',
  locale: 'main',
  fieldsDescriptions: {
    ...commonProductFields,
    ...sharedEmployeeFields,
    upsell_package_type_product_ids: { type: 'multi-select', hidden: true },
    company_name: {},
    realizing_activities: {},
    president: {},
    secretary: {},
    treasurer: {},
    extra_shareholders: { type: 'bool-options' },
    remaining_roles: {
      dependency_dcl: {
        fieldParam: 'extra_shareholders',
        rule: visible_if_true
      }
    },
    reappointed: {
      type: 'number',
      mask: 'integer'
    },
    term_managing: {
      type: 'number',
      mask: 'integer'
    }
  }
};

export function useMainResource() {
  return {
    main: new NotaryProductResource(bvOprichten, 'main', 'stichting')
  };
}
