import { AxiosResponse } from 'axios';
import { ApiService, createGetService } from '@ligo/shared/utils';
import { Notification } from '../models/notification.model';

export const notificationServiceFactory = (notificationsUrl: string) => ({
  ...createGetService<Notification>(notificationsUrl, ApiService),
  markAsSeen(ids: string[]): Promise<AxiosResponse> {
    return ApiService.put(notificationsUrl, { ...(ids && { ids }) });
  }
});
