






















import { defineComponent, PropType, Ref, ref } from '@vue/composition-api';
import { SocialBtn } from '../../models';
import RegisterForm from './RegisterForm.vue';

export default defineComponent({
  name: 'Register',
  components: { RegisterForm },
  props: {
    socialBtns: {
      type: Array as PropType<Array<SocialBtn>>,
      default: () => []
    },
    brand: {
      type: String,
      default: 'ligo'
    }
  },
  setup(_, { emit }) {
    const baseForm: Ref<any> = ref();

    const performRegister = function (data) {
      emit('perform-register', data);
    };

    const performSocialAuth = function (socialBtn: SocialBtn) {
      emit('social-auth', socialBtn);
    };

    return {
      performRegister,
      performSocialAuth,
      baseForm
    };
  }
});
