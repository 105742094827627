import { ref, computed, ComputedRef, watch } from '@vue/composition-api';
import { AssistantPageInformation } from '../../../utils/useCustomerPageInformation.hooks';
import { AssistantState } from '@ligo/shared/components';
import { useStore } from '../../../../composables';
import { useStoreValue } from '@ligo/shared/utils';

export function useCustomerAssistant(
  storeStateGetter: string,
  storeActivateAction: string,
  storeDeactivateAction: string,
  assistantSlug: string,
  assistantConditions: {
    [key: string]: ComputedRef;
  } = {},
  conditionsToClear: { [key: string]: () => void } = {}
) {
  const store = useStore();
  const nextClick = ref(false);
  const dismissClick = ref(false);
  const assistantStates: AssistantState[] = [];
  const hiddenAssistant = ref(false);
  const isActive = useStoreValue(store)(storeStateGetter);
  const loadingAssistant = ref(true);

  const allConditions = {
    next: computed(() => nextClick.value),
    dismiss: computed(() => dismissClick.value),
    ...assistantConditions
  };

  const clearableConditions = {
    next: clearNextClick,
    ...conditionsToClear
  };

  const {
    t,
    load,
    useConfig: useLigoAssistantConfig,
    isReady: isAssistantReady
  } = AssistantPageInformation;

  load();

  function handleNextClick() {
    nextClick.value = true;
  }

  function handleDismissClick(state: AssistantState) {
    if (state.dontShowAgain?.value) deactivateAssistant();
    else hideAssistant();

    trackAction({
      action: 'dismiss',
      dontShowAgain: state.dontShowAgain?.value ? 'true' : 'false'
    });
  }

  function clearNextClick() {
    nextClick.value = false;
  }

  function activateAssistant() {
    store.dispatch(storeActivateAction);
  }

  function deactivateAssistant() {
    store.dispatch(storeDeactivateAction);
  }

  function hideAssistant() {
    hiddenAssistant.value = true;
  }

  function finalizeAssistant(state: AssistantState) {
    if (state.dontShowAgain?.value) {
      deactivateAssistant();
    }
  }

  function trackAction(interaction: { [key: string]: string }) {
    (window as any).analytics.track(assistantSlug, {
      ...interaction
    });
  }

  watch(
    isAssistantReady,
    (ready) => {
      if (ready) {
        try {
          const assistantConfig = useLigoAssistantConfig()[assistantSlug];
          Object.keys(assistantConfig.steps).forEach((key, index) => {
            const step = assistantConfig.steps[key];
            const conditions = Object.keys(step.transition).map(
              (condition) => ({
                condition: allConditions[condition],
                to: step.transition[condition],
                clear: clearableConditions[condition],
                slug: condition
              })
            );
            assistantStates.push({
              title: step.title && t(step.title),
              subtitle: step.subtitle && t(step.subtitle),
              info: step.info && t(step.info),
              targetHtmlSelector: step.target_html_selector,
              transition: computed(() => {
                for (let i = 0; i < conditions.length; i++) {
                  if (conditions[i].condition.value) {
                    conditions[i].clear && conditions[i].clear();
                    const to = conditions[i].to;
                    trackAction({
                      action: conditions[i].slug,
                      from: assistantStates[index].title,
                      to:
                        to != -1
                          ? assistantStates[conditions[i].to].title
                          : 'End'
                    });
                    return conditions[i].to;
                  }
                }
                return index;
              }),
              alignment: {
                right: step.alignment?.right ? 1 : 0,
                bottom: step.alignment?.bottom ? 1 : 0
              },
              offset: {
                x: step.offset?.x || 0,
                y: step.offset?.y || 0
              },
              arrowDirection: step.arrow_direction,
              actions:
                step.actions &&
                Object.keys(step.actions).reduce(
                  (acc, key) => ({
                    [key]: {
                      ...step.actions[key],
                      label: t(step.actions[key].label)
                    },
                    ...acc
                  }),
                  {}
                ),
              image: step.image,
              dontShowAgain: step.dont_show_again && {
                value: false,
                label: t(step.dont_show_again.label)
              }
            });
          });
          loadingAssistant.value = false;
        } catch (e) {
          console.error('There was an error defining the assistance : ', e);
        }
      }
    },
    { immediate: true }
  );

  return {
    assistantStates,
    isActive,
    hiddenAssistant,
    loadingAssistant,
    finalizeAssistant,
    hideAssistant,
    activateAssistant,
    deactivateAssistant,
    handleNextClick,
    handleDismissClick
  };
}
