import { CalculatorResource } from './CalculatorResource';
import { Dictionary } from '@ligo/shared/utils';
import { CalculatorDcl } from './CalculatorField';
import {
  TYPE_OF_CONVERSION,
  INCORPORATION_SPEED,
  TIME_ESTIMATION,
  REPRESENTATION_TYPES
} from './CalculatorTypeDefinition';
import { buildRule } from '@ligo/shared/mvc';

function toOptions(simpleOptions) {
  return simpleOptions.reduce((result, value) => {
    result.push({
      value: value,
      label: `options.${value}.label`,
      subtitle: `options.${value}.subtitle`
    });
    return result;
  }, []);
}

export const fieldDescriptions: Dictionary<CalculatorDcl<any>> = {
  converting: {
    type: 'options',
    boolean: true,
    tail: { fieldKey: 'type_of_conversion', transitionType: 'positive' },
    root: 'bv-product',
    transitions: [{ fieldKey: 'shareholder_count' }],
    customQuestionComponent: 'question-card-for-converting'
  },
  type_of_conversion: {
    type: 'select',
    required: false,
    options: toOptions(TYPE_OF_CONVERSION)
  },
  shareholder_count: {
    type: 'select',
    options: toOptions([1, 2, 3, 4]),
    transitions: [{ fieldKey: 'representation_type' }]
  },
  incorporation_speed: {
    type: 'options',
    options: toOptions(INCORPORATION_SPEED),
    transitions: [{ fieldKey: 'time_estimation' }]
  },
  time_estimation: {
    type: 'select',
    options: toOptions(TIME_ESTIMATION),
    transitions: [{ fieldKey: 'email' }]
  },
  email: {
    filter: (value: string) => {
      return value && value.toLowerCase();
    },
    rules: [buildRule('email', 'validations.calculator_email')]
  },
  representation_type: {
    type: 'select',
    options: toOptions(REPRESENTATION_TYPES),
    perShareholder: true,
    transitions: [
      {
        fieldKey: 'existing_company_omzetting',
        conditions: [
          {
            fieldKey: 'representation_type',
            transitionType: 'equals',
            value: 'existing_company'
          },
          {
            fieldKey: 'type_of_conversion',
            transitionType: 'equals',
            value: 'tax_free_transfer'
          }
        ]
      },
      {
        fieldKey: 'existing_company_omzetting',
        conditions: [
          {
            fieldKey: 'representation_type',
            transitionType: 'equals',
            value: 'existing_company'
          },
          {
            fieldKey: 'type_of_conversion',
            transitionType: 'equals',
            value: 'transfer_subject_to_tax'
          }
        ]
      },
      {
        fieldKey: 'ubos',
        conditions: [
          {
            fieldKey: 'representation_type',
            transitionType: 'equals',
            value: 'existing_company'
          }
        ]
      },
      {
        fieldKey: 'resident'
      }
    ],
    customQuestionComponent: 'question-card-for-participate'
  },
  ubos: {
    type: 'select',
    options: toOptions([0, 1, 2, 3, 4]),
    perShareholder: true,
    transitions: [{ fieldKey: 'resident' }],
    shareholderFilter: [
      {
        fieldKey: 'representation_type',
        transitionType: 'equals',
        value: 'existing_company'
      }
    ]
  },
  resident: {
    type: 'options',
    boolean: true,
    root: 'holding',
    perShareholder: true,
    transitions: [{ fieldKey: 'language_requirement' }]
  },
  language_requirement: {
    type: 'options',
    boolean: true,
    transitions: [
      {
        fieldKey: 'english_omzetting',
        conditions: [
          {
            fieldKey: 'converting',
            transitionType: 'positive'
          },
          {
            fieldKey: 'language_requirement',
            transitionType: 'negative'
          }
        ]
      },
      {
        fieldKey: 'time_estimation'
      }
    ]
  },
  english_omzetting: {
    type: 'blocker',
    customQuestionComponent: 'stop-card'
  },
  existing_company_omzetting: {
    type: 'blocker',
    customQuestionComponent: 'stop-card'
  }
};

export function buildCalculator(uuid?: string) {
  return new CalculatorResource(
    { fieldsDescriptions: fieldDescriptions, loadOnCreate: true },
    'fields',
    uuid
  );
}
