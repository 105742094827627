import VueGtm from '@gtm-support/vue2-gtm';
import { useEnvironment } from 'netlify_functions';
import { boot } from 'quasar/wrappers';

export default boot(({ Vue }) => {
  Vue.use(VueGtm, {
    id: useEnvironment().GTM_ID,
    defer: false,
    compatibility: true,
    enabled: true,
    debug: process.env.NODE_ENV === 'development',
    loadScript: true
  });
});
