export interface ContactCard {
  title: string;
  subtitle: string;
  contacts: string[];
  sections: ContactSection[];
}

interface ContactSection {
  title: string;
  contacts: Contact[];
}

interface Contact {
  icon: string;
  text: string;
  event: ConcactTypeEvent;
}

export enum ConcactTypeEvent {
  MEETING = 'meeting',
  PHONE = 'phone',
  CHAT = 'chat'
}
