export const REPRESENTATION_TYPES = [
  'natural_person',
  'existing_company',
  'new_company'
];

export const TYPE_OF_CONVERSION = [
  'asset_liability',
  'tax_free_transfer',
  'transfer_subject_to_tax',
  'none'
];

export const INCORPORATION_SPEED = ['slow', 'medium', 'fast'];

export const TIME_ESTIMATION = ['soon', 'future', 'fast', 'none'];

export interface CalculatorShareholderType {
  representation_type: typeof REPRESENTATION_TYPES[number];
  ubos: number;
  resident: boolean;
}
export interface CalculatorMainDclType {
  converting: boolean;
  type_of_conversion: typeof TYPE_OF_CONVERSION[number];
  shareholder_count: number;
  incorporation_speed: typeof INCORPORATION_SPEED[number];
  time_estimation: typeof TIME_ESTIMATION[number];
  email: string;
  language_requirement: boolean;
  english_omzetting: boolean;
  upsell_package_type_product_ids: number[];
}

export type CalculatorDclType = CalculatorMainDclType &
  CalculatorShareholderType;

export interface CalculatorDataType extends CalculatorMainDclType {
  p1: CalculatorShareholderType;
  p2: CalculatorShareholderType;
  p3: CalculatorShareholderType;
  p4: CalculatorShareholderType;
}
