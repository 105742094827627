import { ref } from '@vue/composition-api';
import { BasicFieldType } from '../models/Field';
import { Option } from '../models/FieldDcl';

export function isPromiseOption(
  response: any
): response is Promise<string[] | Option[]> {
  return typeof response?.then == 'function';
}

export const useAsyncOptions = (field: BasicFieldType, { root }) => {
  const options = ref<any[]>([]);
  const loading = ref(false);

  const translateFieldLabel = (element) => {
    const i18key = `${field.localePrefix}.options.${field.key}.${element}`;
    return root.$i18n.t(i18key);
  };

  function resolve() {
    if (field.type == 'bool-options') {
      return [
        { value: true, label: root.$i18n.t('yes') },
        { value: false, label: root.$i18n.t('no') }
      ];
    }
    if ('options' in field && field.options) {
      if (typeof field.options === 'function') {
        return field.options();
      }
      // One should NOT modify props from within a component in Vue
      // props.field.options = props.field.options();
      const _options = field.options;

      const getLabel = field.ids ? translateFieldLabel : (e) => e;
      return (_options as any[]).map((element) => {
        return {
          value: element.value != undefined ? element.value : element,
          label: getLabel(element.label != undefined ? element.label : element)
        };
      });
    }
  }

  const result = resolve();

  function load() {
    loading.value = true;

    if (isPromiseOption(result)) {
      return result
        .then((response) => (options.value = response))
        .finally(() => (loading.value = false));
    } else {
      return new Promise(() => {
        options.value = resolve() as any[];
        loading.value = false;
      });
    }
  }
  return {
    load,
    options,
    loading
  };
};
