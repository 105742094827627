import { Contract } from '@ligo/dashboard/customer/store';
import { computed, ComputedRef, Ref } from '@vue/composition-api';
import { PreviewDocumentMenuItem } from '../../../models';
import { ShowCloneContract } from '../active-contracts-list';

export function useContractMenu(contract: Ref<Contract>) {
  const menu: ComputedRef<PreviewDocumentMenuItem[]> = computed(() => {
    const makeACopy: PreviewDocumentMenuItem = {
      emit: 'on-make-copy',
      label: 'contract.contract_menu.make_copy.title',
      icon: 'far fa-copy',
      separator: true
    };
    const list = [];
    if (!contract.value) return [makeACopy];
    if (ShowCloneContract(contract.value.status)) {
      list.push(makeACopy);
    }
    if (contract.value.last_revision?.word_document) {
      list.push({
        emit: 'on-download-doc',
        label: 'contract.contract_menu.download_as_doc',
        icon: 'far fa-download',
        separator: false
      });
    }
    if (contract.value.last_revision?.pdf_signed_document) {
      list.push({
        emit: 'on-download-signed-pdf',
        label: 'contract.contract_menu.download_as_signed_pdf',
        icon: 'far fa-download',
        separator: false
      });
    }
    if (contract.value.last_revision?.pdf_document) {
      list.push({
        emit: 'on-download-unsigned-pdf',
        label: 'contract.contract_menu.download_as_unsigned_pdf',
        icon: 'far fa-download',
        separator: true
      });
    }
    if (contract.value.last_revision?.pdf_document) {
      list.push({
        emit: 'on-print',
        label: 'contract.contract_menu.print',
        icon: 'far fa-print',
        separator: true
      });
    }
    list.push({
      emit: 'on-rename',
      label: 'contract.contract_menu.rename',
      icon: 'far fa-i-cursor',
      separator: true
    });
    list.push({
      emit: 'on-delete',
      label: 'contract.contract_menu.delete',
      icon: 'far fa-trash-can',
      separator: true
    });
    return list;
  });

  return { menu };
}
