




























import { defineComponent, PropType } from '@vue/composition-api';
export default defineComponent({
  name: 'I18nSwitch',
  components: {},
  props: {
    i18nSwitch: {
      type: (Object as unknown) as PropType<{
        [id: string]: { active: boolean; language: string };
      }>
    },
    color: {
      type: String,
      default: 'grey-14'
    },
    disable: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: 'row justify-center'
    }
  }
});
