


















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'LabelButton',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    hideTooltip: {
      type: Boolean
    }
  }
});
