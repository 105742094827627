































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { BasicField } from '../../models/Field';
import { Option } from '../../models/FieldDcl';

export default defineComponent({
  name: 'OptionsField',
  props: {
    options: {
      type: (Array as unknown) as
        | PropType<Array<string>>
        | PropType<Array<Option>>,
      required: true
    },
    value: {
      type: null as any,
      required: true
    },
    ids: {
      type: Boolean,
      default: false
    },
    field: {
      type: BasicField
    }
  },
  setup(props, { emit }) {
    const updateValue = (a: any) => {
      emit('input', a.value);
    };
    const style = (option: Option, i: number) => {
      const padding = i == props.options.length - 1 ? '' : 'q-mr-sm';
      if (typeof props.value === 'boolean') {
        if (props.value && option.value)
          return `text-primary selected-option ${padding}`;
        if (!props.value && !option.value)
          return `text-warning selected-option-negative ${padding}`;
      }
      let active = false;
      if (typeof option === 'string') active = option == props.value;
      else active = option.value == props.value;
      return active
        ? `text-primary selected-option ${padding}`
        : `text-grey-1 ${padding}`;
    };

    let borderless = ref(true);

    const required = function (val: any) {
      if (val === null) {
        borderless.value = false;
        return false;
      }
      borderless.value = true;
      return true;
    };

    return {
      updateValue,
      style,
      borderless,
      required
    };
  }
});
