














import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ColoredTitle',
  props: {
    dark: {
      type: String,
      required: true
    },
    light: {
      type: String
    },
    append: {
      type: String
    }
  }
});
