import { AuthError } from './../models/Common';
import { TokenService, AuthToken } from '../services';

export interface PropsInterface {
  authenticating: boolean;
  accessToken: AuthToken | null;
  checkToken: boolean;
  authenticationError: AuthError | null;
  openDialog: boolean;
  authMethod: boolean;
}

const state: PropsInterface = {
  authenticating: false,
  accessToken: TokenService.getToken(),
  checkToken: TokenService.checkToken(),
  authenticationError: null,
  openDialog: false,
  authMethod: false
};

export default state;
