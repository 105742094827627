































































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { ApiService, Dictionary } from '@ligo/shared/utils';
import SocialCard from './SocialCard.vue';
import FormCard from './FormCard.vue';
import { emailRules } from '../hooks/auth';

export default defineComponent({
  name: 'NewResetpassword',
  components: { SocialCard, FormCard },
  props: {
    base: {
      type: String,
      default: ''
    },
    brand: {
      type: String,
      default: ''
    },
    brandIcon: {
      type: String,
      default: ''
    },
    redirect: {
      type: String,
      default: '/change-password'
    },
    backPage: {
      type: String,
      default: 'Login'
    },
    texts: {
      type: Object as PropType<Dictionary>,
      default: () => ({
        userLabel: 'user.labels.email',
        userPlaceholder: 'user.placeholders.email'
      })
    }
  },
  setup(props) {
    const formRef = ref();
    const loading = ref(false);
    const done = ref(false);
    const email = ref('');

    const mailError = ref(false);

    function onSend() {
      formRef.value.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          ApiService.post(`${props.base}/password`, {
            email: email.value,
            redirect_url: props.redirect
          })
            .then(() => {
              loading.value = false;
              done.value = true;
            })
            .catch(() => {
              mailError.value = true;
            })
            .finally(() => {
              loading.value = false;
            });
        }
      });
    }

    return {
      onSend,
      formRef,
      email,
      done,
      loading,
      emailRules,
      mailError
    };
  }
});
