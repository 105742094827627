import axios from 'axios';
import { Dictionary } from '../tools/types';
import { TokenService, AuthToken } from './token.service';

const default_headers = {
  FORM_DATA: { 'Content-Type': 'multipart/form-data' }
};

export const ApiService = {
  _401interceptor: 0,

  init(baseUrl: string): void {
    axios.defaults.baseURL = baseUrl;
    if (TokenService.checkToken()) {
      this.setAuth();
    }
  },

  setAuth(): void {
    const token: AuthToken | null = TokenService.getToken();
    if (token) {
      this.setHeaders(token);
      token.refresh();
    }
  },

  setHeaders(token: AuthToken) {
    axios.defaults.headers.common['client'] = token.client;
    axios.defaults.headers.common['uid'] = token.uid;
    axios.defaults.headers.common['expiry'] = token.expiry;
    axios.defaults.headers.common['access-token'] = token.access_token;
  },

  setCustomHeader(key: string, val: any) {
    axios.defaults.headers.common[key] = val;
  },

  removeHeader(): void {
    axios.defaults.headers.common = {};
  },

  get<R>(resource: string, params?: Dictionary, options?: Dictionary) {
    return axios.get<R>(resource, {
      params,
      ...options
    });
  },

  post<T, R = T>(
    resource: string,
    data?: T,
    formData = false,
    headers?: { [id: string]: string | null }
  ) {
    const request_headers = headers
      ? headers
      : { ...(formData && default_headers.FORM_DATA) };
    return axios.post<R>(resource, data, {
      headers: request_headers
    });
  },

  auth_redirection<T, R = T>(url: string) {
    return axios.post<R>(url, undefined, { withCredentials: true });
  },

  put<T, R = T>(resource: string, data: T, formData = false) {
    return axios.put<R>(resource, data, {
      headers: { ...(formData && default_headers.FORM_DATA) }
    });
  },

  patch<T, R = T>(resource: string, data: T, formData = false) {
    return axios.patch<R>(resource, data, {
      headers: { ...(formData && default_headers.FORM_DATA) }
    });
  },

  delete(resource: string) {
    return axios.delete(resource);
  },

  mount401Interceptor(interceptCallback: (error: any) => void): void {
    this._401interceptor = axios.interceptors.response.use((response) => {
      return response;
    }, interceptCallback);
  },

  unmount401Interceptor() {
    axios.interceptors.response.eject(this._401interceptor);
  },

  useInterceptors(interceptCallback: (error: any) => void): number {
    return axios.interceptors.response.use(
      (response) => response,
      interceptCallback
    );
  }
};
