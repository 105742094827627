























import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'TextWithTootip',
  props: {
    text: String,
    tip: String,
    icon: String,
    iconSize: String
  },
  data: function () {
    return {
      visible: false
    };
  },
  methods: {
    enter() {
      document.body.style.cursor = 'pointer';
      this.visible = true;
    },
    leave() {
      document.body.style.cursor = 'auto';
      this.visible = false;
    }
  }
});
