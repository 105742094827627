var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{class:{
    'q-py-md q-px-md': _vm.$q.screen.gt.sm,
    'bg-transparent': _vm.$q.screen.xs
  },attrs:{"bordered":_vm.$q.screen.gt.xs,"flat":""}},[(_vm.$q.screen.gt.xs)?_c('q-card-section',{staticClass:"font-15 w-600"},[_vm._v(" "+_vm._s(_vm.$t('account.payment.title'))+" ")]):_vm._e(),_c('s-q-table',{staticClass:"payments-table",attrs:{"columns":_vm.columns,"rows":_vm.paymentList,"loading":_vm.loading,"pagination":Object.assign({}, _vm.pagination, {rowsNumber: _vm.total}),"search-engine":_vm.search,"filters-definitions":_vm.filters},scopedSlots:_vm._u([{key:"actions",fn:function(row){return [_c('div',{staticClass:"row items-center",staticStyle:{"width":"120px !important"}},[_c('div',{staticClass:"col"},[(row.status == _vm.PaymentResponse.AUTHORIZED)?_c('span',{staticClass:"font-12"},[_vm._v(" "+_vm._s(_vm.$t('account.payment.actions.done'))+" ")]):(row.status == _vm.PaymentResponse.OPEN)?_c('q-btn',{staticClass:"text-np-primary-1100 font-12 w-700",attrs:{"flat":"","dense":"","label":_vm.$t('account.payment.actions.pay'),"no-caps":""},on:{"click":function($event){return _vm.$store.dispatch('layout/openPaymentModal', {
                paymentUuid: row.uuid
              })}}}):(row.status)?_c('span',{staticClass:"font-12 text-red"},[_vm._v(" "+_vm._s(_vm.$t(("account.payment.actions." + (row.status))))+" "),_c('q-tooltip',{staticClass:"bg-default"},[_c('span',{staticClass:"w-500"},[_vm._v(_vm._s(_vm.$t(("account.payment.actions." + (row.status)))))])])],1):_vm._e()],1),_c('div',{staticClass:"col"},[(row.status == _vm.PaymentResponse.AUTHORIZED && row.pdfInvoice)?_c('div',[_c('q-btn',{attrs:{"dense":"","color":"np-primary-1100","label":_vm.$t('account.payment.actions.view'),"flat":"","no-caps":""},on:{"click":function($event){return _vm.onDownload(row)}}})],1):_vm._e()])])]}},{key:"mobile-row-data",fn:function(row){return [_c('div',{staticClass:"font-16 w-500 text-default ellipsis"},[_vm._v(" "+_vm._s(row.description)+" ")]),_c('div',{staticClass:"font-14 w-400 text-default q-mt-sm"},[(row.paid_at)?_c('div',[_vm._v(" "+_vm._s(_vm.moment(row.paid_at).format('DD/MM/YYYY'))+" ")]):_c('div',[_vm._v("-")]),_c('div',{staticClass:"row q-mt-sm w-500"},[_vm._v("€"+_vm._s(row.total))]),_c('div',{staticClass:"row q-mt-sm"},[_c('div',{class:("bg-" + (row.statusBackgroundColor) + "  border-radius-10 text-" + (row.statusTextColor) + " q-px-sm")},[_vm._v(" "+_vm._s(row.status)+" ")])])]),(row.status == _vm.PaymentResponse.OPEN)?_c('a-primary-btn',{staticClass:"full-width q-mt-md",attrs:{"label":_vm.$t('account.payment.actions.pay')},on:{"click":function($event){return _vm.$store.dispatch('layout/openPaymentModal', {
            paymentUuid: row.uuid
          })}}}):_vm._e(),(row.status == _vm.PaymentResponse.AUTHORIZED && row.pdfInvoice)?_c('a-secondary-btn',{staticClass:"full-width q-mt-md",attrs:{"bordered":"","label":_vm.$t('account.payment.actions.view')},on:{"click":function($event){return _vm.onDownload(row)}}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }