import { ExtraService, ExtraServiceType } from '../models/extra-service';
import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '../resources';
import Vue from 'vue';
import { SQTablePagination } from '../../../../components/src/lib/components/base/tables/s-q-table.hook';
import { Route } from 'vue-router';
import VueRouter from 'vue-router';

const STORAGE_KEY = 'extra-service-type-slug';

export const ExtraServiceService = {
  async create(extraServiceTypeSlug: string, redirectionRoute: Partial<Route>) {
    ApiService.post<any, ExtraService>(RESOURCES.EXTRA_SERVICES, {
      extra_service_type_slug: extraServiceTypeSlug
    }).then((response) => {
      if (response.data.payment) {
        const router = Vue['Router'] as VueRouter;
        router.push({
          ...redirectionRoute,
          query: { paymentUuid: response.data.payment.uuid }
        });
      }
    });
  },
  async list(categories = [], pagination?: SQTablePagination) {
    return ApiService.get<Array<ExtraService>>(RESOURCES.EXTRA_SERVICES, {
      categories,
      page: pagination?.page,
      per_page: pagination?.rowsPerPage
    });
  },
  saveSelection(extraServiceTypeSlug: string) {
    localStorage.setItem(STORAGE_KEY, extraServiceTypeSlug);
  },
  async checkAndCreateFromStorage() {
    const extraServiceTypeSlug = localStorage.getItem(STORAGE_KEY);
    if (extraServiceTypeSlug) {
      await this.create(extraServiceTypeSlug);
      this.clearSelection();
    }
  },
  clearSelection() {
    localStorage.removeItem(STORAGE_KEY);
  }
};
