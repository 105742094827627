import { LocalResource, NotaryProductResource } from '@ligo/bv-flow/store';
import { ShareholderType } from '../../../share';
import {
  commonShareholderFieldDescriptions,
  companyShareholderFields,
  uboShareholderFields
} from '../../shared/commonShareholderFields';

export const ShareholderResourceDefinition: LocalResource<ShareholderType> = {
  url: '#',
  locale: 'shareholder',
  fieldsDescriptions: {
    ...commonShareholderFieldDescriptions,
    ...companyShareholderFields,
    ...uboShareholderFields,
    become_director: { type: 'bool-options' },
    company_name: {}
  },
  formatJSON: (data: { [id: string]: any }) => {
    if (
      data['is_existing_company'] &&
      data['address_equals_company_address'] === true
    ) {
      data['address'] = data['company_address'];
      data['location'] = data['company_location'];
      data['zip_code'] = data['company_zipcode'];
      data['residence_country'] = data['company_country'];
    }
    if (
      data['is_new_company'] &&
      data['address_equals_company_address'] === true
    ) {
      data['address'] = data['company_address'];
      data['location'] = data['company_location'];
      data['zip_code'] = data['company_zipcode'];
    }

    return data;
  }
};

export const shareholderResources = () => {
  return {
    p1: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p1',
      'omzetting'
    ),
    p2: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p2',
      'omzetting'
    ),
    p3: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p3',
      'omzetting'
    ),
    p4: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p4',
      'omzetting'
    )
  };
};
