































import { defineComponent } from '@vue/composition-api';
import { APrimaryBtn, ASecondaryBtn } from '../components/base/app/buttons';

export default defineComponent({
  name: 'InactivityModal',
  components: {
    APrimaryBtn,
    ASecondaryBtn
  },
  props: {
    image: {
      type: String,
      default: '/dashboard/images/inactivity_modal_image.svg'
    },
    modalAppearanceTime: {
      type: Number,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    }
  }
});
