<template>
  <div :class="`row q-mx-md ${$q.screen.lt.md && 'justify-center '}`">
    <p
      :class="`col-11 col-md-9 q-mt-lg w-600 font-${
        $q.screen.gt.md ? '46' : '30'
      } `"
    >
      {{ $t('errors.maintenance.title') }}
    </p>
    <q-img
      class="col-7 col-md-7 col-lg-6"
      :style="`${
        $q.screen.gt.sm && 'position: absolute; top: 25px; right: 25px'
      }`"
      src="../svg/maintenance.svg"
    />
    <p
      :class="`font-${$q.screen.gt.md ? '30' : '20'} col-11 col-md-8 q-mt-lg `"
    >
      {{ $t('errors.maintenance.p1') }}
    </p>
    <p :class="`font-${$q.screen.gt.md ? '30' : '20'} col-11 col-md-5`">
      {{ $t('errors.maintenance.p2') }}
      <span class="text-primary"> support@ligo.nl.</span>
    </p>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'MaintenanceInfo'
});
</script>
