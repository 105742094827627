












































































































import { defineComponent, ref, Ref } from '@vue/composition-api';
import {
  PaymentService,
  PaymentResponse,
  LitePayment
} from '@ligo/dashboard/customer/store';
import { useI18n } from '../../composables';
import {
  SQTableRow,
  SQTableColumn,
  SQTablePagination,
  SQTableFilter
} from '../../components/base/tables/s-q-table.hook';
import { SQTable } from '../base';
import { useAsyncResult } from '@ligo/shared/utils';
import { APrimaryBtn, ASecondaryBtn } from '../../components/base/app/buttons';
import moment from 'moment';

export default defineComponent({
  name: 'Payments',
  components: { SQTable, APrimaryBtn, ASecondaryBtn },
  setup() {
    const i18n = useI18n();

    const columns: SQTableColumn[] = [
      {
        name: 'paid_at',
        label: i18n.t('account.payment.date').toString(),
        align: 'left',
        field: (value) => {
          if (value.paid_at) return moment(value.paid_at).format('DD/MM/YYYY');
          return '-';
        }
      },
      {
        name: 'uuid',
        label: i18n.t('account.payment.name').toString(),
        align: 'left',
        field: 'description'
      },
      {
        name: 'total',
        label: i18n.t('account.payment.total').toString(),
        align: 'left',
        field: (value) => {
          return `€ ${value.total.toFixed(2)}`;
        }
      }
    ];

    const filters: SQTableFilter[] = [
      {
        type: 'multi-select',
        name: 'aasm_state',
        label: `${i18n.t('account.payment.filters.aasm_state').toString()}`,
        model: [],
        options: [
          {
            value: PaymentResponse.AUTHORIZED,
            label: i18n
              .t(`account.payment.actions.${PaymentResponse.AUTHORIZED}`)
              .toString()
          },
          {
            value: PaymentResponse.OPEN,
            label: i18n
              .t(`account.payment.actions.${PaymentResponse.OPEN}`)
              .toString()
          },
          {
            value: PaymentResponse.CANCELLED,
            label: i18n
              .t(`account.payment.actions.${PaymentResponse.CANCELLED}`)
              .toString()
          }
        ]
      },
      {
        type: 'date-range',
        name: 'paid_at',
        label: i18n.t('account.payment.filters.paid_at').toString(),
        model: {
          from: '',
          to: ''
        }
      }
    ];

    const pagination: Ref<SQTablePagination> = ref({
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 50
    });

    const query = ref({
      aasm_state: undefined,
      'paid_at_filter[gte]': undefined,
      'paid_at_filter[lte]': undefined
    });

    const { state: paymentList, loading, total, load } = useAsyncResult(
      () =>
        PaymentService.list({
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          ...query.value
        }),
      [pagination, query],
      (item: LitePayment): SQTableRow => ({
        uuid: item.uuid,
        paid_at: item.paid_at,
        description: item.merchantReference,
        total: item.payment_amount,
        status: item.status,
        statusBackgroundColor:
          item.status === PaymentResponse.AUTHORIZED
            ? 'green-light'
            : item.status === PaymentResponse.OPEN
            ? 'yellow-light'
            : 'red-light',
        statusTextColor:
          item.status === PaymentResponse.AUTHORIZED
            ? 'green'
            : item.status === PaymentResponse.OPEN
            ? 'yellow'
            : 'red',
        pdfInvoice: item.finalized_payment?.pdf_invoice
      })
    );

    function search(filter, page, rowsPerPage, sortBy, descending) {
      pagination.value.sortBy = sortBy;
      pagination.value.descending = descending;
      pagination.value.page = page;
      pagination.value.rowsPerPage = rowsPerPage;

      const from =
        filter['paid_at']?.model &&
        new Date(filter['paid_at']?.model?.from || filter['paid_at']?.model);
      const to = new Date(filter['paid_at']?.model?.to);

      const isValidFrom = from && !isNaN(from.getTime());
      const isValidTo = to && !isNaN(to.getTime());

      isValidTo && to.setDate(to.getDate() + 1);

      query.value['paid_at_filter[gte]'] =
        (isValidFrom && moment(from).format('YYYY-MM-DD')) || undefined;
      query.value['paid_at_filter[lte]'] =
        (isValidTo && moment(to).format('YYYY-MM-DD')) || undefined;

      query.value.aasm_state =
        filter.aasm_state.model.map((state) => state.value) || undefined;

      load();
    }

    function onDownload(payment: any) {
      window.open(payment.pdfInvoice, '_blank');
    }

    return {
      PaymentResponse,
      paymentList,
      columns,
      loading,
      pagination,
      total,
      filters,
      moment,
      onDownload,
      search
    };
  }
});
