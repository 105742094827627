import {
  ComputedRef,
  computed,
  watch,
  ref,
  onMounted
} from '@vue/composition-api';
import { useMovementDetector } from '@ligo/shared/utils';

export interface AssistantState {
  title: string;
  info: string;
  transition: ComputedRef;
  targetHtmlSelector: string;
  alignment?: AssistantAlignment;
  offset?: AssistantOffset;
  [key: string]: any;
}

export interface AssistantAlignment {
  right?: 0 | 1;
  bottom?: 0 | 1;
}

export interface AssistantOffset {
  x?: number;
  y?: number;
}

export function useLigoAssistant(
  states: AssistantState[],
  emit: (event: string, ...args: any[]) => void,
  nextTick: (callback: () => void) => void,
  initialState = 0,
  externalTransition = false
) {
  const index = ref(initialState);
  const state = computed(() => states[index.value]);
  const title = computed(() => state.value.title);
  const info = computed(() => state.value.info);
  const end = ref(false);
  const loadingTransition = ref(true);
  const x = ref();
  const y = ref();
  const movementDetector = ref();

  function pointToHtmlElement() {
    const htmlElement = document.querySelector(state.value.targetHtmlSelector);

    if (htmlElement) {
      document.getElementById('ligo-assistant-circle')?.remove();
      const X = htmlElement.getBoundingClientRect().left;
      const Y = htmlElement.getBoundingClientRect().top;
      const width = htmlElement.getBoundingClientRect().width;
      const height = htmlElement.getBoundingClientRect().height;

      x.value =
        X +
        width * (state.value.alignment?.right || 0) +
        (state.value.offset?.x || 0);
      y.value =
        Y +
        height * (state.value.alignment?.bottom || 0) +
        (state.value.offset?.y || 0);

      nextTick(() => {
        loadingTransition.value = false;
      });
    }
  }

  function initWatcher() {
    loadingTransition.value = true;
    nextTick(() => {
      movementDetector.value?.cleanUp();
      movementDetector.value = useMovementDetector(
        state.value.targetHtmlSelector,
        pointToHtmlElement
      );
    });
    const unwatch = watch(state.value.transition, (next: string | number) => {
      if (typeof next == 'number' && next == -1) {
        unwatch();
        movementDetector.value?.cleanUp();
        end.value = true;
        emit('end', state.value);
        return;
      } else if (!externalTransition && typeof next == 'number' && next >= 0) {
        index.value = next;
        unwatch();
        initWatcher();
      } else emit('transition', { next, drawCallback: pointToHtmlElement });
    });
  }

  function initIndex() {
    if (index.value != state.value.transition.value) {
      index.value = state.value.transition.value;
      initIndex();
    }
  }

  onMounted(() => {
    initIndex();
    initWatcher();
  });

  return {
    title,
    info,
    loadingTransition,
    end,
    x,
    y,
    state
  };
}
