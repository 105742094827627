import { TokenInterface } from '../token.service';
import { useEnvironment } from 'netlify_functions';

function intercomEvent(event, params) {
  const w: any = window;
  if (w.Intercom) {
    w.Intercom(event, params);
  }
}

export const Intercom = {
  extractDataFromToken: function (token: any) {
    return {
      app_id: useEnvironment().INTERCOM_KEY,
      user_id: token.userUuid,
      user_hash: token.userHash,
      email: token.email,
      first_name: token.firstname,
      last_name: token.lastname
    };
  },
  identifyUser: function (token: TokenInterface | null) {
    if (token) {
      console.log('IDENTIFYING', token);
      intercomEvent('boot', this.extractDataFromToken(token));
    } else {
      intercomEvent('boot', { app_id: useEnvironment().INTERCOM_KEY });
    }
  },
  update: function () {
    intercomEvent('update', undefined);
  },
  shutdown: function () {
    intercomEvent('shutdown', undefined);
  },
  wakeUp: function () {
    intercomEvent('show', undefined);
  }
};
