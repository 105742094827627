












import {
  defineComponent,
  ref,
  Ref,
  PropType,
  onMounted
} from '@vue/composition-api';
import { ApiService } from '@ligo/shared/utils';
import { AnimationOptions, AnimationObject } from '../../../models';
import Lottie from './Lottie.vue';

export default defineComponent({
  name: 'LottieAnimation',
  components: { Lottie },
  props: {
    loop: [Boolean, Number],
    width: {
      type: String
    },
    height: {
      type: String
    },
    animationData: {
      type: [String, Object]
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    name: String,
    render: String as PropType<'svg' | 'canvas' | 'html'>,
    container: String,
    speed: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const loading = ref(true);
    const animationObject: Ref<AnimationObject> = ref(null);
    const defaultOptions: Ref<AnimationOptions> = ref(null);
    const lottieContainer = ref(null);
    const excludeKeys = ['width', 'height', 'speed'];

    function fillOptions() {
      defaultOptions.value = Object.keys(props)
        .filter((key) => !excludeKeys.includes(key) && props[key] != undefined)
        .reduce((obj, key) => {
          obj[key] = props[key];
          return obj;
        }, {}) as AnimationOptions;
    }

    async function getJsonFromUrl() {
      ApiService.get(props.animationData)
        .then((response) => {
          defaultOptions.value.animationData = response.data;
        })
        .catch((e) => {
          console.log('There was an error trying to fetch the json file : ', e);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    function onCreated(anim: AnimationObject) {
      animationObject.value = anim;
      animationObject.value.setSpeed(props.speed);
    }

    onMounted(() => {
      fillOptions();
      if (typeof props.animationData === 'string') {
        getJsonFromUrl();
      } else loading.value = false;
    });

    return { defaultOptions, lottieContainer, loading, onCreated };
  }
});
