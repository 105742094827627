

























































































import { computed, defineComponent } from '@vue/composition-api';
import { useTaxesServices, statusBadges } from './taxes-services.hook';
import ServicesTypesList from './components/ServicesTypesList.vue';
import ServicesTypesCard from './components/ServicesTypesCard.vue';
import ServicesTable from './components/ServicesTable.vue';
import { APrimaryBtn } from '../base/app/buttons';
import { TaxServicesPageInformation } from '../utils/useCustomerPageInformation.hooks';

const CUSTOM_TYPES_KEY = 'custom_types';

interface CustomTypesCard {
  title: string;
  subtitle: string;
  cta_label: string;
  cta_link: string;
  slug: string;
}

export default defineComponent({
  name: 'TaxServicesHandler',
  components: {
    ServicesTypesList,
    ServicesTable,
    ServicesTypesCard,
    APrimaryBtn
  },
  setup() {
    const { t, get, load } = TaxServicesPageInformation;
    load();

    const {
      services,
      loadingServices,
      types,
      loadingTypes,
      tablePagination,
      search
    } = useTaxesServices();

    const customTypes = computed(() =>
      get<CustomTypesCard[]>(CUSTOM_TYPES_KEY, [])
    );

    function openExternal(link: string) {
      window.open(link);
    }

    return {
      services,
      loadingServices,
      types,
      loadingTypes,
      tablePagination,
      statusBadges,
      customTypes,
      t,
      search,
      openExternal
    };
  }
});
