import { Ref, ref } from '@vue/composition-api';
import AdyenCheckout from '@adyen/adyen-web';
import { ApiService } from '@ligo/shared/utils';
import { useEnvironment } from '../../../../../../netlify_functions';
import { PaymentAdyenSessions } from '@ligo/shared/models';
import { sendGTMPaymentEvent } from '@ligo/dashboard/customer/store';

const SECOND = 1000;

interface AdyenConfig {
  paymentUuid: string;
  data: any;
  container: Ref<any>;
  timeBeforeRedirect?: number;
  emit?: any;
}

enum AdyenResponseCode {
  ERROR = 'Error',
  AUTHORISED = 'Authorised',
  REFUSED = 'Refused',
  CANCELLED = 'Cancelled',
  PENDING = 'Received'
}

export function getConfig(config: AdyenConfig, successUrl) {
  return {
    environment: useEnvironment().ADYEN.environment,
    clientKey: useEnvironment().ADYEN.clientKey,
    session: config.data,
    successUrl,
    onPaymentCompleted: async (paymentResponse) => {
      if (paymentResponse.resultCode == AdyenResponseCode.AUTHORISED) {
        config.container.value.setStatus('success');
        await sendGTMPaymentEvent(config.paymentUuid);
        setTimeout(() => {
          config.emit('payment-success', successUrl);
        }, config.timeBeforeRedirect || 3 * SECOND);
      } else config.emit('payment-error', paymentResponse.resultCode);
    },
    onError: (error, component) => {
      config.emit('payment-error', error);
      console.error(
        'Error in payment',
        error.name,
        error.message,
        error.stack,
        component
      );
    },
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true
      }
    }
  };
}

export async function initPaymentSession(
  paymentUuid: string,
  requestUrl: string,
  dropinContainer: Ref<HTMLElement>,
  sessionId?: string,
  redirectResult?: string,
  emit?: any
) {
  let session;
  const { data } = await ApiService.post<PaymentAdyenSessions>(requestUrl);
  const success_url = data.success_url;
  if (sessionId) {
    session = {
      id: sessionId
    };
  } else {
    session = data.session;
  }

  const checkout = ref();
  const container = ref();

  const config = getConfig(
    { paymentUuid, data: session, container, emit },
    success_url
  );

  checkout.value = await AdyenCheckout(config);

  container.value = checkout.value
    .create('dropin')
    .mount(dropinContainer.value);

  if (redirectResult) {
    checkout.value.submitDetails({ details: { redirectResult } });
  }
}
