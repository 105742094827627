import { ApiService, Dictionary } from '@ligo/shared/utils';
import {
  City,
  Notary,
  NotaryProduct,
  LiteNotaryProduct,
  NotaryProductDocument,
  NotaryProductEvent
} from '@ligo/dashboard/store';
import { RESOURCES } from '../resources';
import { AxiosPromise } from 'axios';

export const NotaryProductService = {
  list(query: Dictionary = {}): AxiosPromise<LiteNotaryProduct[]> {
    return ApiService.get<LiteNotaryProduct[]>(RESOURCES.NOTARY_PRODUCT, {
      ...query
    });
  },

  getById(id: string) {
    return ApiService.get<NotaryProduct>(`${RESOURCES.NOTARY_PRODUCT + id}/`);
  },

  delete(id: string) {
    return ApiService.delete(`${RESOURCES.NOTARY_PRODUCT + id}/`);
  },

  changeStage: function (
    uuid: string,
    event: NotaryProductEvent
  ): AxiosPromise<NotaryProduct> {
    return ApiService.patch(`${RESOURCES.NOTARY_PRODUCT}/${uuid}`, { event });
  },

  documents: function (
    uuid: string,
    queryParams?: Dictionary
  ): AxiosPromise<NotaryProductDocument[]> {
    return ApiService.get<NotaryProductDocument[]>(
      RESOURCES.NOTARY_PRODUCT_DOCUMENT(uuid),
      queryParams
    );
  },

  getNotariesList: function (uuid: string) {
    return ApiService.get<Notary[]>(RESOURCES.NOTARY_CUSTOMER(uuid));
  },

  getCitiesList: function () {
    return ApiService.get<City[]>(RESOURCES.CITIES_LIST);
  },

  updateNotaryProduct: function (
    uuid: string,
    data: Dictionary
  ): AxiosPromise<NotaryProduct> {
    return ApiService.patch(RESOURCES.UPDATE_NOTARY_PRODUCT(uuid), data);
  },

  getDescriptionFromChatGPT: function (data: Dictionary) {
    return ApiService.post<Dictionary>(RESOURCES.AI_GENERATOR_ACTIVITIES, data);
  },

  requestChange: function (uuid: string, data: Dictionary) {
    return ApiService.post<Dictionary>(RESOURCES.REQUEST_CHANGES(uuid), data);
  }
};
