








































import { ApiService, notifyAsync } from '@ligo/shared/utils';
import { defineComponent, Ref, ref, watch } from '@vue/composition-api';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import AlertModal from '../../base/AlertModal.vue';
import { APrimaryBtn, ASecondaryBtn } from '../../../base/app/buttons';

export const MAX_SIZE = 15728640;
export const FORMATS = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf'
];
export default defineComponent({
  name: 'UploadVersionDialog',
  components: { AlertModal, APrimaryBtn, ASecondaryBtn },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    contractId: {
      type: String
    }
  },
  setup(props, { root, emit }) {
    const file: Ref<any> = ref();
    const form: Ref<any> = ref();
    const loading = ref(false);
    const fileRules = [
      (value: any) => !!value || root.$t('contract.upload.required_file')
    ];
    const onUpload = async () => {
      form.value.validate().then((valid) => {
        if (valid) {
          loading.value = true;
          const formData = new FormData();
          formData.append('file', file.value);
          notifyAsync(
            ApiService.patch(
              RESOURCES.UPLOAD_CONTRACT(props.contractId),
              formData
            )
              .then(() => {
                file.value = null;
                loading.value = false;
                emit('input', false);
                emit('refresh');
              })
              .finally(() => {
                loading.value = false;
              }),
            root.$t('contract.upload.success'),
            root.$t('contract.upload.error')
          );
        }
      });
    };
    watch(file, (value: Blob) => {
      if (value) {
        const extensionIncluded = FORMATS.includes(value.type);
        const bigFile = value.size > MAX_SIZE;
        if (!extensionIncluded || bigFile) {
          onRejected();
        }
      }
    });
    function onRejected() {
      file.value = undefined;
      root['$q'].notify({
        type: 'negative',
        position: 'top',
        message: root['$t']('contract.upload.format').toString()
      });
    }

    function onCancel() {
      file.value = null;
      emit('input', false);
    }

    return {
      file,
      form,
      loading,
      fileRules,
      onUpload,
      onCancel
    };
  }
});
