import { NotaryProductSlug } from '@ligo/dashboard/store';
import { Dictionary } from '@ligo/shared/utils';
import { Notify } from 'quasar';

const companyUpdateItems = {
  company_name: 'far fa-a',
  company_address: 'far fa-location-dot',
  update_shareholder: 'far fa-address-book',
  remove_shareholders: 'far fa-user-plus'
};

const businessUpdateItems = {
  share_capital: 'far fa-chart-pie-simple',
  other: 'far fa-i-cursor'
};

const defaultItems = {
  ...companyUpdateItems,
  ...businessUpdateItems
};

const allItems = {
  ...companyUpdateItems,
  business_activities: 'far fa-chart-tree-map',
  ...businessUpdateItems
};

export const customMenuItems: {
  [P in NotaryProductSlug]?: Dictionary<string>;
} = {
  'persoonlijke-holding-oprichten': defaultItems,
  'incorporate-holding': defaultItems
};

export const menuItemsHandler = (productType: NotaryProductSlug) => {
  return customMenuItems[productType] || allItems;
};

const ICON_LOADING = 'img:/dashboard/icons/loading.png';
const ICON_CHECK = 'img:/dashboard/icons/circle-check.svg';

export const notifySuccess = (message: string, loading = false) => {
  Notify.create({
    message: message,
    color: 'dark',
    position: 'top',
    icon: loading ? ICON_LOADING : ICON_CHECK
  });
};
