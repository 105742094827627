
























































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { ApiService } from '@ligo/shared/utils';
import { buildRule } from '@ligo/shared/mvc';
import { ResetTexts } from '../../models';

export default defineComponent({
  name: 'ChangePassword',
  props: {
    base: {
      type: String,
      default: ''
    },
    redirect: {
      type: String,
      default: '/change-password'
    },
    texts: {
      type: Object as PropType<ResetTexts>,
      default: () => ({
        userLabel: 'user.labels.email',
        userPlaceholder: 'auth.reset.email'
      })
    },

    titleClass: {
      type: String,
      default: 'font-28 w-600'
    },

    buttonClass: {
      type: String,
      default: 'font-16'
    },

    errorClass: {
      type: String,
      default: 'font-14'
    },

    formClass: {
      type: String,
      default: 'col-10 col-sm-6 col-md-4 col-lg-4 col-xl-2'
    },

    checkImg: {
      type: String,
      default: '/images/check_email.svg'
    },

    backPage: {
      type: String,
      default: 'Login'
    }
  },
  setup(props) {
    const formRef = ref();
    const loading = ref(false);
    const done = ref(false);
    const email = ref('');

    const emailRules = [
      buildRule('required', 'validations.required_generic'),
      buildRule('email', 'validations.email_format')
    ];

    const mailError = ref(false);

    function onSend() {
      formRef.value.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          ApiService.post(`${props.base}/password`, {
            email: email.value,
            redirect_url: props.redirect
          })
            .then(() => {
              loading.value = false;
              done.value = true;
            })
            .catch(() => {
              mailError.value = true;
            })
            .finally(() => {
              loading.value = false;
            });
        }
      });
    }

    return {
      onSend,
      formRef,
      email,
      done,
      loading,
      emailRules,
      mailError
    };
  }
});
