import {
  BasicFieldType,
  BasicForm,
  FieldSet,
  TypeData
} from '@ligo/shared/mvc';
import { nully } from '@ligo/shared/utils';
import { LocalResource } from './LocalResource';

export class NotaryProductResource<T> {
  id: string;
  uuid = '';
  active = false;
  substep = 0;
  locale: string;
  values: TypeData<T>;
  fields: FieldSet<T> = {};
  formatJSON?: (data: { [id: string]: any }) => { [id: string]: any };

  constructor(
    resource_dcl: LocalResource<T>,
    id: string,
    locale: string,
    active = false
  ) {
    this.id = id;
    this.locale = resource_dcl.locale
      ? `${locale}.${resource_dcl.locale}`
      : `${locale}.${id}`;
    this.active = active;
    this.fields = resource_dcl.fieldsDescriptions;
    this.formatJSON = resource_dcl.formatJSON;
    this.values = {};
    const keys = this.getFieldKeys() as Array<keyof FieldSet<T>>;
    keys.forEach((key) => {
      const field = this.fields[key];
      if (!nully(field.defaultValue)) {
        this.values[key] = field.defaultValue as T[keyof T];
      }
    });
  }

  getFieldKeys() {
    return Object.keys(this.fields);
  }

  fieldData(): { [id: string]: any } {
    const fields: { [id: string]: any } = {};
    const keys = this.getFieldKeys() as Array<keyof FieldSet<T>>;
    keys.forEach((key) => {
      const value = this.values[key];
      if (!nully(value)) {
        fields[key.toString()] = value;
      }
    });
    fields['substep'] = this.substep;
    return this.formatJSON ? this.formatJSON(fields) : fields;
  }

  fieldsToString(): string {
    const fields: { [id: string]: any } = this.fieldData();
    return JSON.stringify(fields);
  }

  updateFromForm(form: BasicForm) {
    this.updateFromJSON(form.toDict());
  }

  updateFromJSON(data: any) {
    if (data) {
      const keys = this.getFieldKeys() as Array<keyof FieldSet<T>>;
      keys.forEach((key) => {
        if (!nully(data[key])) {
          const field = this.fields[key] as BasicFieldType;
          const value =
            field.type == 'number' ? data[key].toString() : data[key];
          this.values[key] = value;
        }
      });
      if (data['substep']) this.substep = data['substep'] || 0;
    }
  }
}
