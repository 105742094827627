import { NotaryProduct, Step } from '@ligo/bv-flow/store';
import { ApiService } from '@ligo/shared/utils';
import { IncorporateHoldingResource } from './definitions/incorporate_holding';
import { useMainResource } from './resources/main';
import { shareholderResources } from './resources/shareholders';
import { paymentResource } from '../../share';
import { getSteps } from '../../tools';
import { acceptShareholder, NotaryProductSlug } from '../../product_structure';

const steps: Array<Step> = [
  {
    name: 'holding.company_name.name',
    completed: false
  },
  {
    name: 'holding.shareholder_information.name',
    parts: 2,
    currentPart: 0,
    completed: false
  },
  {
    name: 'holding.holding_upsell.name',
    parts: 3,
    currentPart: 0,
    completed: false
  },
  {
    name: 'holding.preview_documents.name',
    completed: false
  }
];

interface HoldingAPIResponse {
  product_data: IncorporateHoldingResource;
  upsell_package: any;
  calculation: { uuid: string };
}

const url = 'incorporate_holding/';
const path = 'incorporate-holding';
const locale = 'incorporate_holding';

const typeId = 14;
const slug = NotaryProductSlug.INCORPORATE_HOLDING;
const finalStep = 6;

const saveToAPI = async (product: NotaryProduct, sendStep = true) => {
  const data = product.resources['main'].fieldData();
  if (acceptShareholder(product.resources['p1']))
    data['p1'] = product.resources['p1'].fieldData();
  const nextStep = sendStep ? product.getStepIncrement() : 0;
  data['step'] = product.step + nextStep;
  data['temporal_step'] = product.temporalStep + nextStep;
  data['substep'] = product.resources['main'].substep;
  const request = product.url + product.uuid;
  console.log('PayLoad', data);
  try {
    await ApiService.patch(request, data);
  } catch (error) {
    console.log('PATCH ERROR', error);
  }
};

const loadFromAPI = async (product: NotaryProduct) => {
  if (product.uuid) {
    const response = await ApiService.get<HoldingAPIResponse>(
      product.url + product.uuid
    );
    product.upsellPackageData = response.data.upsell_package;
    product.calculationUuid = response.data.calculation?.uuid;
    const { step, temporalStep, substep } = getSteps(
      product.uuid,
      response.data.product_data
    );
    product.step = step;
    product.temporalStep = temporalStep;
    product.substep = substep;
    product.updateFromJSON(response.data);
  }
};

export const useIncorporateHolding = (uuid?: string) => {
  const resources = {
    ...useMainResource(),
    ...shareholderResources(),
    ...paymentResource(locale)
  };
  return new NotaryProduct(
    resources,
    locale,
    url,
    path,
    saveToAPI,
    loadFromAPI,
    'incorporate-holding-handler',
    steps,
    typeId,
    slug,
    finalStep,
    uuid,
    false,
    undefined,
    'en'
  );
};
