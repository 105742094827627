import { LocalResource, NotaryProductResource } from '@ligo/bv-flow/store';
import { ShareholderType } from '../../../share';
import { commonShareholderFieldDescriptions } from '../../shared/commonShareholderFields';

export const ShareholderResourceDefinition: LocalResource<ShareholderType> = {
  url: '#',
  locale: 'shareholder',
  fieldsDescriptions: {
    ...commonShareholderFieldDescriptions
  }
};

export const shareholderResources = () => {
  return {
    p1: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p1',
      'incorporate_holding',
      true
    )
  };
};
