
































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import DocumentBanner from '../base/DocumentBanner.vue';
import { PreviewDocumentMenuItem } from '../../../models';
import ContractMenu from './ContractMenu.vue';
import { Contract } from '@ligo/dashboard/customer/store';
import { UploadVersionModal } from './modals';
import { useContractPropsForHeaders } from './contract-banner.hook';
import CustomerLangSelect from '../../../layouts/CustomerLangSelect.vue';
import { ASecondaryBtn } from '../../base/app/buttons';

export default defineComponent({
  name: 'ContractBanner',
  components: {
    DocumentBanner,
    ContractMenu,
    UploadVersionModal,
    CustomerLangSelect,
    ASecondaryBtn
  },
  props: {
    title: String,
    subtitle: String,
    loading: Boolean,
    menuItems: Array as PropType<PreviewDocumentMenuItem[]>,
    contract: {
      type: Object as PropType<Contract>
    }
  },
  setup(props) {
    const {
      onEdit,
      showEdit,
      showUpload,
      uploadTooltip,
      editTooltip
    } = useContractPropsForHeaders(props.contract);

    const previewEditDisabled = computed(() => {
      return (
        !showEdit ||
        !props.contract.last_revision.word_document ||
        props.loading
      );
    });

    const previewUploadDisabled = computed(() => {
      return !showEdit || props.loading;
    });

    return {
      showEdit,
      onEdit,
      showUpload,
      uploadTooltip,
      editTooltip,
      previewEditDisabled,
      previewUploadDisabled
    };
  }
});
