import Vue from 'vue';
import moment from 'moment';

const date_pattern = /(\d{2})\-(\d{2})\-(\d{4})/;

const fieldRules: { [id: string]: (v: any) => boolean } = {
  required: (v: any) => !!v,
  email: (v: any) => !v || /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(v),
  password: (v: any) => v && v.length >= 8,
  positive: (v: any) => v && v > 0,
  non_negative: (v: any) => v && v >= 0,
  only_numbers: (v: any) => v && /[0-9]+/.test(v),
  zip_code: (v: any) => v && /[0-9][0-9][0-9][0-9]\s{1}[A-Z]{2}$/.test(v),
  before_today: (v: any) => {
    const now = new Date();
    const date = new Date(v.replace(date_pattern, '$3-$2-$1'));
    return v && date <= now;
  },
  after_today: (v: any) => {
    const now = new Date();
    const date = new Date(v.replace(date_pattern, '$3-$2-$1'));
    return v && date > now;
  },
  legal_age: (v: any) => {
    const now = new Date();
    const yearsAgo = new Date(
      now.getFullYear() - 18,
      now.getMonth(),
      now.getDate()
    );
    const date = new Date(v.replace(date_pattern, '$3-$2-$1'));
    return v && date < yearsAgo;
  },
  valid_date: (v: any) => {
    return v && moment(v, 'DD-MM-YYYY').isValid();
  },
  no_fundation: (v: any) => {
    return v && !/(S|s)tichting/.test(v);
  },
  bv_suffix: (v: any) => {
    return v && !/(BV|B.V.)$/g.test(v);
  }
};

export function hasBaseRule(rule: string) {
  return !!fieldRules[rule];
}

export function buildRule(rule: string, error: string) {
  return (v: any) => fieldRules[rule](v) || Vue['i18n'].t(error);
}
