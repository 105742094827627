











import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'TransitionExpand',
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    objectToWatch: null as any,
    shadow: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      container: null,
      content: null,
      id: null
    };
  },
  watch: {
    expanded: function (newValue: boolean) {
      if (newValue) this.expand();
      else this.contract();
    },
    objectToWatch() {
      if (this.expanded)
        this.$nextTick(() => {
          const containerHeight = this.container.getBoundingClientRect().height;
          const contentHeight = this.content.getBoundingClientRect().height;
          if (containerHeight > contentHeight) this.contract(contentHeight);
          else if (containerHeight < contentHeight) this.expand();
        });
    }
  },
  mounted() {
    this.id = this._uid;
    this.$nextTick(() => {
      this.container = document.querySelector(`#container-${this.id}`);
      this.content = document.querySelector(`#content-${this.id}`);
      this.container.style.height = 0;
    });
  },
  methods: {
    expand() {
      this.container.style.height = `${
        this.content.getBoundingClientRect().height
      }px`;
    },
    contract(contractTo = 0) {
      this.container.style.height = `${contractTo}px`;
    }
  }
});
