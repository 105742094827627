







































import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'BaseCardButton',
  props: {
    active: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'blue-9'
    },
    icon: {
      type: String
    },
    iconColor: {
      type: String,
      default: 'red-13'
    },
    label: {
      type: String,
      default: ''
    },
    deleteItem: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number
    }
  },
  setup(props, { emit }) {
    return { props, emit };
  }
});
