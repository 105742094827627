


















































































































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  Ref,
  ref
} from '@vue/composition-api';
import { BasicForm, buildRule } from '@ligo/shared/mvc';
import { SocialBtn, LoginTexts } from '../../models';
import { Dictionary } from '@ligo/shared/utils';
import { AuthError } from '@ligo/bv-flow/store';
import BannerError from '../BannerError.vue';

export default defineComponent({
  name: 'Login',
  components: { BannerError },
  props: {
    socialBtns: {
      type: Array as PropType<Array<SocialBtn>>,
      default: () => []
    },
    form: {
      type: Object as PropType<BasicForm>,
      default: null
    },
    // This prop indicates if the username is an email
    emailConfiguration: {
      type: Boolean,
      default: true
    },
    texts: {
      type: Object as PropType<LoginTexts>,
      default: () => ({
        userLabel: 'user.labels.email',
        userPlaceholder: 'user.placeholders.email',
        passLabel: 'user.labels.password',
        passPlaceholder: 'user.placeholders.password'
      })
    },
    appRoot: String,
    providerLinks: { type: Object as PropType<Dictionary> },
    brand: {
      type: String,
      default: 'ligo'
    }
  },
  setup(props, { root, emit }) {
    const baseForm: Ref<any> = ref();

    const loginError: ComputedRef<AuthError> = computed(() => {
      return root.$store.state.authentication.authenticationError;
    });

    const performLogin = function () {
      if (props.form) {
        baseForm.value.setLoadingSubmit(true);
        emit('performLogin', baseForm);
      } else {
        emit('performLogin', username, password);
      }
    };

    const socialAuth = function (socialBtn: SocialBtn) {
      emit('socialAuth', socialBtn);
    };

    const username = ref('');
    const password = ref('');
    const passwordVisibility = ref(false);

    const usernameRules = [
      buildRule('required', 'validations.required_generic')
    ];
    if (props.emailConfiguration) {
      usernameRules.push(buildRule('email', 'validations.email_format'));
    }

    const passwordRules = [
      buildRule('required', 'validations.required_generic')
    ];

    return {
      performLogin,
      baseForm,
      socialAuth,
      username,
      password,
      passwordVisibility,
      usernameRules,
      passwordRules,
      loginError
    };
  }
});
