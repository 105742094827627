export enum NotaryProductTypeSlug {
  DUTCH_BV = 'bv-oprichten',
  OMZETTING = 'omzetting-naar-bv',
  HOLDING = 'persoonlijke-holding-oprichten',
  STITCHING = 'stichting-oprichten',
  STITCHING_DERDENGELDEN = 'stichting-derdengelden',
  ANBI_STITCHING = 'anbi-stichting-oprichten',
  TRANSFER = 'aandelenoverdracht',
  STATUTEN = 'statutenwijziging-naam',
  TERMS_AND_CONDITIONS_DRAFT = 'algemene-voorwaarden-opstellen',
  TERMS_AND_CONDITIONS_CHECK = 'algemene-voorwaarden-controleren',
  MARK_REGISTRATION = 'merkregistratie',
  MARK_MONITORING = 'merkbewaking',
  ENGLISH_BV = 'incorporate-dutch-bv',
  ENGLISH_HOLDING = 'incorporate-holding'
}

export enum NotaryProductSlug {
  BV_OPRICHTEN = 'bv-oprichten',
  HOLDING_OPRICHTEN = 'persoonlijke-holding-oprichten',
  INCORPORATE_DUTCH_BV = 'incorporate-dutch-bv',
  OMZETTING = 'omzetting-naar-bv',
  STICHTING_OPRICHTEN = 'stichting-oprichten',
  ANBI_STICHTING = 'anbi-stichting-oprichten',
  TRANSFER_OF_SHARES = 'aandelenoverdracht',
  INCORPORATE_HOLDING = 'incorporate-holding'
}

export enum DeprecatedServices {
  OLD_HOLDING = 'persoonlijke-holding-oud',
  HOLDING_OPZETTEN = 'holdingstructuur-opzetten'
}

export enum UpsellPackageNotaryProductSlug {
  UPSELL_INCORPORATE_HOLDING = 'upsell-package-incorporate-holding',
  UPSELL_HOLDING = 'upsell-package-holding-oprichting'
}

export type AllNotaryProductSlug =
  | NotaryProductSlug
  | UpsellPackageNotaryProductSlug;

export const NotaryProductQuestionnairePath = {
  'bv-oprichten': 'dutch-bv',
  'persoonlijke-holding-oprichten': 'holding',
  'incorporate-dutch-bv': 'english-bv',
  'omzetting-naar-bv': 'omzetting',
  'stichting-oprichten': 'stichting',
  'anbi-stichting-oprichten': 'anbi_stichting_oprichten',
  aandelenoverdracht: 'transfer_of_shares',
  'incorporate-holding': 'incorporate-holding/'
};

export const EXTRA_SERVICES: NotaryProductTypeSlug[] = [
  NotaryProductTypeSlug.TERMS_AND_CONDITIONS_CHECK,
  NotaryProductTypeSlug.TERMS_AND_CONDITIONS_DRAFT,
  NotaryProductTypeSlug.MARK_MONITORING,
  NotaryProductTypeSlug.MARK_REGISTRATION
];

export interface NotaryProductType {
  name: string;
  description: string;
  slug: NotaryProductTypeSlug;
  service_type: string;
  locale: ProductLocale;
  vidyard_tutorial_id: string;
}
export interface ProductLocale {
  EN: 'en';
  NL: 'nl';
}
