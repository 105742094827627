/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/
import Vue, { VueConstructor } from 'vue'

import { QuasarPluginOptions } from 'quasar'

import { useQuasar } from './import-quasar';
import { QuasarAppOptions } from './options';


export default async function <S = any>(App: VueConstructor, options: QuasarAppOptions<S>, quasar: Partial<QuasarPluginOptions>) {
  // install quasar
  useQuasar(quasar)

  // create store and router instances
  const store = typeof options.store === 'function'
    ? await options.store({ Vue })
    : options.store

  const router = typeof options.router === 'function'
    ? await options.router({ Vue, store })
    : options.router

  // make router instance available in store
  store["$router"] = router

  // Create the app instantiation Object.
  // Here we inject the router, store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    router,
    store,
    render: h => h(App),
    el: '#q-app'
  }

  // expose the app, the router and the store.
  // note we are not mounting the app here, since bootstrapping will be
  // different depending on whether we are in a browser or on the server.
  return {
    app,
    store,
    router
  }
}
