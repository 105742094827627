// Module for utilities functions related to strings.

// Cuts a string and adds '...' to the end if bigger than a given length.
export function cutString(str: string, len: number) {
  return str.length > len ? `${str.substring(0, len)}...` : str;
}

export function toKebabCase(str: string) {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-')
  );
}

// From a set of strings returns a string containing the first letter capitalized of each
export function extractInitials(words: string[], first = 2): string {
  let initials = '';
  for (const str of words) {
    if (str.length == 0) continue;
    initials += str[0].toLocaleUpperCase();
  }
  return initials.slice(0, first);
}

// From a string returns a string containing the first letter capitalized of each word
export function titleize(str: string): string {
  if (str.length == 0) return '';

  return str
    .trim()
    .split(' ')
    .map((word) => word[0].toLocaleUpperCase() + word.slice(1))
    .join(' ');
}
