import {
  useDutchBV,
  useEnglishBV,
  useHolding,
  useOmzetting,
  useStichting,
  useAnbiStichting,
  useTransferOfShares,
  useIncorporateHolding
} from './notarial_products';
import { NotaryProductSlug } from './product_structure';

const productTypeHandlers = {
  'dutch-bv': {
    handler: useDutchBV,
    route: 'DutchBV',
    slug: NotaryProductSlug.BV_OPRICHTEN
  },
  'english-bv': {
    handler: useEnglishBV,
    route: 'EnglishBV',
    slug: NotaryProductSlug.INCORPORATE_DUTCH_BV
  },
  holding: {
    handler: useHolding,
    route: 'Holding',
    slug: NotaryProductSlug.HOLDING_OPRICHTEN
  },
  'incorporate-holding': {
    handler: useIncorporateHolding,
    route: 'IncorporateHolding',
    slug: NotaryProductSlug.INCORPORATE_HOLDING
  },

  omzetting: {
    handler: useOmzetting,
    route: 'Omzetting',
    slug: NotaryProductSlug.OMZETTING
  },
  stichting: {
    handler: useStichting,
    route: 'Stichting',
    slug: NotaryProductSlug.STICHTING_OPRICHTEN
  },
  anbi_stichting_oprichten: {
    handler: useAnbiStichting,
    route: 'AnbiStichting',
    slug: NotaryProductSlug.ANBI_STICHTING
  },
  transfer_of_shares: {
    handler: useTransferOfShares,
    route: 'TransferOfShares',
    slug: NotaryProductSlug.TRANSFER_OF_SHARES
  }
};

export const useNotaryProduct = (slug: string, uid: string) => {
  return productTypeHandlers[slug].handler(uid);
};

export function getPathFromRouteName(name: string) {
  return Object.keys(productTypeHandlers).find((path) => {
    return productTypeHandlers[path].route == name;
  });
}

export function getRouteNameFromSlug(slug: string) {
  return Object.values(productTypeHandlers).find((path) => {
    return path.slug == slug;
  }).route;
}
