




























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseButton',
  props: {
    label: {
      type: String,
      required: true
    },
    filled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String
    },
    padding: {
      type: String
    },
    classes: {
      type: String
    },
    loading: {
      type: Boolean
    },
    unelevated: {
      type: Boolean,
      default: false
    }
  }
});
