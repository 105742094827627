import Vue from 'vue';
import { ref, watch } from '@vue/composition-api';
import { CalculatorField } from '../models/calculator';

const localeMapper = { 'en-us': 'en', nl: 'nl' };

export function getLocaleMapped(): 'en' | 'nl' {
  return localeMapper[Vue['i18n'].locale] || 'nl';
}

export function getI18nUnreact(field: CalculatorField, prop: string) {
  return field?.i18n?.[localeMapper[Vue['i18n'].locale]]?.[prop];
}

export function getI18n(field: CalculatorField, prop: string) {
  const result = ref(null);

  watch(
    () => Vue['i18n'].locale,
    (newVal) => {
      result.value = getI18nUnreact(field, prop);
    },
    { immediate: true }
  );

  return result;
}
