import { OmzettingTypes } from '../definitions/omzetting';
import { converted_from, conversionTypeOptions } from '../../../share';
import {
  LocalResource,
  NotaryProductResource,
  ProductField
} from '@ligo/bv-flow/store';
import { computed, Ref } from '@vue/composition-api';
import {
  commonProductFields,
  sharedBVProductFields,
  sharedCompanyFields,
  sharedEmployeeFields
} from '../../shared/commonProductFields';
import { visible_if_false } from '../../shared/conditions';

const visible_if_conversion_type_is_asset_or_none = (
  field: Ref<ProductField>
) => {
  return computed(() => {
    return () => {
      return (
        field.value.value === 'asset_liability' || field.value.value === 'none'
      );
    };
  });
};

export const bvOprichten: LocalResource<OmzettingTypes> = {
  url: '',
  locale: 'main',
  fieldsDescriptions: {
    ...sharedBVProductFields,
    ...commonProductFields,
    ...sharedCompanyFields,
    ...sharedEmployeeFields,
    converted_from: {
      options: converted_from,
      type: 'select',
      ids: true
    },
    type_of_conversion: {
      options: conversionTypeOptions,
      type: 'select',
      ids: true
    },
    holding_structure: {
      type: 'bool-options',
      dependency_dcl: {
        fieldParam: 'type_of_conversion',
        rule: visible_if_conversion_type_is_asset_or_none
      }
    },
    accountant: { type: 'bool-options' },
    free_intake: {
      type: 'bool-options',
      dependency_dcl: {
        fieldParam: 'accountant',
        rule: visible_if_false
      }
    },
    online_notary: { defaultValue: false }
  },
  formatJSON: (data: { [id: string]: any }) => {
    if (
      !(
        data['type_of_conversion'] === 'asset_liability' ||
        data['type_of_conversion'] === 'none'
      )
    )
      data['holding_structure'] = false;
    return data;
  }
};

export function useMainResource() {
  return {
    main: new NotaryProductResource(bvOprichten, 'main', 'omzetting')
  };
}
