






























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'StepHeader',
  props: {
    icon: {
      type: String,
      default: '/dashboard/images/lisa_avatar.svg'
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    hasGoBack: {
      type: Boolean,
      default: true
    },
    image: {
      type: String
    }
  }
});
