import { NotaryProduct } from '@ligo/dashboard/store';

export const NOTARY_PRODUCT_KEY = 'notary_product';

export interface NotaryProductState {
  selectedId: string;
  notaryProduct: NotaryProduct | null;
}

const state: NotaryProductState = {
  selectedId: localStorage.getItem(NOTARY_PRODUCT_KEY),
  notaryProduct: null
};

export default state;
