









































































import { defineComponent, computed } from '@vue/composition-api';
import { SQTableRow, SQTableColumn, SQTableAction } from '../s-q-table.hook';

export default defineComponent({
  name: 'SQBody',
  components: {},
  props: {
    row: {
      type: Object as () => SQTableRow,
      required: true
    },
    columns: {
      type: Array as () => SQTableColumn[],
      required: true
    },
    menuIcon: {
      type: String,
      required: true
    },
    actions: {
      type: Array as () => SQTableAction[],
      required: false
    }
  },
  setup(props) {
    const mappedColumns = computed(() =>
      props.columns.map((col) =>
        typeof col.field == 'string'
          ? props.row[col.field]
          : col.field(props.row)
      )
    );
    return { mappedColumns };
  }
});
