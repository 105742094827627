
































import { defineComponent, PropType, computed, ref } from '@vue/composition-api';
import { Coordinates, Dimensions, FieldTypes } from '../../../../models';
import { initCanvas, fitTextToWidth } from '@ligo/shared/utils';

type FieldsRepresentation = {
  [key in FieldTypes]: {
    fontFamily: string;
    fontSize?: number;
    justify?: 'start' | 'center' | 'end';
    sample: string;
    icon?: string;
  };
};

export default defineComponent({
  name: 'ESignFieldToFill',
  props: {
    color: {
      type: String,
      required: true
    },
    type: {
      type: String as PropType<string>,
      required: true
    },
    uuid: {
      type: String as PropType<string>,
      required: true
    },
    dimensions: {
      type: Object as PropType<Dimensions>,
      required: true
    },
    coordinates: {
      type: Object as PropType<Coordinates>,
      required: true
    },
    content: String
  },
  setup(props, { root }) {
    const requiredFonts = ['Arial', 'Brush Script MT'];
    const loadingFonts = ref(true);
    const fieldsRepresentation: FieldsRepresentation = {
      [FieldTypes.SIGNATURE]: {
        fontFamily: 'Brush Script MT',
        sample: root.$i18n
          .t('e_signature.sign_document.fields.samples.signature')
          .toString()
      },
      [FieldTypes.TEXT]: {
        fontFamily: 'Arial',
        fontSize: 14,
        justify: 'start',
        sample: root.$i18n
          .t('e_signature.sign_document.fields.samples.text')
          .toString()
      },
      [FieldTypes.DATE]: {
        fontFamily: 'Arial',
        fontSize: 14,
        justify: 'start',
        sample: root.$i18n
          .t('e_signature.sign_document.fields.samples.date')
          .toString()
      },
      [FieldTypes.CHECKBOX]: {
        fontFamily: 'Arial',
        icon: 'far fa-square-check',
        sample: 'X'
      }
    };

    function getSampleFontSize() {
      if (fieldsRepresentation[props.type].fontSize)
        return fieldsRepresentation[props.type].fontSize;

      const { canvas, context } = initCanvas(
        props.dimensions.width,
        props.dimensions.height
      );
      const { fontFamily, sample } = fieldsRepresentation[props.type];
      context!.font = `100px ${fontFamily}`;
      const fontSize = fitTextToWidth(canvas, sample);
      return fontSize - 2;
    }

    const getCurrentDimensions = computed(() => {
      const { width, height } = props.dimensions;
      return {
        width,
        height
      };
    });

    function getFieldStyle() {
      return `
        top: ${props.coordinates.y}px;
        left: ${props.coordinates.x}px;
        width: ${props.dimensions.width}px;
        height: ${props.dimensions.height}px;
      `;
    }

    const sampleTextFontSize = computed(() => getSampleFontSize());

    Promise.all(
      requiredFonts.map((font) => (document as any).fonts.load(`24px ${font}`))
    ).then(() => {
      loadingFonts.value = false;
    });

    return {
      getCurrentDimensions,
      fieldsRepresentation,
      sampleTextFontSize,
      loadingFonts,
      getFieldStyle
    };
  }
});
