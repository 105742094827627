import { NotaryProductService } from '@ligo/dashboard/customer/store';
import {
  Notary,
  NotaryProductEvent,
  NotaryProductSlug
} from '@ligo/dashboard/store';
import { Dictionary } from '@ligo/shared/utils';
import { ref } from '@vue/composition-api';
import { SimpleForm } from '../../base/SimpleForm/models/simpleForm.models';
import { ExtraQuestionPageInformation } from '../../utils/useCustomerPageInformation.hooks';
import { useGetFields, useGetNotarySelection } from './extra-fields.hooks';

export interface DialogPageInformation {
  next: string;
  cancel: string;
  text: string;
  title: string;
}

export const DIALOG_WITHOUT_NOTARY_KEY = 'next_dialog';
export const DIALOG_WITH_NOTARY_KEY = 'next_dialog_with_notary';

export const useCustomerProductSerializer = (uuid: string) => {
  const onSendInfo = (payload: Dictionary) => {
    return NotaryProductService.updateNotaryProduct(uuid, {
      product_data: { ...payload }
    });
  };

  return { onSendInfo };
};

export const useCustomerNotarySelection = (
  uuid: string,
  slug: NotaryProductSlug,
  notaryId: number | undefined,
  disable: () => boolean
) => {
  const { notaryField } = useGetNotarySelection(uuid, notaryId, disable);

  const { load: loadPageInformation, t, te } = ExtraQuestionPageInformation;

  const { onSendInfo } = useCustomerProductSerializer(uuid);

  const notaryList = ref([] as Notary[]);
  NotaryProductService.getNotariesList(uuid).then((response) => {
    notaryList.value = response.data;
  });

  const formConfig: SimpleForm = {
    translateT: (key) =>
      te(`custom.${slug}.${key}`) ? t(`custom.${slug}.${key}`) : t(key),
    translateTe: (key) => te(`custom.${slug}.${key}`) || te(key),
    fields: [notaryField],
    key: 'extra_questions'
  };

  return {
    notaryField,
    formConfig,
    notaryList,
    onSendInfo,
    loadPageInformation,
    t,
    te
  };
};

export const useCustomerExtraQuestions = (
  uuid: string,
  slug: NotaryProductSlug,
  emit: (event: string, ...args: any[]) => void,
  notaryId?: number
) => {
  const loading = ref(false);

  const { onSendInfo } = useCustomerProductSerializer(uuid);

  const onNext = (payload: Dictionary) => {
    loading.value = true;
    onSendInfo(payload)
      .then(() => {
        emit('event', NotaryProductEvent.SUBMIT);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const { load: loadPageInformation, t, te } = ExtraQuestionPageInformation;

  const { fields, useLayout, getCompanyName } = useGetFields(notaryId, t, slug);

  const formConfig: SimpleForm = {
    translateT: (key) =>
      te(`custom.${slug}.${key}`) ? t(`custom.${slug}.${key}`) : t(key),
    translateTe: (key) => te(`custom.${slug}.${key}`) || te(key),
    fields: fields,
    useLayout: useLayout,
    key: 'extra_questions'
  };

  return {
    loading,
    formConfig,
    getCompanyName,
    t,
    te,
    loadPageInformation,
    onSendInfo,
    onNext
  };
};
