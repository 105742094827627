







































































































import { defineComponent, ref } from '@vue/composition-api';
import { BasicForm } from '../models/Form';
import { BaseButton } from '@ligo/shared/components';
import LabelButton from './label-tooltip/LabelButton.vue';
import LabelTooltip from './label-tooltip/LabelTooltip.vue';

export default defineComponent({
  name: 'BaseForm',
  components: { BaseButton, LabelTooltip, LabelButton },
  props: {
    form: {
      type: BasicForm,
      required: true
    },
    save: {
      type: Boolean
    },
    noButton: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'lg'
    },
    classes: {
      type: String,
      default: 'font-button q-py-xs q-mt-sm'
    },
    filled: {
      type: Boolean,
      default: true
    },
    send: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    hasCancelBtn: {
      type: Boolean,
      default: true
    }
  },

  setup(props, { emit }) {
    const formulaire = ref(null as any);
    const loadingSubmit = ref(false);
    const after_submit = async () => {
      if (props.form.config.after_submit) props.form.config.after_submit();
      if (props.save) {
        loadingSubmit.value = true;
        await props.form.save(props.send);
        loadingSubmit.value = false;
      }
      emit('submit');
    };

    const submit = () => {
      formulaire.submit();
    };

    const validate = async () => {
      const resp = await formulaire.value.validate();
      return resp;
    };

    const resetValidation = () => {
      if (formulaire.value.resetValidation) formulaire.value.resetValidation();
    };

    const setLoadingSubmit = (value: boolean) => {
      loadingSubmit.value = value;
    };

    const visible = (field: any) => {
      return !field.hidden && (!field.dependency || field.dependency());
    };

    function composeSectionTooltipKey(key: string) {
      return `${key}_tooltip`;
    }
    const sectionModels = ref(Array(props.form.layout.length).fill(false));

    return {
      after_submit,
      validate,
      resetValidation,
      formulaire,
      props,
      submit,
      loadingSubmit,
      setLoadingSubmit,
      visible,
      composeSectionTooltipKey,
      sectionModels
    };
  }
});
