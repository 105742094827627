import { ProductField } from '@ligo/bv-flow/store';
import { computed, Ref } from '@vue/composition-api';

export const visible_if_not_true = (field: Ref<ProductField>) => {
  return computed(() => {
    return () => {
      return !field.value.value;
    };
  });
};

export const visible_if_true = (field: Ref<ProductField>) => {
  return computed(() => {
    return () => {
      return field.value?.value;
    };
  });
};

export const visible_if_false = (field: Ref<ProductField>) => {
  return computed(() => {
    return () => {
      return field.value.value === false;
    };
  });
};

export const visible_if_answer = (field: Ref<ProductField>, answer: string) => {
  return computed(() => {
    return () => {
      return field.value.value === answer;
    };
  });
};
