import { Contract } from '@ligo/dashboard/customer/store';
import { DefaultUser } from '@ligo/shared/auth';
import { EditorEvent } from './analytic.service';

export function analyticGenericEditorEvent(
  eventName: EditorEvent,
  contract: Contract,
  user: DefaultUser
) {
  (window as any).analytics.track(eventName, {
    user_id: user.uuid,
    contract_name: contract.name,
    user_membership_type: user.membership.membership_type.name,
    freemium: contract.free || false
  });
}

export function analyticDownloadContract(
  contract: Contract,
  btn: 'pdf' | 'word' | 'pdf-signed',
  user: DefaultUser
) {
  (window as any).analytics.track(EditorEvent.DOWNLOAD_IN_EDITOR, {
    contract_name: contract.name,
    document_type: btn,
    user_id: user.uuid,
    user_membership_type: user.membership.membership_type.name,
    freemium: contract.free || false
  });
}

export function analyticLoginEvent(user_uuid, user_email) {
  (window as any).analytics.track('dashboard - account login', {
    user_uuid,
    user_email
  });
}

export function analyticCancelPlanEvent(user: DefaultUser, corpus) {
  (window as any).analytics.track('Membership - Cancelled', {
    user_id: user.uuid,
    email: user.email,
    ...corpus
  });
}
