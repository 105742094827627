import { ref, Ref, computed } from '@vue/composition-api';
import {
  ContractType,
  ContractService,
  EXTERNAL_RESOURCES
} from '@ligo/dashboard/customer/store';
import { CategoryItem } from '../../../models';
import {
  usePaginated,
  Dictionary,
  ApiService,
  useAsyncState
} from '@ligo/shared/utils';
import { useI18n, useRouter } from '../../../composables';

const PER_PAGE = 10;
export const ALL_CONTRACTS_CATEGORY_ID = -1;

function getCategories() {
  const categoriesLabels: Ref<CategoryItem[]> = ref();
  const loading = ref(true);

  ContractService.categories()
    .then(
      (categories) =>
        (categoriesLabels.value = categories.data.map(
          (category): CategoryItem => ({
            id: category.id,
            name: category.name
          })
        ))
    )
    .catch((e) => {
      console.log('There was an error retrieving categories : ', e);
    })
    .finally(() => {
      loading.value = false;
    });
  return { categoriesLabels, loading };
}

export function useLibraryConfig(documentSlug: string) {
  const i18n = useI18n();
  const router = useRouter();
  const header = {
    title: i18n.t('contract.template.lib_title').toString(),
    subtitle: i18n.t('contract.template.lib_subtitle').toString()
  };

  const search = ref('');
  const category = ref(ALL_CONTRACTS_CATEGORY_ID);

  const { categoriesLabels, loading: loadingCategories } = getCategories();

  function setCategory() {
    return category.value != ALL_CONTRACTS_CATEGORY_ID ? [category.value] : [];
  }
  const {
    items,
    loading: loadingTemplates,
    onNextPage,
    restart
  } = usePaginated<ContractType>(
    (params: Dictionary) => {
      if (category.value) {
        return ContractService.templates({
          ...params,
          contract_category_ids: setCategory()
        });
      }
      return ContractService.templates(params);
    },
    PER_PAGE,
    [],
    search
  );

  const documentPreviewSlug = ref(documentSlug);

  const {
    state: documentPreviewState,
    loading: loadingDocumentPreview,
    load: loadDocumentPreview
  } = useAsyncState(
    () => ContractService.templatePreview(documentPreviewSlug.value),
    undefined,
    undefined,
    undefined,
    false
  );

  const documentPreview = computed(
    () => documentPreviewState.value && documentPreviewState.value.data
  );

  const loading = computed(
    () => loadingCategories.value && loadingTemplates.value
  );

  const breadcrumbPath = computed(() => {
    const path: {
      name: string;
      link: string;
      query?: { [key: string]: string };
    }[] = [
      {
        name: i18n.t('nav.contracts_title').toString(),
        link: 'ActiveContracts'
      },
      {
        name: i18n.t('nav.documents_library').toString(),
        link: 'DocumentsLibrary'
      }
    ];
    if (documentPreviewSlug.value)
      path.push({
        name: documentPreview.value?.name || '',
        link: 'DocumentsLibrary',
        query: { 'document-slug': documentPreviewSlug.value }
      });
    return path;
  });

  const loadingContract = ref(false);

  function onChangeCategory(id: number) {
    search.value = '';
    category.value = id;
    restart();
  }

  function onContractClick(item: ContractType) {
    router.push({
      name: 'DocumentsLibrary',
      query: { 'document-slug': item.slug }
    });
  }

  async function onStartContract(item: ContractType) {
    loadingContract.value = true;
    const newContract = await ContractService.createFromTemplate(item.id);
    const url = EXTERNAL_RESOURCES.CONTRACTS(
      newContract.contract_type.slug,
      newContract.uuid
    );
    const path = EXTERNAL_RESOURCES.AUTHENTICATED_REDIRECTION(url);
    const response = await ApiService.auth_redirection(path);
    window.location.assign(response.request.responseURL);
  }

  function onSlugChange(slug?: string) {
    documentPreviewSlug.value = slug;
    if (documentPreviewSlug.value) loadDocumentPreview();
  }

  function breadcrumbNavigation(
    routeName: string,
    query?: { [key: string]: string }
  ) {
    if (router.currentRoute.name !== routeName) {
      router.push({ name: routeName, query });
      return;
    }
    if (documentPreviewSlug.value && !query) {
      router.push({ name: 'DocumentsLibrary' });
      return;
    }
  }

  return {
    header,
    items,
    loading,
    categoriesLabels,
    search,
    loadingDocumentPreview,
    documentPreview,
    documentPreviewSlug,
    breadcrumbPath,
    onSlugChange,
    loadDocumentPreview,
    onNextPage,
    onChangeCategory,
    restart,
    onStartContract,
    onContractClick,
    breadcrumbNavigation
  };
}
