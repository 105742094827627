const CLICK_DOWN_UP_THRESHOLD = 200;

export function simpleClickEvent() {
  let stopWatch = Date.now();

  function mouseClickDown() {
    stopWatch = Date.now();
  }

  function isSimpleClick() {
    if (Date.now() - stopWatch < CLICK_DOWN_UP_THRESHOLD) return true;
    else return false;
  }

  return { mouseClickDown, isSimpleClick };
}
