import { ApiService, Dictionary } from '@ligo/shared/utils';
import { RESOURCES } from '../resources';
import { LitePayment, Payment } from '../models/payment';

export const PaymentService = {
  getById(id: string) {
    return ApiService.get<Payment>(`${RESOURCES.ALL_PAYMENTS + id}/`);
  },
  list(query: Dictionary = {}) {
    return ApiService.get<LitePayment[]>(RESOURCES.ALL_PAYMENTS, query);
  },
  async applyCoupon(paymentId: string, couponCode: string) {
    return ApiService.post(`${RESOURCES.PAYMENT(paymentId)}/apply_coupon`, {
      code: couponCode
    })
      .then(() => {
        return 'success';
      })
      .catch((error) => {
        return error.code == 403 ? 'no_product' : 'generic';
      });
  },
  async removeCoupon(paymentId: string) {
    return ApiService.patch(
      `${RESOURCES.PAYMENT(paymentId)}/revert_coupon`,
      {}
    );
  }
};
