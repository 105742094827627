import { RootContext } from './../../../../../utils/src/lib/tools/types';
import { buildRule } from '@ligo/shared/mvc';
import { ref } from '@vue/composition-api';
import { IVueI18n } from 'vue-i18n';

export enum AUTH_STEPS {
  SOCIAL_AUTH = 1,
  EMAIL_AUTH = 2
}

export function useHandleLoginSteps() {
  const currentStep = ref(AUTH_STEPS.SOCIAL_AUTH);

  function moveStep() {
    currentStep.value += 1;
  }

  function backStep() {
    currentStep.value -= 1;
    if (currentStep.value < 1) currentStep.value = AUTH_STEPS.SOCIAL_AUTH;
  }

  function goToFirstStep() {
    currentStep.value = AUTH_STEPS.SOCIAL_AUTH;
  }

  return {
    currentStep,
    moveStep,
    backStep,
    goToFirstStep
  };
}

const googleBtn = {
  icon: 'img:/questionnaire/icons/google.svg',
  label: 'google'
};

const linkedinBtn = {
  icon: 'img:/questionnaire/icons/linkedin.svg',
  label: 'linkedin'
};

export const socialBtns = [googleBtn, linkedinBtn];

export const registrationFormFields = [['firstname', 'lastname'], ['email']];

export const fieldRules = {
  required: buildRule('required', 'validations.required_generic'),
  email: buildRule('email', 'validations.email_format')
};

export const emailRules = [fieldRules.required, fieldRules.email];

export const useAuthErrors = (props: any, root: RootContext) => {
  const socialAuthLink = (provider: string) => {
    const redirection = `&auth_redirect_url=${props.redirectUrl}`;
    return (
      props.providerLinks[provider] +
      window.location.host +
      props.appRoot +
      redirection
    );
  };

  const errorMsg = () =>
    props.error.code
      ? root.$i18n.t(`auth.errorCodeMsg.${props.error.code}`)
      : props.error.message;

  const errorLinks = {
    not_confirmed: root.$t('auth.errorCodeLink.not_confirmed'),
    different_provider: root.$t(
      `auth.errorCodeLink.different_provider.${
        props.error?.data?.provider || 'no_provider'
      }`
    )
  };

  const errorURLs = {
    not_confirmed: {
      name: 'Confirmation',
      query: { redirect_url: props.redirectUrl }
    },
    different_provider: socialAuthLink(props.error?.data?.provider)
  };

  return {
    errorMsg,
    errorLinks,
    errorURLs
  };
};
export const EN_FLAG = 'img:/dashboard/icons/en-flag.svg';
export const NL_FLAG = 'img:/dashboard/icons/nl-flag.svg';

export function useBrandedLocale(i18n: IVueI18n) {
  const locales = [
    {
      value: 'en-us',
      flag: EN_FLAG,
      label: 'auth.language_list.en',
      labelMini: 'auth.language_list_mini.en'
    },
    {
      value: 'nl',
      flag: NL_FLAG,
      label: 'auth.language_list.nl',
      labelMini: 'auth.language_list_mini.nl'
    }
  ];
  const current = ref(i18n.locale);

  function onChangeLanguage(language: string) {
    current.value = language;
    i18n.locale = current.value;
  }

  if (!locales.map((v) => v.value).includes(i18n.locale)) {
    onChangeLanguage('en-us');
  } else {
    onChangeLanguage(i18n.locale);
  }

  return { current, locales, onChangeLanguage };
}
