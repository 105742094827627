





















import { defineComponent, ref } from '@vue/composition-api';
import vidyardEmbed from '@vidyard/embed-code';

export default defineComponent({
  name: 'VidyardVideoModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    videoUuid: {
      type: String,
      required: true
    },
    modalStyle: {
      type: String,
      default: 'width: 1100px; max-width: 80vw'
    }
  },

  setup(props) {
    const loading = ref(true);

    function renderPlayer() {
      vidyardEmbed.api.renderPlayer({
        uuid: props.videoUuid,
        container: document.getElementById('player-container')
      });
      vidyardEmbed.api.addReadyListener((_, player) => {
        if (player.uuid === props.videoUuid) {
          loading.value = false;
        }
      });
    }

    return { renderPlayer, loading };
  }
});
