


























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'LabelTooltip',
  props: {
    text: {
      type: String,
      required: true
    },
    useHtml: {
      type: Boolean
    }
  }
});
