import { TransferOfSharesTypes } from '../definitions/transfer_of_shares';
import {
  sharesRightDatesOptions,
  notarialCostsOptions,
  sharetype,
  accountantOptions
} from '../../../share';
import { LocalResource, NotaryProductResource } from '@ligo/bv-flow/store';
import { buildRule } from '@ligo/shared/mvc';
import {
  commonProductFields,
  sharedCompanyFields
} from '../../shared/commonProductFields';
import {
  visible_if_answer,
  visible_if_false,
  visible_if_true
} from '../../shared/conditions';

const oldAcquistionOptions = [
  { label: 'before_1993', value: true },
  { label: 'after_1993', value: false }
];

export const bvOprichten: LocalResource<TransferOfSharesTypes> = {
  url: '',
  locale: 'main',
  fieldsDescriptions: {
    ...commonProductFields,
    ...sharedCompanyFields,
    upsell_package_type_product_ids: { type: 'multi-select', hidden: true },
    company_name: {
      filter: (value: string) => {
        if (value) return value.replace(/(BV|B.V.)$/g, '').trim();
      },
      rules: [
        buildRule('bv_suffix', 'validations.bv_suffix'),
        buildRule('required', 'validations.required_generic')
      ],
      suffix: ' B.V.'
    },
    kvk: {},
    purchase_agreement: { type: 'bool-options' },
    purchase_price_received: { type: 'bool-options' },
    old_acquistion: {
      type: 'options',
      options: oldAcquistionOptions,
      ids: true
    },
    old_acquistion_date: {
      type: 'datepicker',
      mask: '##-##-####',
      dependency_dcl: {
        fieldParam: 'old_acquistion',
        rule: visible_if_true
      },
      rules: [buildRule('valid_date', 'validations.required_generic')]
    },
    received_shares: { type: 'bool-options' },
    received_shares_date: {
      type: 'datepicker',
      mask: '##-##-####',
      dependency_dcl: {
        fieldParam: 'received_shares',
        rule: visible_if_false
      },
      rules: [buildRule('valid_date', 'validations.required_generic')]
    },
    incorporation_date: {
      type: 'datepicker',
      mask: '##-##-####',
      dependency_dcl: {
        fieldParam: 'received_shares',
        rule: visible_if_true
      },
      rules: [buildRule('valid_date', 'validations.required_generic')]
    },
    statutes_adopted_date: {
      type: 'datepicker',
      mask: '##-##-####',
      rules: [buildRule('valid_date', 'validations.required_generic')]
    },
    balance_sheet: { type: 'bool-options' },
    date_of_balance: {
      type: 'datepicker',
      mask: '##-##-####',
      dependency_dcl: {
        fieldParam: 'balance_sheet',
        rule: visible_if_true
      },
      rules: [buildRule('valid_date', 'validations.required_generic')]
    },
    shares_rights_date: {
      type: 'options',
      options: sharesRightDatesOptions,
      ids: true
    },
    transfer_rights_date: {
      type: 'datepicker',
      mask: '##-##-####',
      rules: [buildRule('valid_date', 'validations.required_generic')],
      dependency_dcl: {
        fieldParam: 'shares_rights_date',
        rule: (field) => visible_if_answer(field, 'other_date')
      }
    },
    notarial_costs: {
      type: 'select',
      options: notarialCostsOptions,
      ids: true
    },
    shares_from: {
      type: 'number',
      mask: 'integer',
      rules: [
        buildRule('positive', 'validations.positive'),
        buildRule('required', 'validations.required_generic')
      ]
    },
    shares_to: {
      label: '',
      type: 'number',
      mask: 'integer',
      rules: [
        buildRule('positive', 'validations.positive'),
        buildRule('required', 'validations.required_generic')
      ]
    },
    share_type: { type: 'select', ids: true, options: sharetype },
    share_price_details: { type: 'textarea' },
    total_shares: {
      type: 'number',
      mask: 'integer',
      rules: [
        buildRule('positive', 'validations.positive'),
        buildRule('required', 'validations.required_generic')
      ]
    },
    accountant: {
      type: 'options',
      options: accountantOptions,
      ids: true
    },
    free_intake: {
      type: 'bool-options',
      dependency_dcl: {
        fieldParam: 'accountant',
        rule: visible_if_false
      }
    }
  }
};

export function useMainResource() {
  return {
    main: new NotaryProductResource(bvOprichten, 'main', 'transfer_of_shares')
  };
}
