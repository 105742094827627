


















import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'RequiredOption',
  props: {
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'np-primary-1100'
    },
    value: {
      type: Boolean,
      required: true
    }
  }
});
