import { isHash, nully } from '@ligo/shared/utils';

export function insertWithDot(data, dotKey, value) {
  const keys = dotKey.split('.');
  let parent = data;
  keys.forEach((key, i) => {
    if (isHash(parent)) {
      if (i == keys.length - 1) {
        parent[key] = value;
      } else {
        if (nully(parent[key])) parent[key] = {};
        parent = parent[key];
      }
    }
  });
}

export function accessWithDot(data, dotKey) {
  const keys = dotKey.split('.');
  let parent = data;
  let result = null;
  keys.every((key, i) => {
    if (isHash(parent)) {
      if (i == keys.length - 1) {
        result = parent[key];
        return false;
      } else {
        if (nully(parent[key])) {
          return false;
        }
        parent = parent[key];
        return true;
      }
    }
  });
  return result;
}
