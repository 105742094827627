


























































import { defineComponent, ref } from '@vue/composition-api';
import { getMaxHeightFromCurrentTop } from '@ligo/shared/utils';

export default defineComponent({
  name: 'SignCard',
  props: {
    title: String,
    text: String,
    badgeTextColor: String,
    badgeBackgroundColor: String,
    badgeText: String,
    requestNotification: String,
    icon: String,
    fullySigned: Boolean,
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const container = ref();
    const { height } = getMaxHeightFromCurrentTop(container);
    return { container, height };
  }
});
