import { Member } from './member';

export enum PartnerCategory {
  BRONZE = 'bronze',
  SILVER = 'silver',
  GOLD = 'gold'
}

export interface Partner {
  id: number;
  description: string;
  name: string;
  logo: string;
}

export interface Label {
  id: number;
  description: string;
  name: string;
}

export interface PartnerOffer {
  id: number;
  benefits: string[];
  category: PartnerCategory;
  description: string;
  enabled: boolean;
  name: string;
  partner: Partner;
  labels: Label[];
  redirection_url: string;
  redeem_by_member: boolean;
  use_redirection: boolean;
}

export interface PartnerOfferLead {
  id: number;
  partner_offer: PartnerOffer;
  member: Member;
}
