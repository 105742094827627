






import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { initPaymentSession } from './adyen.hooks';

export default defineComponent({
  name: 'AdyenDropin',
  props: {
    sessionId: String,
    redirectResult: String,
    uuid: {
      type: String,
      required: true
    },
    requestUrl: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const dropinContainer = ref();
    onMounted(() => {
      initPaymentSession(
        props.uuid,
        props.requestUrl,
        dropinContainer,
        props.sessionId,
        props.redirectResult,
        emit
      );
    });

    return { dropinContainer };
  }
});
