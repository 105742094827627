



































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { Dictionary } from '@ligo/shared/utils';
import { emailRules } from '../hooks/auth';
import SocialCard from './SocialCard.vue';

export default defineComponent({
  name: 'EmailConfirmation',
  components: { SocialCard },
  props: {
    brand: {
      type: String,
      default: ''
    },
    brandIcon: {
      type: String,
      default: ''
    },
    backPage: {
      type: String,
      default: 'Login'
    },
    texts: {
      type: Object as PropType<Dictionary>,
      default: () => ({
        emailLabel: 'user.labels.email',
        emailPlaceholder: 'user.placeholders.email'
      })
    }
  },
  setup(props, { root, emit }) {
    const email = ref('');
    const confirmationSent = ref(false);

    const sendConfirmationEmail = (e) => {
      confirmationSent.value = true;
      root.$store.dispatch('authentication/cleanErrors');
      emit('send-confirmation-email', email.value);
    };

    return {
      sendConfirmationEmail,
      emailRules,
      confirmationSent,
      email
    };
  }
});
