import { ActionTree } from 'vuex';

import { ApiService } from '@ligo/shared/utils';

import { pageInformationState } from './state';

export const actionsFactory = <StateInterface>(resources: {
  PAGE_INFORMATION: string;
}): ActionTree<pageInformationState, StateInterface> => ({
  async loadPage({ commit }, slug) {
    const response = await ApiService.get<{ data: string }>(
      `${resources.PAGE_INFORMATION}/${slug}`
    );
    commit('setData', { slug, data: response.data.data });
  },
  setLocalePrefix({ commit }, localePrefix) {
    commit('setLocalePrefix', { localePrefix });
  }
});
