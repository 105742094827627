import { NotaryProductSlug } from '@ligo/bv-flow/store';
/**
 * Service utility for interaction with Segment analytics configuration of BV-Flow
 */

import { TokenInterface } from '@ligo/bv-flow/store';
import { Dictionary } from '@ligo/shared/utils';

// Page Categories
const PAGE_CATEGORY = 'BV Flow';

interface ContactInfo {
  email: string;
  userId: string;
  phone: string;
  firstname: string;
  lastname: string;
}

export enum Apps {
  BV = 'bv-flow'
}

export enum NotarialEvent {
  EXIT = 'notarial_exit',
  SELECT_CONTACT_PERSON = 'notarial-select-contact-person',
  SELECT_SERVICE = 'notarial_select_service',
  SELECT_PACKAGE = 'notarial_select_package',
  SELECT_HOLDING = 'notarial_select_holding',
  SELECT_NAME = 'notarial_select_company_name',

  SELECT_SERVICE_DETAILS_GENERAL = 'notarial_select_service_details_general',
  SELECT_SERVICE_DETAILS_CONTACT = 'notarial_select_service_details_contact',
  SELECT_SERVICE_DETAILS_COMPLETE = 'notarial_select_service_details_complete',
  SELECT_SERVICE_DETAILS_OTHER = 'notarial_select_service_details_other',
  SELECT_SERVICE_DETAILS_METHOD = 'notarial_select_service_details_method',
  SELECT_SERVICE_DETAILS_GOAL = 'notarial_select_service_details_goal',
  SELECT_SERVICE_DETAILS_MANAGE = 'notarial_select_service_details_manage',
  SELECT_SERVICE_DETAILS_INFORMATION = 'notarial_select_service_details_information',

  SELECT_SHAREHOLDERS = 'notarial_select_shareholders',
  SELECT_VALUE_SHARES = 'notarial_select_value_of_shares',
  SELECT_DGA = 'notarial_select_dga_salary_administration',
  SELECT_DEED = 'notarial_select_deed_preview',
  SELECT_NOTARY = 'notarial_select_notary',
  SELECT_CHECKOUT = 'notarial_select_checkout',

  SELECT_MEMBERSHIP_UPSELL = 'notarial_select_membership_upsell'
}

export enum CalculatorEvent {
  CALCULATOR_START = 'notarial calculator - start',
  COMPLETE_STEP = 'notarial calculator - complete',
  CONTINUE_WITH_FLOW = 'notarial calculator - flow start step'
}

export enum NotarialStep {
  SELECT_CONTACT_PERSON = 'contact name step',
  SELECT_SERVICE = 'home step',
  SELECT_PACKAGE = 'package step',
  SELECT_HOLDING = 'holding step',
  SELECT_NAME = 'company name step',
  SELECT_SERVICE_DETAILS = 'service details step',
  SELECT_SHAREHOLDERS = 'shareholders step',
  SELECT_VALUE_SHARES = 'value of shares step',
  SELECT_DGA = 'dga-salaris administration step',
  SELECT_DEED = 'deed preview step',
  SELECT_NOTARY = 'notary step',
  SELECT_CHECKOUT = 'invoice step',
  SELECT_MEMBERSHIP = 'membership upsell step'
}

export const CUSTOM_STEPS_FOR_SERVICE_DETAILS: Dictionary<NotarialEvent[]> = {
  [NotaryProductSlug.BV_OPRICHTEN]: [
    NotarialEvent.SELECT_SERVICE_DETAILS_GENERAL,
    NotarialEvent.SELECT_SERVICE_DETAILS_CONTACT,
    NotarialEvent.SELECT_SERVICE_DETAILS_COMPLETE
  ],
  [NotaryProductSlug.HOLDING_OPRICHTEN]: [
    NotarialEvent.SELECT_SERVICE_DETAILS_GENERAL
  ],
  [NotaryProductSlug.INCORPORATE_DUTCH_BV]: [
    NotarialEvent.SELECT_SERVICE_DETAILS_GENERAL,
    NotarialEvent.SELECT_SERVICE_DETAILS_CONTACT,
    NotarialEvent.SELECT_SERVICE_DETAILS_COMPLETE
  ],
  [NotaryProductSlug.OMZETTING]: [
    NotarialEvent.SELECT_SERVICE_DETAILS_GENERAL,
    NotarialEvent.SELECT_SERVICE_DETAILS_CONTACT,
    NotarialEvent.SELECT_SERVICE_DETAILS_OTHER,
    NotarialEvent.SELECT_SERVICE_DETAILS_METHOD
  ],
  [NotaryProductSlug.STICHTING_OPRICHTEN]: [
    NotarialEvent.SELECT_SERVICE_DETAILS_GENERAL,
    NotarialEvent.SELECT_SERVICE_DETAILS_GOAL,
    NotarialEvent.SELECT_SERVICE_DETAILS_MANAGE,
    NotarialEvent.SELECT_SERVICE_DETAILS_OTHER
  ],
  [NotaryProductSlug.ANBI_STICHTING]: [
    NotarialEvent.SELECT_SERVICE_DETAILS_GENERAL,
    NotarialEvent.SELECT_SERVICE_DETAILS_GOAL,
    NotarialEvent.SELECT_SERVICE_DETAILS_MANAGE,
    NotarialEvent.SELECT_SERVICE_DETAILS_OTHER
  ],
  [NotaryProductSlug.TRANSFER_OF_SHARES]: [
    NotarialEvent.SELECT_SERVICE_DETAILS_GENERAL,
    NotarialEvent.SELECT_SERVICE_DETAILS_INFORMATION
  ]
};

// Utilities functions

function extractContactFromToken(token: any): ContactInfo {
  return {
    email: token.email,
    userId: token.userUuid,
    phone: token.userPhone,
    firstname: token.firstname,
    lastname: token.lastname
  };
}

// Service
export const Analytic = {
  registerCalculatorStep: function (
    event: CalculatorEvent | string,
    token: any,
    calculatorInstance: any
  ) {
    window.analytics.track(event, {
      token,
      calculatorInstance
    });
  },

  registerEvent: function (
    product: string,
    name: NotarialEvent | string,
    content?: NotarialStep | string,
    token?: any,
    props?: any
  ) {
    const payload = {
      content,
      notaryProduct: product,
      loggedIn: token && !!token.access_token,
      ...props,
      ...(token && token.access_token ? extractContactFromToken(token) : {})
    };

    window.analytics.track(name, payload);
    // console.log('Analytics T', { name, payload });
  },

  registerUser: function (token: TokenInterface) {
    const { userId, ...info } = extractContactFromToken(token);
    (window as any).analytics.identify(userId, info);
    // console.log('Analytics I', { id, payload: info });

    this.registerEvent(
      '',
      NotarialEvent.SELECT_CONTACT_PERSON,
      NotarialStep.SELECT_CONTACT_PERSON,
      token
    );
  },

  registerPage: function (name: string) {
    window.analytics.page(PAGE_CATEGORY, name);
    // console.log('Analytics P', { page_category: PAGE_CATEGORY, name });
  },

  reset: function () {
    window.analytics.reset();
  }
};
