import { LocalResourceFields } from '@ligo/bv-flow/store';
import { buildRule } from '@ligo/shared/mvc';
import {
  ShareholderType,
  documentType,
  functionKeys,
  maritalStatus,
  representationType,
  ubosValues,
  proprietorshipAction
} from '../../share';
import { capitalize } from '../../tools/mainResource';
import { visible_if_false, visible_if_true } from './conditions';
import {
  getCountryList,
  getNationalityList
} from '../../../services/country.service';

const now = new Date();
const yearsAgo = new Date(
  now.getFullYear() - 18,
  now.getMonth(),
  now.getDate()
);
const legalAge = yearsAgo.toISOString().split('T')[0];

const textWithCapitalLetter = { filter: capitalize, filterable: true };

export const commonShareholderFieldDescriptions: LocalResourceFields<ShareholderType> = {
  type: {
    hidden: true
  },
  is_natural_person: { type: 'bool', hidden: true, defaultValue: true },
  is_new_company: { type: 'bool', hidden: true, defaultValue: false },
  is_existing_company: { type: 'bool', hidden: true, defaultValue: false },
  address: textWithCapitalLetter,
  document_type: {
    type: 'options',
    options: documentType,
    ids: true
  },
  nationality: {
    type: 'select',
    filterable: true,
    options: getNationalityList
  },
  document_number: {},
  issue_date: {
    type: 'datepicker',
    datetimeOptions: (date: any) => {
      const now = new Date().toISOString().split('T')[0];
      return date <= now.replace(/-/gi, '/');
    },
    mask: '##-##-####',
    rules: [
      buildRule('before_today', 'validations.before_today'),
      buildRule('valid_date', 'validations.required_generic')
    ]
  },
  expiration_date: {
    type: 'datepicker',
    datetimeOptions: (date: any) => {
      const now = new Date().toISOString().split('T')[0];
      return date > now.replace(/-/gi, '/');
    },
    mask: '##-##-####',
    rules: [
      buildRule('after_today', 'validations.after_today'),
      buildRule('valid_date', 'validations.required_generic')
    ]
  },
  place_of_issue: textWithCapitalLetter,
  bsn: {
    dependency_dcl: {
      fieldParam: 'bsn_not_present',
      rule: visible_if_false
    }
  },
  bsn_not_present: {
    label: 'empty',
    type: 'checkbox',
    rules: [],
    defaultValue: false
  },
  lives_in_netherlands: { type: 'bool-options' },
  english_proficient: { type: 'bool-options' },
  shares: {
    type: 'number',
    label: 'empty',
    mask: 'integer',
    rules: [
      buildRule('positive', 'validations.positive'),
      buildRule('only_numbers', 'validations.only_numbers'),
      buildRule('required', 'validations.required_generic')
    ]
  },
  dga_plan: { type: 'bool', defaultValue: false },
  prefix: {
    type: 'select',
    options: ['Mrs', 'Mr'],
    ids: true
  },
  firstname: textWithCapitalLetter,
  lastname: textWithCapitalLetter,
  birthdate: {
    type: 'datepicker',
    mask: '##-##-####',
    rules: [
      buildRule('legal_age', 'validations.legal_age'),
      buildRule('valid_date', 'validations.required_generic')
    ],
    datetimeOptions: (date: any) => {
      return date <= legalAge.replace(/-/gi, '/');
    },
    defaultDateValue: 'Years',
    defaultYearMonth: `${yearsAgo.getFullYear().toString()}/` + '01'
  },
  birthplace: textWithCapitalLetter,
  country_of_birth: {
    type: 'select',
    filterable: true,
    options: getCountryList
  },
  residence_country: {
    type: 'select',
    filterable: true,
    options: () => getCountryList(true)
  },
  email: {
    filter: (value: string) => {
      return value && value.toLowerCase();
    },
    rules: [
      buildRule('email', 'validations.email_format'),
      buildRule('required', 'validations.required_generic')
    ]
  },
  phone_number: { type: 'phone', placeholder: 'empty', defaultValue: '' },
  marital_status: { type: 'select', options: maritalStatus, ids: true },
  has_proprietorship: { type: 'bool-options' },
  proprietorship_action: {
    type: 'select',
    options: proprietorshipAction,
    ids: true,
    dependency_dcl: {
      fieldParam: 'has_proprietorship',
      rule: visible_if_true
    }
  },
  kvk_proprietorship: {
    dependency_dcl: {
      fieldParam: 'has_proprietorship',
      rule: visible_if_true
    }
  },
  zip_code: {},
  location: textWithCapitalLetter
};

export const companyShareholderFields: LocalResourceFields<any> = {
  holding: { type: 'bool', defaultValue: false },
  holding_name: {
    label: 'empty',
    filter: (value: string) => {
      if (value) return value.replace(/(BV|B.V.)$/g, '').trim();
    },
    rules: [
      buildRule('bv_suffix', 'validations.bv_suffix'),
      buildRule('required', 'validations.required_generic')
    ],
    suffix: ' B.V.'
  },
  zip_code: {},
  representation_type: {
    type: 'select',
    options: representationType,
    ids: true
  },
  chamber: {},
  director_authorized: { type: 'bool-options' },
  function: {
    type: 'select',
    options: functionKeys,
    ids: true,
    placeholder: 'empty'
  },
  company_location: textWithCapitalLetter,
  company_country: {
    type: 'select',
    filterable: true,
    options: getCountryList
  },
  address_equals_company_address: {
    type: 'checkbox',
    rules: [],
    defaultValue: false,
    label: 'empty'
  },
  company_address: textWithCapitalLetter,
  company_zipcode: { label: 'empty' },
  incorporated_in_netherlands: { type: 'bool-options' }
};

export const uboShareholderFields: LocalResourceFields<any> = {
  ubo_kvk_registered: {
    type: 'bool-options',
    dependency_dcl: {
      fieldParam: 'incorporated_in_netherlands',
      rule: visible_if_true
    }
  },
  ubo_kvk_registered_check: {
    type: 'checkbox',
    defaultValue: false,
    label: 'empty',
    dependency_dcl: {
      fieldParam: 'ubo_kvk_registered',
      rule: visible_if_false
    }
  },
  ubos: {
    type: 'select',
    options: ubosValues
  }
};
