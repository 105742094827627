/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
export function initIntercom(key: string) {
  var w: any = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i: any = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    (function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + key;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    })();
  }
}
