








































import {
  defineComponent,
  computed,
  ref,
  getCurrentInstance
} from '@vue/composition-api';

const YOUTUBE_IMG_PREVIEW = 'https://img.youtube.com/vi/';
const IMG_MAX_RESOLUTION = '/maxresdefault.jpg';
const PROGRESS = 100;
const LOADING_TIME = 1000;

export default defineComponent({
  name: 'BasicYouTubeVideo',
  components: {},
  props: {
    videoUrl: {
      type: String,
      required: true
    },
    radius: {
      type: String,
      default: '10'
    },
    playIconSizeDesktop: String,
    playIconSizeMobile: String
  },
  setup(props, { root }) {
    const loadProgress = ref(PROGRESS);
    const videoLoading = ref(false);
    const showVideo = ref(false);
    const player = ref();
    const PLAY_ICON_SIZE_DESKTOP = props.playIconSizeDesktop || '95px';
    const PLAY_ICON_SIZE_MOBILE = props.playIconSizeMobile || '45px';

    // Init youtube API comunication ********************************
    let tag = document.createElement('script');

    tag.src = 'https://www.youtube.com/iframe_api';
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    // **************************************************************

    const getId = computed(() => {
      const urlParts = props.videoUrl.split('/');
      return urlParts[urlParts.length - 1];
    });

    const getImgPreview = computed(() => {
      return YOUTUBE_IMG_PREVIEW + getId.value + IMG_MAX_RESOLUTION;
    });

    const instanceId = computed((): string => {
      return (getCurrentInstance() as any)._uid;
    });

    function onPlayerReady(event) {
      // Control the player when the component is ready
      event.target.playVideo();
    }

    function onPlayerStateChange(event) {
      // Control the player when the state change
      console.log('On Player State Change');
    }

    function onLoadVideo() {
      const previewHeight = document.getElementById('preview').offsetHeight;
      videoLoading.value = true;
      setTimeout(() => {
        showVideo.value = true;
        root.$nextTick(() => {
          player.value = new YT.Player(`${instanceId.value}-youtubeVideo`, {
            videoId: getId.value,
            height: previewHeight,
            events: {
              onReady: onPlayerReady,
              onStateChange: onPlayerStateChange
            }
          });
        });
      }, LOADING_TIME);
    }

    return {
      getId,
      getImgPreview,
      loadProgress,
      videoLoading,
      onLoadVideo,
      showVideo,
      instanceId,
      PLAY_ICON_SIZE_DESKTOP,
      PLAY_ICON_SIZE_MOBILE
    };
  }
});
