import { computed } from '@vue/composition-api';
import { CustomerDashboardState } from '@ligo/dashboard/customer/store';
import { NotaryProductDocumentType } from '@ligo/dashboard/store';
import { RootContext, useStoreValue } from '@ligo/shared/utils';
import { getDocumentsBy } from '../documents.hooks';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFinalizedProduct(root: RootContext) {
  const notaryProductId = useStoreValue<string, CustomerDashboardState>(
    root.$store
  )('notaryProduct/getNotaryProductId');

  const { items, loading } = getDocumentsBy(notaryProductId, {
    document_types: [
      NotaryProductDocumentType.FINAL_DEED,
      NotaryProductDocumentType.KVK
    ]
  });

  const kvks = computed(() => {
    const docs = items.value?.filter(
      (doc) => doc.document_type == NotaryProductDocumentType.KVK
    );
    return docs;
  });

  const deeds = computed(() => {
    const docs = items.value?.filter(
      (doc) => doc.document_type == NotaryProductDocumentType.FINAL_DEED
    );

    return docs;
  });

  return { kvks, deeds, loading, items };
}
