import { RESOURCES } from '../resources';
import { ApiService, Dictionary } from '@ligo/shared/utils';
import { PartnerOffer, PartnerOfferLead } from '../..';

export const PartnerService = {
  partnerOffers(params: Dictionary) {
    return ApiService.get<PartnerOffer[]>(RESOURCES.PARTENER_OFFERS, params);
  },

  createLead(partnerOfferId: number) {
    return ApiService.post<any, PartnerOfferLead>(
      RESOURCES.PARTENER_OFFER_LEAD,
      {
        partner_offer_id: partnerOfferId
      }
    );
  }
};
