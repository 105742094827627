




















import { defineComponent } from '@vue/composition-api';
import { useBrandedLocale } from '../hooks/auth';
export default defineComponent({
  name: 'BrandI18n',
  props: {
    brand: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const { current, locales, onChangeLanguage } = useBrandedLocale(root.$i18n);
    return {
      current,
      locales,
      onChangeLanguage
    };
  }
});
