import { computed, Ref, ref, watch, onBeforeMount } from '@vue/composition-api';
import { Form } from '@ligo/dashboard/components';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import {
  ApiService,
  simpleNotification,
  notifyAsync
} from '@ligo/shared/utils';
import { useStore, useI18n } from '../../composables';

function required(msg) {
  return (val) => !!val || msg;
}

export function useLocale(notify = true, reload = true) {
  const i18n = useI18n();

  const locales = computed(() => [
    { value: 'en-us', label: i18n.t('en') },
    { value: 'nl', label: i18n.t('nl') }
  ]);
  const current = ref(localStorage.getItem('locale') || 'en-us');

  watch(current, () => {
    document.body.style.cursor = 'wait';
    const locale = current.value.split('-')[0];
    ApiService.patch(RESOURCES.ME, {
      locale
    })
      .then(() => {
        if (notify) simpleNotification(i18n.t('account.profile.info_success'));
        if (reload) window.location.reload();
      })
      .catch((e) => {
        if (notify) simpleNotification(i18n.t('account.profile.info_error'));
        console.error('Failed to update locale : ', e);
      })
      .finally(() => {
        localStorage.setItem('locale', current.value);
        document.body.style.cursor = 'unset';
      });
  });

  return { current, locales };
}

export function useSettings(form: Ref<Form>) {
  const store = useStore();
  const i18n = useI18n();

  const model = ref({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    provider: ''
  });

  const rules = {
    firstname: [required(i18n.t('account.profile.firstname_required'))],
    lastname: [required(i18n.t('account.profile.lastname_required'))],
    phone: [required(i18n.t('account.profile.phone_required'))]
  };

  function load() {
    ApiService.get(RESOURCES.ME).then(({ data }: any) => {
      model.value = {
        firstname: data.firstname,
        lastname: data.lastname,
        phone: data.phone || '',
        email: data.email,
        provider: data.provider
      };
    });
  }

  function onUpdateSettings() {
    form.value.validate().then((valid) => {
      if (valid) {
        notifyAsync(
          ApiService.patch(RESOURCES.ME, model.value).then(() => {
            store.dispatch('authentication/loadUserInfo');
          }),
          i18n.t('account.profile.info_success'),
          i18n.t('account.profile.info_error')
        );
      }
    });
  }

  onBeforeMount(() => {
    load();
  });

  return { form, rules, model, onUpdateSettings };
}

export function useChangePassword(form: Ref<Form>) {
  const i18n = useI18n();

  const model = ref({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  });

  const rules = {
    oldPassword: [required(i18n.t('account.profile.old_password_required'))],
    newPassword: [
      required(i18n.t('account.profile.new_password_required')),
      (val) => val.length >= 8 || i18n.t('account.profile.password_minimum')
    ],
    newPasswordConfirmation: [
      required(i18n.t('account.profile.new_password_confirmation_required')),
      (val) => val.length >= 8 || i18n.t('account.profile.password_minimum'),
      (val) =>
        model.value.newPassword == val ||
        i18n.t('account.profile.passwords_match')
    ]
  };

  function onChangePassword() {
    form.value.validate().then((valid) => {
      if (valid) {
        notifyAsync(
          ApiService.post(RESOURCES.CHANGE_PASSWORD, {
            old_password: model.value.oldPassword,
            new_password: model.value.newPassword,
            new_password_confirmation: model.value.newPasswordConfirmation
          }),
          i18n.t('account.profile.password_success'),
          i18n.t('account.profile.password_error')
        );
      }
    });
  }
  return { model, onChangePassword, rules };
}
