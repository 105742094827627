

























import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  name: 'SLigoAssistantAvatar',
  components: {},
  props: {
    x: {
      type: Number,
      required: true
    },
    y: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const X = computed(() => props.x - 50);
    const Y = computed(() => props.y - 50);
    return { X, Y };
  }
});
