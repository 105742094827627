<template>
  <div>
    <div class="row">
      <p
        :class="`col-10 col-sm-8 col-lg-7 q-mt-lg q-ml-xl w-600 font-${
          $q.screen.gt.md ? '46' : '30'
        } `"
      >
        {{ $t('errors.404') }}
      </p>
    </div>
    <div class="row justify-center">
      <q-img
        class="col-10 col-sm-8 col-xl-7"
        :style="`top: -${$q.screen.gt.md ? '100' : '50'}px`"
        src="../images/bv_map.svg"
      />
      <div class="column justify-center">
        <base-button
          :class="`self-end q-mb-lg w-600 font-16`"
          no-caps
          filled
          :label="$t('errors.back_home')"
          @click="$emit('back')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'Error404'
});
</script>
