import { LocalResource, NotaryProductResource } from '@ligo/bv-flow/store';
import { ShareholderType, fundationRoleType } from '../../../share';
import {
  commonShareholderFieldDescriptions,
  companyShareholderFields
} from '../../shared/commonShareholderFields';
import { visible_if_true } from '../../shared/conditions';

export const ShareholderResourceDefinition: LocalResource<ShareholderType> = {
  url: '#',
  locale: 'shareholder',
  fieldsDescriptions: {
    ...commonShareholderFieldDescriptions,
    ...companyShareholderFields,
    become_director: { type: 'bool-options' },
    foundation_role: {
      type: 'select',
      dependency_dcl: {
        fieldParam: 'become_director',
        rule: visible_if_true
      },
      options: fundationRoleType,
      ids: true
    },
    company_name: {}
  },
  formatJSON: (data: { [id: string]: any }) => {
    if (
      data['is_existing_company'] &&
      data['address_equals_company_address'] === true
    ) {
      data['address'] = data['company_address'];
      data['location'] = data['company_location'];
      data['zip_code'] = data['company_zipcode'];
    }
    return data;
  }
};

export const shareholderResources = () => {
  return {
    p1: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p1',
      'stichting'
    ),
    p2: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p2',
      'stichting'
    ),
    p3: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p3',
      'stichting'
    ),
    p4: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p4',
      'stichting'
    )
  };
};
