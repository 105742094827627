export function onlyNull(value) {
  return value === null;
}

export function nully(value) {
  return value === undefined || value === null;
}

export function checkConstructor(value, type) {
  return value.constructor === type;
}

export function strictTrue(value) {
  return value === true;
}

export function isHash(value) {
  return checkConstructor(value, Object);
}

export function isEmptyHash(value) {
  return (
    Object.getPrototypeOf(value) === Object.prototype &&
    Object.keys(value).length === 0
  );
}
