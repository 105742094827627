











import { defineComponent, ref } from '@vue/composition-api';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import { ApiService, Dictionary } from '@ligo/shared/utils';
import OnboardingRegisterHandler from '../../components/onboarding/OnboardingRegister.handler.vue';
import {
  ContractService,
  ExtraServiceService,
  MembershipService
} from '@ligo/dashboard/customer/store';
import { useRouter } from '../../composables';

export default defineComponent({
  name: 'OnboardingPage',
  components: { OnboardingRegisterHandler },
  props: {
    redirectUrl: String,
    contractUuid: String,
    extraServiceTypeUuid: String,
    planId: String
  },
  setup(props) {
    const model = ref({});
    const ready = ref(false);
    const loading = ref(false);
    const router = useRouter();
    if (props.contractUuid) {
      ContractService.saveSelection(props.contractUuid);
    }
    if (props.extraServiceTypeUuid) {
      ExtraServiceService.saveSelection(props.extraServiceTypeUuid);
    }

    if (props.planId) {
      MembershipService.saveSelection(props.planId);
    }

    async function redirect() {
      const redirected =
        (await ContractService.checkAndClaimFromStorage()) ||
        (await MembershipService.checkAndCreateFromStorage());
      await ExtraServiceService.checkAndCreateFromStorage();
      if (!redirected) {
        if (props.redirectUrl) {
          router.push(props.redirectUrl);
        } else {
          router.push({ name: 'Home' });
        }
      }
    }

    ApiService.get(RESOURCES.ME).then(({ data }: any) => {
      model.value = data?.metadata || {};
      if (data?.onboarded_at) {
        redirect();
      } else {
        ready.value = true;
      }
    });
    const onNext = (value: Dictionary) => {
      loading.value = true;
      ApiService.patch(RESOURCES.ME, {
        metadata: value,
        locale: value['preferred_language'] || 'en'
      })
        .then((_) => {
          ApiService.post(RESOURCES.ME_ONBOARDING_COMPLETED)
            .then(async () => {
              redirect();
            })
            .finally(() => {
              loading.value = false;
            });
        })
        .catch(() => {
          loading.value = false;
        });
    };

    return { onNext, model, ready, loading };
  }
});
