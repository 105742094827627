import { useEnvironment } from '../../../../../netlify_functions';

const { API, CUSTOMER_DASHBOARD } = useEnvironment();

const CUSTOMER_DASHBOARD_API = 'api_customer_dashboard';

export const RESOURCES = {
  LIGO: API,
  BASE: `${API}api_questionnaire/`,
  LOGIN: `${API}api/member_auth/sign_in`,
  CUSTOMER_DASHBOARD: CUSTOMER_DASHBOARD,
  TERMS_AND_CONDITION: 'https://www.ligo.nl/terms',
  DASHBOARD: 'dashboard',
  OMZETTING: 'omzetting-naar-bv',
  ENGLISHBV: 'incorporate-dutch-bv/incorporate-dutch-bv/new_product?locale=en',
  REGISTER: `${API}api/member_auth`,
  LOGOUT: `${API}api/member_auth/sign_out`,
  REFRESH: `${API}api/member_auth/validate_token`,
  GOOGLEOAUTH: `${API}api/member_auth/google_oauth2/?ligo_flow=api_flow&auth_origin_url=https://`,
  LINKEDINOAUTH: `${API}api/member_auth/linkedin/?ligo_flow=api_flow&auth_origin_url=https://`,
  CONFIRMATION: 'https://ligobv.netlify.app/#/confirm/',
  RETRY_CONFIRMATION: `${API}api/member_auth/confirmation`,
  ME: 'me',
  RESET_PASSWORD: '#',
  PASSWORD_CONFIRM: '#',
  MEMBERSHIPS: 'upsell_package_types?notary_product_type_slug=',
  NOTARIES: 'notaries?service_slug=',
  BV_INCORPORATIONS: 'dutch_bv_incorporations',
  PREDEFINED_NOTARY: '/predefined_notary',
  PAYMENT: 'payments',
  BILLING: 'billings',
  LEGAL_CHECKUP_TYPES: 'legal_checkup_types',
  LEGAL_CHECKUP: 'legal_checkups',
  CALCULATION: 'calculations',
  PAGE_INFORMATION: `${API}public/page_informations`,
  COUNTRIES: 'countries',
  GTM_PAYMENT_DATA: (uuid: string) =>
    `${API}/${CUSTOMER_DASHBOARD_API}/payments/${uuid}/gtm_format/`,
  AUTHENTICATED_REDIRECTION: (redirect_url: string) => {
    return `${API}api_redirections?redirect_url=${redirect_url}`;
  },
  NOTARY_PRODUCT_EXTRA: (slug: string, key_name: string) => {
    return `notary_product_types/${slug}/notary_product_type_extras/${key_name}`;
  },
  ADYEN_PAYMENT: (uuid: string) => `/payments/${uuid}/adyen_sessions`
};
