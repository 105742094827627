




import { defineComponent } from '@vue/composition-api';
import lottie from 'lottie-web';

export default defineComponent({
  name: 'Lottie',
  props: {
    options: {
      type: Object,
      required: true
    },
    height: String,
    width: String
  },
  data() {
    return {
      style: {
        width: this.width ? `${this.width}` : '100%',
        height: this.height ? `${this.height}` : '100%',
        overflow: 'hidden',
        margin: '0 auto'
      }
    };
  },
  mounted() {
    const anim = lottie.loadAnimation({
      container: this.$refs.lottieContainer,
      renderer: this.options.render,
      loop: this.options.loop,
      autoplay: this.options.autoplay,
      animationData: this.options.animationData,
      rendererSettings: this.options.rendererSettings
    });
    anim.addEventListener('complete', () => {
      this.$emit('complete');
    });
    this.$emit('created', anim);
  }
});
