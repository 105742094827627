import { LocalResource, NotaryProductResource } from '@ligo/bv-flow/store';
import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '../../resources';

export interface PaymentType {
  name: string;
  street: string;
  house_number: string;
  zip: string;
  city: string;
  vat_number: string;
  phone_number: string;
}

export const PaymentResourceDefinition: LocalResource<PaymentType> = {
  url: 'payments',
  locale: 'billing',
  fieldsDescriptions: {
    name: {},
    street: {},
    house_number: {},
    zip: {},
    city: {},
    vat_number: { rules: [] },
    phone_number: { type: 'phone', placeholder: 'empty', defaultValue: '' }
  },
  async saveToAPI(model: NotaryProductResource<PaymentType>) {
    const fields = model.fieldData();
    const request = RESOURCES.BILLING;
    await ApiService.patch(request, fields);
  }
};

export const paymentResource = (locale: string) => ({
  payment: new NotaryProductResource(
    PaymentResourceDefinition,
    'payment',
    locale
  )
});
