import { AnbiStichtingTypes } from '../definitions/anbi-stichting';
import { LocalResource, NotaryProductResource } from '@ligo/bv-flow/store';
import { buildRule } from '@ligo/shared/mvc';
import {
  commonProductFields,
  sharedEmployeeFields
} from '../../shared/commonProductFields';
import { visible_if_true } from '../../shared/conditions';

export const bvOprichten: LocalResource<AnbiStichtingTypes> = {
  url: '',
  locale: 'main',
  fieldsDescriptions: {
    ...commonProductFields,
    ...sharedEmployeeFields,
    upsell_package_type_product_ids: { type: 'multi-select', hidden: true },
    company_name: {},
    company_email: {
      filter: (value: string) => {
        return value && value.toLowerCase();
      },
      placeholder: 'empty',
      rules: [
        buildRule('email', 'validations.email_format'),
        buildRule('required', 'validations.required_generic')
      ]
    },
    phone: { placeholder: 'empty', type: 'phone' },
    website: { placeholder: 'empty' },
    realizing_activities: {},
    president: {},
    secretary: {},
    treasurer: {},
    extra_shareholders: { type: 'bool-options' },
    remaining_roles: {
      dependency_dcl: {
        fieldParam: 'extra_shareholders',
        rule: visible_if_true
      }
    },
    reappointed: {
      type: 'number',
      mask: 'integer'
    },
    term_managing: {
      type: 'number',
      mask: 'integer'
    }
  }
};

export function useMainResource() {
  return {
    main: new NotaryProductResource(
      bvOprichten,
      'main',
      'anbi_stichting_oprichten'
    )
  };
}
