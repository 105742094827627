

















































































































import { Contract } from '@ligo/dashboard/customer/store';
import Reminder from './Reminder.vue';
import ReminderListModal from './ReminderListModal.vue';

import {
  computed,
  defineComponent,
  onMounted,
  PropType
} from '@vue/composition-api';
import CreateModal from './modals/CreateModal.vue';
import UpdateModal from './modals/UpdateModal.vue';
import { useReminderManagerConfig } from './modals/reminder-modals-config.hook';
import { useStore } from '../../composables';

export default defineComponent({
  name: 'ReminderList',
  components: {
    Reminder,
    ReminderListModal,
    CreateModal,
    UpdateModal
  },
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true
    }
  },
  setup(props) {
    const {
      loadReminders,
      loadingReminders,
      reminderList,
      completeReminderList,
      showCreateDialog,
      showListDialog,
      showUpdateDoalog,
      selectedReminder,
      onShowCreateDialog,
      onCloseCreateDialog,
      onCreateReminder,
      onShowUpdateDialog,
      onCloseUpdateDialog,
      onUpdateReminder,
      onToggleCompleteReminder,
      onDeleteReminder
    } = useReminderManagerConfig();

    function onWrappDeleteReminder(eventId: number) {
      onDeleteReminder(props.contract.uuid, eventId);
    }

    onMounted(() => {
      loadReminders(props.contract.uuid);
    });

    const store = useStore();

    const currMembershipTypeLevel = computed(
      () => store.getters['authentication/getMembershipTypeLevel']
    );
    // TODO: Uncomment the following lines when the ContractReminders Feature is not for free
    // const noMembership = computed(
    //   () => currMembershipTypeLevel.value === MembershipTypeLevel.FREE
    // );
    const noMembership = computed(() => false);
    // TODO: Clean the rest of the component when this change is definitive

    return {
      showListDialog,
      completeReminderList,
      loadingReminders,
      reminderList,
      showCreateDialog,
      showUpdateDoalog,
      selectedReminder,
      noMembership,
      currMembershipTypeLevel,
      onToggleCompleteReminder,
      onShowCreateDialog,
      onCloseCreateDialog,
      onCreateReminder,
      onShowUpdateDialog,
      onCloseUpdateDialog,
      onUpdateReminder,
      onDeleteReminder,
      onWrappDeleteReminder
    };
  }
});
