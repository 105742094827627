export default {
  dashboard: 'Dashboard',
  account: 'Plan & Billing',
  settings: 'Account',
  upgrade: 'Upgrade and save',
  logout: 'Sign out',
  discounts: 'Business services',
  discounts_subtitle:
    'Arrange the services needed to run and protect your business from here',
  help: 'Get help from our team',
  old_dashboard: 'Old Dashboard',
  contracts_title: 'Contracts',
  contracts_subtitle: 'Create, sign and store legal documents',
  templates_title: 'Contract Library',
  templates_subtitle: 'Access 100+ contract templates to run and scale your BV',
  ask_lawyer_title: 'Ask a lawyer',
  ask_lawyer_subtitle:
    'Find a lawyer from our curated network and ask your questions',
  unlimited_templates: 'Unlimited contract templates',
  unlimited_signatures: 'Unlimited signatures',
  signatures_used: '{used} out of {total} signatures used',
  templates_used: '{used} out of {total} contract templates used',
  upgrade_notification: {
    home:
      'Hi {name}! Currently you have a free account. Upgrade to get access to all benefits.',
    msg: 'Hi {name}!',
    used_all: 'You are currently on a free tier account',
    used_templates:
      'you used all the contract templates that are included in your {plan} plan.',
    used_signatures:
      'you used all the digital signatures that are included in your {plan } plan.',
    all_link: 'Upgrade now to unlock all the benefits of Ligo!',
    signatures_link: 'Upgrade now to keep signing documents',
    templates_link: ' Upgrade now to access more contracts'
  },
  upgrade_section: {
    link: 'Upgrade',
    content: 'All legal matters of your business arranged digitally'
  },
  incorporations: {
    title: 'Incorporations',
    subtitle: 'Start BV, a personal holding or a foundation'
  },
  modifications: {
    title: 'Modifications',
    subtitle: 'Transfer of shares and amendment of the articles of association'
  },
  contract_reminders: 'Tasks',
  home: 'Home',
  documents_library: 'Templates',
  dga_services: 'DGA services',
  tax_services: 'Bookkeeping',
  signature_home: 'eSignatures',
  new_navigation_item: 'New',
  banking: 'Banking',
  lawyers: 'Ask a lawyer',
  lawyers_subtitle:
    'Find a lawyer from our curated network and ask your questions'
};
