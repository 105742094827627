import Vue from 'vue';
import VueSimplemde from 'vue-simplemde';

const globalComponents = [VueSimplemde];

export function globalInstall() {
  globalComponents.forEach((component) => {
    Vue.component(component.name, component);
  });
}
