import { ApiService, createGetService } from '@ligo/shared/utils';
import { ChangeMembershipDescription } from '../models/membership';
import { RESOURCES } from '../resources';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { MembershipType } from '@ligo/shared/auth';

const STORAGE_KEY = 'selected-plan-id';

export const MembershipService = {
  ...createGetService<MembershipType>(RESOURCES.MEMBERSHIPS),

  changeMembership(membershipId: number) {
    return ApiService.post<
      { membership_type_id: number },
      { payment: { uuid: string }; switch_type: ChangeMembershipDescription }
    >(
      RESOURCES.MEMBERSHIP_CHANGE,
      {
        membership_type_id: membershipId
      },
      false
      // { 'Company-Id': localStorage.getItem('Company-id') }
    );
  },
  cancelMembership() {
    return ApiService.post(RESOURCES.MEMBERSHIP_CANCEL, {}, false, {
      // 'Company-Id': localStorage.getItem('Company-id')
    });
  },
  saveSelection(membershipTypeId: string) {
    localStorage.setItem(STORAGE_KEY, membershipTypeId);
  },
  async createAndRedirect(membershipType: string) {
    const response = await this.changeMembership(membershipType);
    if (response.data.payment) {
      console.log('Back from Response');
      const router = Vue['Router'] as VueRouter;
      router.push({
        name: 'Account',
        query: {
          paymentUuid: response.data.payment.uuid
        }
      });
      return true;
    }
  },
  async checkAndCreateFromStorage() {
    const membershipTypeId = localStorage.getItem(STORAGE_KEY);
    if (membershipTypeId) {
      const redirected = await this.createAndRedirect(membershipTypeId);
      this.clearSelection();
      return redirected;
    }
  },
  clearSelection() {
    localStorage.removeItem(STORAGE_KEY);
  }
};
