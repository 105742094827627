import { ApiService, Dictionary } from '@ligo/shared/utils';
import { RESOURCES } from '../resources';
import {
  Contract,
  LiteContract,
  ContractType,
  ContractCategory
} from '../models/contracts';
import { ReminderObject, Event } from '../models/events';
import { AxiosPromise } from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';

const STORAGE_KEY = 'claim-contract-uuid';

export const ContractService = {
  getById(id: string) {
    return ApiService.get<Contract>(`${RESOURCES.CONTRACTS + id}/`);
  },

  list(query: Dictionary = {}) {
    return ApiService.get<LiteContract[]>(RESOURCES.CONTRACTS, query);
  },

  async update(uuid: string, data: Dictionary) {
    return await ApiService.patch(RESOURCES.CONTRACTS + uuid, data);
  },

  async create(data) {
    return await ApiService.post(RESOURCES.CONTRACTS, data, true);
  },

  templates(params?: Dictionary): AxiosPromise<ContractType[]> {
    return ApiService.get(RESOURCES.CONTRACT_TYPES, params);
  },

  templatePreview(slug: string) {
    return ApiService.get(RESOURCES.CONTRACT_TYPE(slug));
  },

  delete: async function (id: string): Promise<void> {
    await ApiService.delete(RESOURCES.CONTRACTS + id);
  },

  async copy(id: string, name: string) {
    return ApiService.post(RESOURCES.CLONE_CONTRACT(id), { name });
  },

  async createFromTemplate(id: number): Promise<Contract> {
    const response = await ApiService.post<Contract>(
      RESOURCES.CREATE_CONTRACT(id)
    );
    return response.data;
  },

  async signRequest(uuid: string) {
    return (await ApiService.post<Contract>(RESOURCES.CONTRACT_SIGNING(uuid)))
      .data;
  },

  async revokeSigning(uuid: string) {
    await ApiService.delete(RESOURCES.CONTRACT_SIGNING(uuid));
  },

  async getPayment(uuid: string) {
    return (
      await ApiService.post<Contract>(RESOURCES.CREATE_CONTRACT_PAYMENT(uuid))
    ).data;
  },

  async payWithCredit(uuid: string) {
    return await ApiService.patch(RESOURCES.CONTRACT_CREDIT(uuid), {});
  },

  categories(): AxiosPromise<ContractCategory[]> {
    return ApiService.get(RESOURCES.CONTRACT_CATEGORIES);
  },

  async uploadContract(id: string, data: FormData) {
    await ApiService.patch(RESOURCES.UPLOAD_CONTRACT(id), data);
  },

  reminders(uuid: string): AxiosPromise<Event[]> {
    return ApiService.get(RESOURCES.CONTRACT_EVENTS(uuid));
  },

  async createReminder(uuid: string, data: ReminderObject) {
    await ApiService.post(RESOURCES.CONTRACT_EVENTS(uuid), data);
  },
  saveSelection(contractUuid: string) {
    localStorage.setItem(STORAGE_KEY, contractUuid);
  },
  async claimContract(contractUuid: string) {
    const response = await ApiService.post<Contract>(
      RESOURCES.CLAIM_CONTRACT(contractUuid)
    );
    if (response.data) {
      const router = Vue['Router'] as VueRouter;
      router.push({ name: 'ActiveContracts' });
      return true;
    }
  },
  async checkAndClaimFromStorage() {
    const contractUuid = localStorage.getItem(STORAGE_KEY);
    if (contractUuid) {
      const redirected = await this.claimContract(contractUuid);
      this.clearSelection();
      return redirected;
    }
  },
  async contractStats() {
    return ApiService.get<
      Array<{ badge_status: string; status: string; count: number }>
    >(RESOURCES.CONTRACT_STATS);
  },
  clearSelection() {
    localStorage.removeItem(STORAGE_KEY);
  }
};
