import {
  NotaryProduct,
  NotaryProductResource,
  Step
} from '@ligo/bv-flow/store';
import { ApiService } from '@ligo/shared/utils';
import { StichtingResource } from './definitions/stichting';
import { useMainResource } from './resources/main';
import { shareholderResources } from './resources/shareholders';
import { paymentResource, ShareholderType } from '../../share';
import { getSteps, addBVtoNewCompanies } from '../../tools';
import { acceptShareholder, NotaryProductSlug } from '../../product_structure';

const steps: Array<Step> = [
  {
    name: 'stichting.company_name.name',
    completed: false
  },
  {
    name: 'stichting.shareholder_information.name',
    parts: 1,
    currentPart: 0,
    completed: false
  },
  {
    name: 'stichting.holding_upsell.name',
    parts: 2,
    currentPart: 0,
    completed: false
  },
  {
    name: 'stichting.preview_documents.name',
    completed: false
  }
];
interface StichtingAPIResponse {
  product_data: StichtingResource;
  upsell_package: any;
}

const url = 'stichting/';
const path = 'stichting';
const locale = 'stichting';
const typeId = 5;
const slug = NotaryProductSlug.STICHTING_OPRICHTEN;
const finalStep = 3;

const SHAREHOLDER_PREFIX = 'p';
const SHAREHOLDER_NAME_LENGTH = 2;

const saveToAPI = async (product: NotaryProduct, sendStep = true) => {
  const data = product.resources['main'].fieldData();
  // If there is a resource with a 2 letter name that start's with a p, this will assume it's a shareholder
  Object.keys(product.resources).forEach((x) => {
    if (
      x.length == SHAREHOLDER_NAME_LENGTH &&
      x.startsWith(SHAREHOLDER_PREFIX) &&
      acceptShareholder(product.resources[x])
    ) {
      data[x] = addBVtoNewCompanies(product.resources[x].fieldData());
    }
  });
  const nextStep = sendStep ? product.getStepIncrement() : 0;
  data['step'] = product.step + nextStep;
  data['temporal_step'] = product.temporalStep + nextStep;
  data['substep'] = product.substep;
  const request = product.url + product.uuid;
  console.log('PayLoad', data);
  try {
    await ApiService.patch(request, data);
  } catch (error) {
    console.log('PATCH ERROR', error);
  }
};

const loadFromAPI = async (product: NotaryProduct) => {
  if (product.uuid) {
    const response = await ApiService.get<StichtingAPIResponse>(
      product.url + product.uuid
    );
    console.log('GET', response);
    product.upsellPackageData = response.data.upsell_package;
    const { step, temporalStep, substep } = getSteps(
      product.uuid,
      response.data.product_data
    );
    product.step = step;
    product.temporalStep = temporalStep;
    product.substep = substep;
    product.updateFromJSON(response.data);
  }
};

export const useBV = (uuid?: string) => {
  const resources = {
    ...useMainResource(),
    ...shareholderResources(),
    ...paymentResource(locale)
  };
  return new NotaryProduct(
    resources,
    locale,
    url,
    path,
    saveToAPI,
    loadFromAPI,
    'stichting-handler',
    steps,
    typeId,
    slug,
    finalStep,
    uuid,
    false
  );
};
