export enum ResponseType {
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface ResponseDialogModel {
  title?: string;
  message?: string;
  btnLabel?: string;
  image?: string;
  bodyPadding?: string;
}

export enum PaymentObject {
  MEMBERSHIP = 'membership',
  CONTRACT = 'contract',
  NOTARY_PRODUCT = 'notary_product'
}
