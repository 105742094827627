import { onBeforeUnmount, ref, Ref } from '@vue/composition-api';
import AsyncLock from 'async-lock';
import { AxiosResponse } from 'axios';
import Vue from 'vue';

const LOCK = new AsyncLock();
const ASSIGN_LOCK_ID = 'lock-1';

function setLock(id: string, targetFunction: () => void) {
  LOCK.acquire(id, function (done) {
    targetFunction();
    done();
  }).catch((err) => {
    console.error(' There was an error: ', err);
  });
}

export function initWebSocket<T, P extends Promise<AxiosResponse<T>>>(
  promiseFactory: () => P = () =>
    new Promise((resolve) => {
      resolve(null);
    }) as P,
  defaultState: T,
  url: string,
  channelIdentifier: string,
  assignFunction = (state: Ref<T>, response: { data: T }) => {
    state.value = response.data;
  },
  loadOnConnect = true
) {
  const WS = new WebSocket(url);
  const state = ref(defaultState) as Ref<T>;
  const loading = ref(true);

  function onConnect() {
    WS.send(
      JSON.stringify({
        command: 'subscribe',
        identifier: channelIdentifier
      })
    );
    if (loadOnConnect) load();
  }

  function onUpdate(event) {
    const parsedData = JSON.parse(event.data);
    if (parsedData.identifier != channelIdentifier || parsedData.type) return;
    try {
      loading.value = true;
      setLock(ASSIGN_LOCK_ID, () => {
        assignFunction(state, { data: parsedData.message });
      });
    } catch (e) {
      console.error(e);
    } finally {
      Vue.nextTick(() => {
        loading.value = false;
      });
    }
  }

  function load(): void {
    loading.value = true;
    promiseFactory()
      .then((response) => {
        setLock(ASSIGN_LOCK_ID, () => {
          assignFunction(state, response);
        });
      })
      .catch((e) => {
        console.error('There was an error retrieving data WebSocket : ', e);
      })
      .finally(() => {
        Vue.nextTick(() => {
          loading.value = false;
        });
      });
  }

  function onClose(error) {
    console.log(
      'Socket is closed. Reconnect will be attempted in 1 second.',
      error.reason
    );
    setTimeout(() => {
      onConnect();
    }, 1000);
  }

  WS.onopen = onConnect;
  WS.onmessage = onUpdate;
  WS.onclose = onClose;

  onBeforeUnmount(() => WS.close());

  return { state, loading, load };
}
