
























import { defineComponent, PropType } from '@vue/composition-api';
import { ActionBtn } from '@ligo/shared/components';

export default defineComponent({
  name: 'AlertBanner',
  props: {
    text: String,
    icon: {
      type: String,
      default: 'far fa-circle-info'
    },
    color: {
      type: String,
      default: 'purple-1'
    },
    textColor: {
      type: String,
      default: 'purple'
    },
    paddingY: {
      type: String,
      default: 'md'
    },
    fontClass: {
      type: String,
      default: 'font-16 w-400'
    },
    applyI18n: {
      type: Boolean,
      default: true
    },
    actionBtn: {
      type: Object as PropType<ActionBtn>
    }
  },
  computed: {
    iconSize() {
      if (this.$q.screen.gt.md) return 'xl';
      if (this.$q.screen.gt.sm) return 'lg';
      if (this.$q.screen.gt.xs) return 'md';
      return 'sm';
    }
  }
});
