interface PlanJSON {
  background?: boolean;
  popular?: boolean;
  price_color?: string;
  cta?: string;
  hideCta?: boolean;
  textWhenNoCta?: string;
  title?: string;
  subtitle?: string;
  features?: Feature[];
  decimal?: boolean;
  information?: string[];
}

export interface Plan {
  id: number;
  title: string;
  cost: string;
  billing_interval: string;
  dark?: boolean;
  active?: boolean;
  slug: string;
  aditionalData?: {
    en: PlanJSON;
    nl: PlanJSON;
  };
}

export interface Feature {
  text: string;
  tip?: string;
}

export interface Membership {
  billing_interval: string;
  id: number;
  level: string;
  name: string;
  price: string;
  slug: MembershipSlug;
  uuid: string;
  description: string;
}

// This is not the complete list of memberships, but only the ones that are used in the dashboard.
export enum MembershipSlug {
  FREE = 'free',
  PROFESIONAL_MONTHLY = 'professional_monthly',
  BASIC_YEARLY = 'basic_yearly',
  BASIC_MONTHYLY_LEGACY = 'basic_monthly_legacy',
  STARTER_MONTHLY_LEGACY = 'starter_monthly_legacy',
  STARTER_YEARLY_LEGACY = 'starter_yearly_legacy',
  COMPLETE_MONTHLY_LEGACY = 'complete_monthly_legacy',
  LIGO_MEMBERSHIP_MONTHLY = 'ligo_membership_monthly'
}

export enum ChangeMembershipDescription {
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
  ENTERPRISE = 'enterprise'
}
