




























import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { BaseModal } from './';
import { useModalCtas } from './base';

const i18nContent = {
  title: 'e_signature.sign_document.modals.checkbox.title',
  yes: 'e_signature.sign_document.modals.checkbox.yes',
  no: 'e_signature.sign_document.modals.checkbox.no'
};

export default defineComponent({
  name: 'CheckboxModal',
  components: { BaseModal },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const yes = ref(false);
    const no = ref(false);

    const { saveBtn, ctasList: ctas } = useModalCtas();
    saveBtn.value.disabled = true;

    const result = computed(() => {
      if (yes.value) return true;
      return false;
    });

    watch([yes, no], () => {
      saveBtn.value.disabled = !yes.value && !no.value;
    });

    return { ctas, i18nContent, yes, no, result };
  }
});
