export default {
  labels: {
    firstname: 'Voornaam',
    lastname: 'Achternaam',
    phone: 'Telefoonnummer',
    email: 'E-mailadres',
    password: 'Wachtwoord',
    password_confirmation: 'Bevestig jouw wachtwoord'
  },
  placeholders: {
    firstname: 'Voornaam',
    lastname: 'Achternaam',
    phone: 'Telefoonnummer',
    email: 'Vul e-mailadres in',
    password: 'Voer uw wachtwoord in',
    password_confirmation: 'Bevestig uw wachtwoord'
  },
  tooltips: {
    email:
      'Geen tijd om vragenlijst af te maken? We sturen een link naar jouw e-mailadres. Via deze link kun je op elk gewenst moment verder gaan.',
    phone:
      'De notaris neemt contact met je op om de details van je oprichting te bespreken.'
  }
};
