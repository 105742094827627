import { MutationTree } from 'vuex';
import { pageInformationState } from './state';

const mutation: MutationTree<pageInformationState> = {
  setData(state, { slug, data }) {
    state.pages = { ...state.pages, [slug]: data };
  },
  setLocalePrefix(state, { localePrefix }) {
    state.localePrefix = localePrefix;
  }
};

export default mutation;
