import { GetterTree } from 'vuex';
import { accessWithDot } from '../../tools/dotNotation';
import { pageInformationState } from './state';
import VueI18n from 'vue-i18n';
import { nully } from '@ligo/shared/utils';

function handleResult(result, key, slug) {
  if (typeof result === 'string') {
    return result;
  }
  if (result) {
    return result;
  } else {
    console.warn(
      `\[page information\] The key ${key} for ${slug} could not be translated. The value of the key will be used instead`
    );
    return key;
  }
}

function separateSlug(prefix: string, key: string) {
  const [slug, ...restArray] = prefix.split('.');
  const rest = restArray.join('.');
  const resultingKey = rest.length > 0 ? `${rest}.${key}` : key;
  return { slug, resultingKey };
}

function getLocale(locale) {
  return locale == 'en-us' ? 'en' : 'nl';
}

export const gettersFactory = <StateInterface>(
  i18n: VueI18n
): GetterTree<pageInformationState, StateInterface> => ({
  accessPageSpecific: (state) => (slug: string) => (key: string) => {
    const locale = getLocale(i18n.locale);
    if (!state.pages[slug]) return key;
    const result = accessWithDot(state.pages[slug][locale], key);
    return handleResult(result, key, slug);
  },
  existsPageSpecific: (state) => (slug: string) => (key: string) => {
    const locale = getLocale(i18n.locale);
    if (!state.pages[slug]) return false;
    const result = accessWithDot(state.pages[slug][locale], key);
    return !nully(result);
  },
  accessWithPrefix: (state) => (key: string) => {
    const { slug, resultingKey } = separateSlug(state.localePrefix, key);
    const locale = getLocale(i18n.locale);
    const result = accessWithDot(state.pages[slug][locale], resultingKey);
    return handleResult(result, key, slug);
  },
  existsWithPrefix: (state) => (key: string) => {
    const { slug, resultingKey } = separateSlug(state.localePrefix, key);
    const locale = getLocale(i18n.locale);
    const result = accessWithDot(state.pages[slug][locale], resultingKey);
    return !nully(result);
  },
  isLoadPageInformation: (state) => (slug: string) => {
    return !nully(state.pages[slug]);
  },
  config: (state) => (slug: string) => {
    return state.pages[slug]['config'];
  }
});
