import { Dictionary } from '@ligo/shared/utils';
import { ComputedRef, Ref } from '@vue/composition-api';
import {
  BasicFieldDcl,
  BasicField,
  NumericField,
  CheckboxField,
  SelectableField,
  TextField,
  DateTimeField,
  BasicFieldInterface,
  GConstructor,
  DateTimeFieldDcl,
  SelectableFieldDcl,
  TextFieldDcl,
  NumericFieldDcl,
  buildRule,
  CheckboxFieldDcl
} from '@ligo/shared/mvc';

export interface FormRule {
  fieldParam: string;
  rule: (Field: Ref<BasicField<any>>) => ComputedRef<(v: any) => boolean>;
  message: string;
}

export interface Dependency {
  fieldParam: string;
  rule: (Field: Ref<BasicField<any>>) => ComputedRef<() => boolean>;
}
export interface ProductBasicFieldDcl<T> extends BasicFieldDcl<T> {
  col_class?: string;
  form_validation?: FormRule;
  external_validation?: any;
  external_validation_message?: string;
  dependency_dcl?: Dependency;
  dependency?: any;
}

export type ProductDcl<T> = ProductBasicFieldDcl<T> &
  (
    | SelectableFieldDcl<T>
    | DateTimeFieldDcl
    | TextFieldDcl
    | NumericFieldDcl
    | CheckboxFieldDcl
  );

export class ProductBasicField<T> extends BasicField<T> {
  col_class?: string;
  form_validation?: FormRule;
  external_validation?: any;
  external_validation_message?: string;
  dependency_dcl?: Dependency;
  dependency?: any;

  initializeLocaleValues() {
    super.initializeLocaleValues();
    this.rules = [buildRule('required', 'validations.required_generic')];
  }
}

export class ProductTextField extends TextField(ProductBasicField)<string> {
  initializeLocaleValues() {
    super.initializeLocaleValues();
    this.rules = [buildRule('required', 'validations.required_generic')];
  }
}

export class ProductNumericField extends NumericField(
  ProductBasicField
)<number> {}

export class ProductCheckboxField extends CheckboxField(
  ProductBasicField
)<boolean> {}

export class ProductDateTimeField extends DateTimeField(
  ProductBasicField
)<string> {}

export class ProductSelectableField extends SelectableField(
  ProductBasicField
)<any> {
  initializeLocaleValues() {
    super.initializeLocaleValues();
    this.rules = [buildRule('required', 'validations.required_generic')];
  }
}

export type ProductField =
  | ProductBasicField<any>
  | ProductTextField
  | ProductNumericField
  | ProductCheckboxField
  | ProductDateTimeField
  | ProductSelectableField;

export const ProductFieldClassMap: Dictionary<
  GConstructor<BasicFieldInterface>
> = {
  text: ProductTextField,
  phone: ProductTextField,
  bool: ProductTextField,
  textarea: ProductTextField,
  number: ProductNumericField,
  timepicker: ProductDateTimeField,
  datepicker: ProductDateTimeField,
  'bool-options': ProductSelectableField,
  select: ProductSelectableField,
  'multi-select': ProductSelectableField,
  options: ProductSelectableField,
  password: ProductTextField,
  checkbox: ProductCheckboxField
};
