import Vue from 'vue';

export type PageInformationSlug =
  | 'calculator'
  | 'contract-upsell-step'
  | 'Bv-Layout'
  | 'extra_question'
  | 'holding-upsell-step'
  | 'extra-changes'
  | 'dga-step'
  | 'customer-home'
  | 'ligo-assistant'
  | 'preview-documents'
  | 'share-step'
  | 'onboarding-register'
  | 'ligo-plans-page'
  | 'dga-services'
  | 'tax-services'
  | 'signature-page'
  | 'incorporation-page'
  | 'modifications-page'
  | 'banking';

export const usePageInformation = (slug: PageInformationSlug) => {
  const isReady = () => {
    return (Vue['Store'].getters['pageInformation/isLoadPageInformation'] as (
      slug: string
    ) => boolean)(slug);
  };

  const load = () => {
    return Vue['Store'].dispatch('pageInformation/loadPage', slug);
  };

  const te = (key: string) => {
    return !!Vue['Store'].getters['pageInformation/existsPageSpecific'](slug)(
      key
    );
  };

  const t = <T = string>(key: string) => {
    return Vue['Store'].getters['pageInformation/accessPageSpecific'](slug)(
      key
    ) as T;
  };

  const tc = (key: string, count: number) => {
    const options = Vue['Store'].getters['pageInformation/accessPageSpecific'](
      slug
    )(key).split('|');

    const times = Math.max(0, count);
    const length = options.length;
    const internationalized =
      times < length ? options[times] : options[length - 1];
    return length === 3 && times > 1
      ? `${times} ${internationalized}`
      : internationalized;
  };

  const useConfig = () => {
    return Vue['Store'].getters['pageInformation/config'](slug);
  };

  const get = <T = string>(key: string, defaultValue: T) => {
    if (!te(key)) return defaultValue;
    return t<T>(key);
  };

  const getNested = (key: string) => {
    return {
      t: (nkey: string) => t(`${key}.${nkey}`),
      te: (nkey: string) => te(`${key}.${nkey}`),
      get<T = string>(nkey: string, defaultValue: T) {
        return get(`${key}.${nkey}`, defaultValue);
      },
      getNested: (nkey: string) => getNested(`${key}.${nkey}`)
    };
  };

  return {
    slug,
    useConfig,
    isReady,
    load,
    getNested,
    t,
    te,
    tc,
    get
  };
};
