import { binarySearch } from '../algorithms';

const FONT_HEIGHT_ERROR = 4;

export function getCanvasFont(context: CanvasRenderingContext2D) {
  const [fontSize, fontType] = context.font.split(' ');
  return { fontSize, fontType };
}

export function fitTextToWidth(canvas: HTMLCanvasElement, text: string) {
  const context = canvas.getContext('2d');
  const width = canvas.width;
  const height = canvas.height;
  const maxFontSize = Math.min(width, height);
  const { fontType } = getCanvasFont(context);
  function checkTextFit(fontsize: number) {
    context.font = `${fontsize}px ${fontType}`;
    const metrics = context.measureText(text);
    const currentHeight =
      metrics.actualBoundingBoxAscent +
      metrics.actualBoundingBoxDescent +
      FONT_HEIGHT_ERROR;
    const currentWidth = metrics.width;
    return currentWidth < width && currentHeight < height;
  }
  return binarySearch(1, maxFontSize, checkTextFit);
}

export function fitTextToHeight(canvas: HTMLCanvasElement, text: string) {
  const context = canvas.getContext('2d');
  const height = canvas.height;
  const maxFontSize = height;
  const { fontType } = getCanvasFont(context);

  function checkTextFit(fontsize: number) {
    context.font = `${fontsize}px ${fontType}`;
    const metrics = context.measureText(text);
    const currentHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent + FONT_HEIGHT_ERROR;
    return currentHeight < height;
  }

  return binarySearch(1, maxFontSize, checkTextFit);
}
