import { GetterTree } from 'vuex';
import { AuthState } from './state';

export const gettersFactory = <StateInterface>(): GetterTree<
  AuthState,
  StateInterface
> => ({
  loggedIn: (state) => {
    return state.accessToken ? true : false;
  },
  authenticationError: (state) => {
    return state.authenticationError;
  },
  authenticating: (state) => {
    return state.authenticating;
  },
  user: (state) => {
    return state.user;
  },
  username: (state) => {
    if (state.user) {
      return `${state.user.firstname} ${state.user.lastname}`;
    }
  },
  firstname: (state) => {
    if (state.user) {
      return state.user.firstname;
    }
  },
  language: (state) => {
    return state.user?.locale;
  },
  accessToken: (state) => {
    return state.accessToken;
  },
  getMembership: (state) => {
    return state.user?.membership;
  },
  getMembershipTypeLevel: (state) => {
    return state.user?.membership?.membership_type.level;
  },
  statusOfReceivedFreeContract: (state) => {
    return state.user?.did_receive_contract_for_free;
  },
  userEmail: (state) => {
    return state.user?.email;
  }
});
