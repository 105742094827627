import Vue from 'vue';

const compareFnSortingAfterSeparator = (
  a: { v: string; i: number },
  b: { v: string; i: number }
) => {
  if (a.i < 3 && b.i < 3) return a.i < b.i ? -1 : 1;
  if (a.i < 3) return -1;
  if (b.i < 3) return 1;
  return a.v < b.v ? -1 : 1;
};

const nationalities_dutch = [
  'Nederlands',
  'Brits',
  'Belgisch',
  '---',
  'Andorrees',
  'Antilliaans',
  'Argentijns',
  'Azerbeidzjaans',
  'Albanees',
  'Angolees',
  'Armeens',
  'Algerijn',
  'Anguilaans',
  'Australisch',
  'Amerikaans',
  'Antiguaans Barbudan',
  'Oostenrijks',
  'Bahamaans',
  'Batswaans',
  'Wit-Russisch',
  'Bermudiaans (Brits)',
  'Bruneiaans',
  'Burundees',
  'Bahreins',
  'Bhutanese',
  'Braziliaans',
  'Bulgaars',
  'Bangladesh',
  'Belizeaans',
  'Boliviaans',
  'Barbadiaans',
  'Bosnisch en Herzegovina',
  'Britse Maagdeneilanden',
  'Birmees (Myanmar)',
  'Chinees',
  'Congolees (DRC)',
  'Cubaans',
  'Curacaaan',
  'Tsjechisch',
  'Kameroen',
  'Centraal-Afrikaans',
  'Colombiaans',
  'Nieuw-Zeeland (Cookeilanden)',
  'Canadees',
  'Tsjadisch',
  'Comoren',
  'Costa Ricaans',
  'Kaapverdiaans',
  'Chileens',
  'Congolees (Congo)',
  'Kroatisch',
  'Cypriotisch',
  'Deens',
  'Djibouti',
  'Dominicaans',
  'Dominicaanse Republiek',
  'Oost-Timorees',
  'Engels',
  'Ethiopisch',
  'Ecuadoriaans',
  'Equatoriaal-Guinees',
  'Egyptisch',
  'Eritrese',
  'Emirati',
  'Ests',
  'Deens (Faeröer)',
  'Frans',
  'Fijisch',
  'Fins',
  'Gabonees (Libiaans)',
  'Grenadier',
  'guinees',
  'Gambiaan',
  'Ghanese',
  'Gibraltar (Brits overzee)',
  'Guamaanse',
  'Guyanese',
  'Georgisch',
  'Grieks',
  'Guatemalteeks',
  'Duits',
  'Groenlands (Deens)',
  ' Guinee-Bissauan',
  'Honduraan',
  'Hongkong (Chinees)',
  'Hongaars',
  'Iraaks',
  'IJslands',
  'Ivoriaans',
  'Indiaas',
  'Iers',
  'Indonesisch',
  'Israëlisch',
  'Italiaans',
  'Japans',
  'Kazachstan',
  'Kosovaars',
  'Keniaans',
  'Koeweit',
  'Kittitiaans (of Nevisiaans)',
  'Kirgizië',
  'Kiribati',
  'Laotiaans',
  'Libisch',
  'Lets',
  'Liechtenstein',
  'Libanees',
  'Litouws',
  'liberiaans',
  'Luxemburgs',
  'Macanees',
  'Maleisisch',
  'Marshallees (Marshalleilanden)',
  'Mauritiaans',
  'Mexicaans',
  'Mosotho',
  'Macedonisch',
  'maldivisch',
  'Mauritiaans',
  'Micronesiërs',
  'Montenegrijns',
  'Mozambikaans',
  'Malagassisch',
  'Moldavisch',
  'Montserrataans',
  'Marokkaans',
  'Malawiaan',
  'Maltees',
  'Mauritiaans',
  'Monegask',
  'Namibisch',
  'Nauruaans',
  'Nepalees',
  'Nicaraguaans',
  'Nigeriaans',
  'Noord-Iers',
  'Noors',
  'Nieuw-Zeeland',
  'Niue (Nieuw-Zeeland)',
  'Omanisch',
  'Papoea-Nieuw-Guinea',
  'Pools',
  'Palauaans',
  'Paraguayaan',
  'Portugees',
  'Pakistaans',
  'Palestijns',
  'Peruaanse',
  'Pitcairneilandbewoners (Brits)',
  'Puerto Ricaan',
  'Qatari',
  'Roemeens',
  'Russisch',
  'Rwandees',
  'Salvadores',
  'Saudi-Arabisch',
  'Seychellen',
  'Sloveens',
  'Zuid-Koreaans',
  'St. Helenisch (Brits)',
  'Surinaams',
  'Sammarinees (San Marino)',
  'Schots',
  'Sierra Leone',
  'Solomonseilander',
  'St. Lucian',
  'Swazi',
  'Samoaans',
  'Singaporeërs',
  'Somalisch',
  'Spaans',
  'Zweeds',
  'Santomaans',
  'Servisch',
  'Slowaaks',
  'Zuid-Afrikaans',
  'Sri Lankaans',
  'Soedanees',
  'Zwitsers',
  'Taiwanees',
  'Togolees',
  'Tunesiër',
  'Tuvaluaans',
  'Tadzjiekse',
  'Tongaans',
  'Turks',
  'Tanzaniaans',
  'Turkmeens',
  'Thais',
  'Tristanien',
  'Turks- en Caicoseilanden',
  'Oekraïens',
  'Uruguayaans',
  'Oezbeeks',
  'Vaticaans',
  'Vincentiaans',
  'Venezolaans',
  'Vietnamees',
  'Zambiaans',
  'Zimbabwaans'
]
  .map((v, i) => ({ v, i }))
  .sort(compareFnSortingAfterSeparator)
  .map((v) => v.v);

const nationalities_english = [
  'Dutch',
  'British',
  'Belgian',
  '---',
  'Andorran',
  'Antillean',
  'Argentinian',
  'Azerbaijani',
  'Albanian',
  'Angolan',
  'Armenian',
  'Algerian',
  'Anguillan',
  'Australian',
  'American',
  'Antiguan Barbudan',
  'Austrian',
  'Bahamian',
  'Batswana',
  'Belarusian',
  'Bermudian (British)',
  'Bruneian',
  'Burundian',
  'Bahraini',
  'Bhutanese',
  'Brazilian',
  'Bulgarian',
  'Bangladesh',
  'Belizean',
  'Bolivian',
  'Barbadian',
  'Bosnian and Herzegovinian',
  'British Virgin Islands',
  'Burmese (Myanmar)',
  'Chinese',
  'Congolese (DRC)',
  'Cuban',
  'Curaçaoan',
  'Czech',
  'Cameroonian',
  'Central African',
  'Colombian',
  'New-Zealand (Cook Islands)',
  'Canadian',
  'Chadian',
  'Comorian',
  'Costa Rican',
  'Cape Verdean',
  'Chilean',
  'Congolese (Congo)',
  'Croatian',
  'Cypriot',
  'Danish',
  'Djibouti',
  'Dominican',
  'Dominican Republic',
  'East Timorese',
  'English',
  'Ethiopian',
  'Ecuadorian',
  'Equatorial Guinean',
  'Egyptian',
  'Eritrean',
  'Emirati',
  'Estonian',
  'Danish (Faroe Islands)',
  'French',
  'Fijian',
  'Finnish',
  'Gabonese (Libian)',
  'Grenadian',
  'Guinean',
  'Gambian',
  'Ghanaian',
  'Gibraltar (British Overseas)',
  'Guamanian',
  'Guyanese',
  'Georgian',
  'Greek',
  'Guatemalan',
  'German',
  'Greenlandic (Danish)',
  'Guinea-Bissauan',
  'Honduran',
  'Hong Kong (Chinese)',
  'Hungarian',
  'Iraqi',
  'Icelandic',
  'Ivorian',
  'Indian',
  'Irish',
  'Indonesian',
  'Israeli',
  'Italian',
  'Japanese',
  'Kazakhstani',
  'Kosovan',
  'Kenyan',
  'Kuwaiti',
  'Kittitian (or Nevisian)',
  'Kyrgyzstani',
  'Kiribati',
  'Laotian',
  'Libyan',
  'Latvian',
  'Liechtenstein',
  'Lebanese',
  'Lithuanian',
  'Liberian',
  'Luxembourg',
  'Macanese',
  'Malaysian',
  'Marshallese (Marshall Islands)',
  'Mauritian',
  'Mexican',
  'Mosotho',
  'Macedonian',
  'Maldivian',
  'Mauritian',
  'Micronesian',
  'Montenegrin',
  'Mozambican',
  'Madagascan',
  'Moldovans',
  'Montserratian',
  'Moroccan',
  'Malawian',
  'Maltese',
  'Mauritian',
  'Monégasque',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'Nicaraguans',
  'Nigerian',
  'North Irish',
  'Norwegian',
  'New- Zealand',
  'Niue (New- Zealand)',
  'Omani',
  'Papua New Guinean',
  'Polish',
  'Palauan',
  'Paraguayan',
  'Portugese',
  'Pakistani',
  'Palestinian',
  'Peruvian',
  'Pitcairn Islanders (British)',
  'Puerto Rican',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Salvadorean',
  'Saudi Arabian',
  'Seychellois',
  'Slovenian',
  'South Korean',
  'St. Helenian (British)',
  'Surinamese',
  'Sammarinese (San-Marino)',
  'Scottish',
  'Sierra Leonean',
  'Solomon Islands',
  'St. Lucian',
  'Swazi',
  'Samoan',
  'Singaporean',
  'Somali',
  'Spanish',
  'Swedish',
  'Santomean',
  'Serbian',
  'Slovak',
  'South African',
  'Sri Lankan',
  'Sudanese',
  'Swiss',
  'Taiwanese',
  'Togolese',
  'Tunisian',
  'Tuvaluan',
  'Tajik',
  'Tongan',
  'Turkish',
  'Tanzanian',
  'Turkmen',
  'Thai',
  'Tristanian',
  'Turks and Caicos Islands (British Overseas)',
  'Ukrainian',
  'Uruguayan',
  'Uzbek',
  'Vatican',
  'Vincentian',
  'Venezuelan',
  'Vietnamese',
  'Zambian',
  'Zimbabwean'
]
  .map((v, i) => ({ v, i }))
  .sort(compareFnSortingAfterSeparator)
  .map((v) => v.v);

export const nationalities = () => {
  if (Vue['i18n'])
    return Vue['i18n'].locale == 'en-us'
      ? nationalities_english
      : nationalities_dutch;
  else return nationalities_dutch;
};
