export default {
  table: {
    columns: {
      service: 'Dienst',
      status: 'Status',
      year: 'Jaar',
      paid_on: 'Betaald op'
    },
    status: {
      paid: 'Betaald',
      open: 'Uitstaand'
    },
    actions: {
      pay: 'Betalen'
    },
    empty: {
      title: 'Je fiscale consults zullen hier getoond worden.',
      subtitle:
        'Krijg professioneel advies van onze fiscale experts en zorg dat je alle fiscale voordelen benut.'
    }
  }
};
