












































import {
  defineComponent,
  ref,
  Ref,
  PropType,
  watch
} from '@vue/composition-api';
import { DashboardDialog } from '@ligo/dashboard/components';
import { ApiService, notifyAsync } from '@ligo/shared/utils';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import {
  NotaryProductDocument,
  NotaryProductDocumentKind
} from '@ligo/dashboard/store';

export const MAX_SIZE = 15728640; // 15 MB
export const FORMATS = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'image/png',
  'image/jpg',
  'image/jpeg'
];

export default defineComponent({
  name: 'UploadDialog',
  components: { DashboardDialog },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    productId: {
      type: String
    },
    document: {
      type: Object as PropType<NotaryProductDocument>
    }
  },
  setup(props, { root, emit }) {
    const file: Ref<any> = ref();
    const form: Ref<any> = ref();
    const loading = ref(false);
    const name = ref('');

    const fileRules = [
      (value: any) => !!value || root.$t('documents.upload.required')
    ];

    async function onUpload() {
      form.value.validate().then((valid) => {
        if (valid) {
          loading.value = true;

          const formData = new FormData();
          formData.append('file', file.value);

          if (props.document) {
            formData.append('kind', NotaryProductDocumentKind.USER_UPLOADED);
            if (props.document.document_type) {
              formData.append('document_type', props.document.document_type);
            }

            notifyAsync(
              ApiService.patch(
                `${RESOURCES.NOTARY_PRODUCT_DOCUMENT(props.productId)}/${
                  props.document.uuid
                }`,
                formData
              ).then(() => {
                file.value = null;
                loading.value = false;
                emit('input');
                emit('refresh');
              }),
              root.$t('documents.upload.success'),
              root.$t('documents.upload.error')
            );
          } else {
            formData.append('name', name.value);
            formData.append('kind', NotaryProductDocumentKind.USER_UPLOADED);
            notifyAsync(
              ApiService.post(
                RESOURCES.NOTARY_PRODUCT_DOCUMENT(props.productId),
                formData
              ).then(() => {
                name.value = '';
                file.value = null;
                loading.value = false;
                emit('input');
                emit('refresh');
              }),
              root.$t('documents.upload.success'),
              root.$t('documents.upload.error')
            );
          }
        }
      });
    }

    function onInput() {
      file.value = null;
      emit('input');
    }

    function onRejected() {
      file.value = undefined;
      root['$q'].notify({
        type: 'negative',
        position: 'top',
        message: root['$t']('documents.upload.format').toString()
      });
    }

    watch(file, (value: Blob) => {
      if (value) {
        const extensionIncluded = FORMATS.includes(value.type);
        const bigFile = value.size > MAX_SIZE;
        if (!extensionIncluded || bigFile) {
          onRejected();
        }
      }
    });

    return { file, fileRules, loading, form, name, onUpload, onInput };
  }
});
