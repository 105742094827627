export const documentType = ['passport', 'driver_licence', 'identity_card'];

export const maritalStatus = ['unmarried', 'registered_partner', 'married'];

export const proprietorshipAction = ['keep', 'transfer', 'close'];

export const fundationRoleType = [
  'chairman',
  'secretary',
  'treasurer',
  'other'
];

export const representationType = [
  'independently_authorized_director',
  'jointly_authorized_director'
];

export const ubosValues = [1, 2, 3, 4];

export const functionKeys = [
  'authorized_director',
  'authorized_representative'
];

export interface BasicShareholder {
  // Common Fields to all shareholders
  type: 0 | 1 | 2;
  prefix: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  birthplace: string;
  email: string;
  phone_number: string;
  document_type: typeof documentType[number];
  nationality: string[];
  document_number: string;
  issue_date: string;
  expiration_date: string;
  place_of_issue: string;
  bsn: string;
  bsn_not_present: boolean;
  lives_in_netherlands: boolean;
  english_proficient: boolean;
  become_director: boolean;
  foundation_role: typeof fundationRoleType[number];
  shares: number;
  dga_plan: boolean;
  is_natural_person: boolean;
  is_new_company: boolean;
  is_existing_company: boolean;
  marital_status: typeof maritalStatus[number];
  country_of_birth: string;
  residence_country: string;
  has_proprietorship: boolean;
  proprietorship_action: typeof proprietorshipAction[number];
  kvk_proprietorship: string;
}

interface NaturalPerson extends BasicShareholder {
  holding: boolean;
  holding_name: string;
  zip_code: string;
  location: string;
  address: string;
}

interface NewCompany extends BasicShareholder {
  company_name: string;
  function: typeof functionKeys[number];
  representation_type: typeof representationType[number];
  company_location: string;
  company_address: string;
  company_zipcode: string;
  holding: boolean;
  holding_name: string;
  incorporated_in_netherlands: boolean;
}

interface ExistingCompany extends BasicShareholder {
  company_name: string;
  function: string;
  representation_type: typeof representationType[number];
  company_location: string;
  company_address: string;
  company_country: string;
  address_equals_company_address: boolean;
  company_zipcode: string;
  chamber: string;
  director_authorized: boolean;
  zip_code: string;
  location: string;
  address: string;
  incorporated_in_netherlands: boolean;
  ubos: typeof ubosValues[number];
  ubo_kvk_registered?: boolean;
  ubo_kvk_registered_check?: boolean;
}

export type ShareholderType = NaturalPerson | NewCompany | ExistingCompany;
