import { useEnvironment } from '@ligo/netlify-functions';
import Vue from 'vue';

const { WEB_SOCKET_SERVER } = useEnvironment();

export function getBaseSocketURL(id: string, channelName: string) {
  const accessToken = Vue['Store'].state.authentication.accessToken;
  const companyId = Vue['Store'].getters['company/getCompanyId'];
  const authParams = `?company-id=${companyId}&access-token=${
    accessToken.access_token
  }&uid=${encodeURIComponent(accessToken.uid)}&client=${accessToken.client}`;
  const url = WEB_SOCKET_SERVER + authParams;
  const channelIdentifier = `{ "channel": \"Api::${channelName}\", \"id\": \"${id}\" }`;
  return { url, channelIdentifier };
}
