








































import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseDialog',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      required: true
    },
    button: {
      type: String
    },
    image: {
      type: String,
      default: '../images/grey_folder.svg'
    },
    text_with_i18n: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const updateValue = (val: boolean) => {
      val = null;
      emit('input', val);
    };
    const open = computed(() => {
      return props.value === false;
    });
    return { props, updateValue, open };
  }
});
