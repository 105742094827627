





























import { defineComponent, PropType, watch } from '@vue/composition-api';
import { setupSlider } from './fluid-card-slider.hooks';

export default defineComponent({
  name: 'FluidCardSlider',
  components: {},
  props: {
    items: {
      type: (Array as unknown) as PropType<any[]>,
      required: true
    },
    cardsPerSlide: {
      type: Number,
      default: 4
    },
    cyclic: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { root }) {
    const {
      height,
      loading,
      overflow,
      cards,
      outLeft,
      width,
      left,
      right,
      sliderContainer,
      cardsRef,
      reset
    } = setupSlider(props.items, props.cardsPerSlide, props.cyclic, root);

    watch(
      () => props.cardsPerSlide,
      (newVal) => {
        reset(newVal);
      }
    );

    return {
      height,
      loading,
      overflow,
      cards,
      outLeft,
      width,
      left,
      right,
      sliderContainer,
      cardsRef,
      reset
    };
  }
});
