














import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'TelephoneNumber',
  props: {
    onlyIcon: {
      type: Boolean,
      default: false
    },
    phoneLink: {
      type: String,
      default: 'tel:0203031043'
    },
    phoneText: {
      type: String,
      default: '020 303 1043'
    },
    desktopIcon: {
      type: String,
      default: 'img:/questionnaire/icons/icon_phone.svg'
    },
    mobileIcon: {
      type: String,
      default: 'img:/questionnaire/icons/rounded_icon_phone.svg'
    }
  }
});
