




































import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'InformationBanner',
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    img: {
      type: String
    },
    bgColor: {
      type: String,
      default: 'purple-3'
    },
    btnClass: {
      type: String
    }
  },
  data: () => ({ show: true })
});
