import { Dictionary, nully } from '@ligo/shared/utils';
import { Notify } from 'quasar';
import Vue from 'vue';
import { BasicFieldType } from './Field';
import { ResourceInterface, FieldSet, BasicForm } from './Form';

export type TypeData<T> = {
  [P in keyof T]?: T[P];
};

export interface ResourceConfig<T> {
  fieldsDescriptions?: FieldSet<T>;
  url?: string;
  slug?: string;
  locale?: string;
  loadOnCreate?: boolean;
}

export abstract class Resource<T> implements ResourceInterface<T> {
  id: string;
  url: string;
  locale: string;
  fields: FieldSet<T> = {};
  values: TypeData<T>;
  uuid?: string;

  constructor(
    resourceConfig: ResourceConfig<T>,
    id: string,
    locale: string,
    uuid?: string
  ) {
    this.id = id;
    this.locale = locale;
    this.url = resourceConfig.url;
    if (uuid) {
      this.uuid = uuid;
      if (resourceConfig.loadOnCreate) {
        void this.load();
      }
    }
  }

  abstract performSave(config): Promise<void>;
  abstract performLoad(): Promise<void>;
  abstract linkToContinue(): string;
  abstract getFields(id: string): FieldSet<any>;
  abstract getValues(id: string): TypeData<any>;
  abstract getLocale(id: string): string;

  async save(send = true, config) {
    if (send) await this.performSave(config);
  }

  getFieldKeys() {
    return Object.keys(this.fields);
  }

  async updateValues(
    form: BasicForm<any>,
    send = false,
    config = { sendStep: true }
  ) {
    this.updateFromJSON(form.toDict());
    if (send) await this.save(send, config);
  }

  updateFromJSON(data: any) {
    const keys = this.getFieldKeys() as Array<keyof TypeData<T>>;
    keys.forEach((key) => {
      if (!nully(data[key])) {
        const field = this.fields[key] as BasicFieldType;
        const value = field.type == 'number' ? data[key].toString() : data[key];
        this.values[key] = value;
      }
    });
  }

  async load(uuid?: string, loggedIn?: boolean): Promise<void> {
    if (uuid) this.uuid = uuid;
    try {
      await this.performLoad();
    } catch (error) {
      console.log('ERROR', error);
      if (error.request?.status == 404) {
        if (loggedIn) {
          Notify.create({
            message: "You don't have access to that resource",
            color: 'negative',
            position: 'top',
            icon: 'mdi-alert'
          });
          void Vue['Router'].push({
            path: '/'
          });
        } else {
          void Vue['Router'].push({
            name: 'login',
            query: { redirect_url: this.linkToContinue() }
          });
        }
      }
    }
  }
}
