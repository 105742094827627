var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"text-uppercase font-12 w-600 text-default q-mb-md"},[_vm._v(" "+_vm._s(_vm.t('client_services'))+" ")]),_c('services-types-list',{attrs:{"types":_vm.types},on:{"details":function ($event) { return _vm.$router.push({
            name: 'TaxServiceTypeDetails',
            params: { slug: $event }
          }); }},scopedSlots:_vm._u([{key:"top-list",fn:function(){return _vm._l((_vm.customTypes),function(extra,i){return _c('div',{key:("extra-" + i),staticClass:"q-mb-md"},[_c('services-types-card',{attrs:{"title":extra.title,"subtitle":extra.subtitle,"cta-label":extra.cta_label,"cta-id":("custom-extra-type-" + i)},on:{"on-click":function($event){return _vm.openExternal(extra.cta_link)}}})],1)})},proxy:true}])})],1),_c('div',[_c('div',{staticClass:"text-uppercase font-12 w-600 text-default q-mb-md",staticStyle:{"margin-top":"36px"}},[_vm._v(" "+_vm._s(_vm.t('client_tax_administration'))+" ")]),_c('services-table',{attrs:{"services":_vm.services,"pagination":_vm.tablePagination,"loading":_vm.loadingServices,"search-engine":_vm.search,"empty":{
        title: _vm.$t('tax.table.empty.title'),
        subtitle: _vm.$t('tax.table.empty.subtitle'),
        icon: 'img:/dashboard/images/tax-empty-state.svg'
      }},scopedSlots:_vm._u([{key:"actions",fn:function(row){return [(
            row.status &&
            row.status.tag === _vm.statusBadges.open.tag &&
            row.paymentUuid
          )?_c('div',{staticClass:"cursor-pointer font-12 text-np-primary-1100",on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('layout/openPaymentModal', {
              paymentUuid: row.paymentUuid
            })}}},[_vm._v(" "+_vm._s(_vm.$t('tax.table.actions.pay'))+" ")]):_vm._e()]}},{key:"mobile-card-cta",fn:function(row){return [(
            row.status &&
            row.status.tag === _vm.statusBadges.open.tag &&
            row.paymentUuid
          )?_c('a-primary-btn',{staticClass:"full-width q-mt-md",attrs:{"label":_vm.$t('tax.table.actions.pay')},on:{"click":function($event){return _vm.$store.dispatch('layout/openPaymentModal', {
              paymentUuid: row.paymentUuid
            })}}}):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }