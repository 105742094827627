




































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { SocialBtn } from '../../models';
import { RESOURCES } from '@ligo/bv-flow/store';
import { registrationFormFields, fieldRules } from '../hooks/auth';

const TERMS_AND_CONDITION = RESOURCES.TERMS_AND_CONDITION;
const CHECKBOX_SIZE = '45px';

export default defineComponent({
  name: 'NewRegisterForm',
  props: {
    socialBtns: {
      type: Array as PropType<Array<SocialBtn>>,
      default: () => []
    },
    brand: {
      type: String,
      default: 'ligo'
    }
  },
  setup(_, { emit }) {
    const formRef = ref();
    const registerValues = ref({});
    const passwordVisibility = ref(false);

    const onSubmit = () => {
      formRef.value.validate().then((ok) => {
        if (ok) {
          emit('perform-register', registerValues.value);
        }
      });
    };

    return {
      formRef,
      passwordVisibility,
      fieldRules,
      registrationFormFields,
      registerValues,
      TERMS_AND_CONDITION,
      CHECKBOX_SIZE,
      onSubmit
    };
  }
});
