
















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseMembershipButton',
  components: {},
  props: {
    text: String,
    fontColor: {
      type: String,
      default: 'white'
    },
    bg: {
      type: String,
      required: true
    },
    outline: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    fontSize: {
      type: String,
      default: 't1'
    },
    minWidth: {
      type: String,
      default: 'c-minw-250'
    },
    type: {
      type: String,
      default: 'button'
    }
  }
});
