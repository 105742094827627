















import { defineComponent, PropType } from '@vue/composition-api';
import { useI18n } from '../../../composables';
import ServiceTypeCard from './ServicesTypesCard.vue';
import { ExtraServiceType } from 'libs/dashboard/customer/store/src/lib/models/extra-service';

export default defineComponent({
  name: 'ServicesTypesList',
  components: { ServiceTypeCard },
  props: {
    types: {
      type: Array as PropType<ExtraServiceType[]>,
      required: true
    }
  },
  setup() {
    const i18n = useI18n();

    function primaryLabel(type: ExtraServiceType) {
      return type.localeData.cta && type.localeData.cta.secondary
        ? type.localeData.cta.secondary
        : i18n.t('service_types.cta.secondary').toString();
    }

    return { primaryLabel };
  }
});
