






















import { defineComponent, onMounted, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'TooltipContent',
  props: {
    delay: {
      type: Number,
      default: 1000
    },
    transitionHide: {
      type: String,
      default: 'scale'
    },
    transitionShow: {
      type: String,
      default: 'scale'
    },
    contentClass: {
      type: String,
      default: 'bg-secondary q-pa-md text-dark'
    },
    maxWidth: {
      type: String,
      default: '300px'
    }
  },
  setup(props, { parent }) {
    const show = ref(false);

    const timer = ref();

    function onEnter () {
      show.value = true;
      if (timer.value) clearTimeout(timer.value);
    }

    function onLeave () {
      timer.value = setTimeout(() => (show.value = false), props.delay);
    }

    onMounted(() => {
      if (parent) {
        parent.$el.addEventListener('mouseleave', onLeave);
        parent.$el.addEventListener('mouseenter', onEnter);
      }
    });

    return {
      show,
      onEnter,
      onLeave
    };
  }
});
