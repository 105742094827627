










import { defineComponent, watch, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseAjaxBar',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const bar = ref(null as any);
    watch(
      () => props.loading,
      (after, before) => {
        if (!before && after) {
          bar.value.start();
        } else {
          bar.value.stop();
        }
      }
    );
    return { bar };
  }
});
