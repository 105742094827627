
















































































import { defineComponent, PropType } from '@vue/composition-api';
import { MembershipCardType } from '../../../models';
import TextWithTooltip from '../TextWithTooltip.vue';

export default defineComponent({
  name: 'MembershipCard',
  components: { TextWithTooltip },
  props: {
    selector: Boolean,
    card: Object as PropType<MembershipCardType>,
    borderRadius: Number
  },
  computed: {
    getContent() {
      if (this.card.content[0].price) {
        if (!this.selector || this.card.content.length === 1)
          return this.card.content[0].price;
        return this.card.content[1].price;
      }
      return this.card.content[0].title;
    }
  },
  methods: {
    onRedirection() {
      if (this.card.external) window.location.assign(this.card.btnUrl);
      else this.$router.push(this.card.btnUrl);
    }
  }
});
