import { LocalResource, NotaryProductResource } from '@ligo/bv-flow/store';
import { ShareholderType } from '../../../share';
import {
  commonShareholderFieldDescriptions,
  companyShareholderFields
} from '../../shared/commonShareholderFields';
import { visible_if_true } from '../../shared/conditions';

export const ShareholderResourceDefinition: LocalResource<
  ShareholderType & {
    sole_shareholder: boolean;
    buyer_sole_shareholder: boolean;
    seller_resign: boolean;
    decharge: boolean;
    internal: boolean;
  }
> = {
  url: '#',
  locale: 'shareholder',
  fieldsDescriptions: {
    ...commonShareholderFieldDescriptions,
    ...companyShareholderFields,
    become_director: { type: 'bool-options' },
    company_name: {},
    sole_shareholder: { type: 'bool-options' },
    buyer_sole_shareholder: { type: 'bool-options' },
    seller_resign: {
      type: 'bool-options',
      dependency_dcl: {
        fieldParam: 'become_director',
        rule: visible_if_true
      }
    },
    decharge: {
      type: 'bool-options',
      dependency_dcl: {
        fieldParam: 'become_director',
        rule: visible_if_true
      }
    },
    internal: { type: 'bool-options' }
  },
  formatJSON: (data: { [id: string]: any }) => {
    if (
      data['is_existing_company'] &&
      data['address_equals_company_address'] === true
    ) {
      data['address'] = data['company_address'];
      data['location'] = data['company_location'];
      data['zip_code'] = data['company_zipcode'];
    }
    return data;
  }
};

export const shareholderResources = () => {
  return {
    p1: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p1',
      'transfer_of_shares'
    ),
    p2: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p2',
      'transfer_of_shares'
    ),
    p3: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p3',
      'transfer_of_shares'
    ),
    p4: new NotaryProductResource(
      ShareholderResourceDefinition,
      'p4',
      'transfer_of_shares'
    )
  };
};
