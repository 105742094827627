











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'InfoBanner',
  props: {
    text: {
      type: String
    },
    usePlainText: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {};
  }
});
