/*!
 * froala_editor v4.0.4 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2021 Froala Labs
 */

!(function (n, t) {
  'object' == typeof exports && 'undefined' != typeof module
    ? t(require('froala-editor'))
    : 'function' == typeof define && define.amd
    ? define(['froala-editor'], t)
    : t(n.FroalaEditor);
})(this, function (i) {
  'use strict';
  ((i =
    i && i.hasOwnProperty('default')
      ? i['default']
      : i).PLUGINS.align = function (a) {
    var l = a.$;
    return {
      apply: function r(n) {
        var t = a.selection.element();
        if (l(t).parents('.fr-img-caption').length) l(t).css('text-align', n);
        else {
          a.selection.save(),
            a.html.wrap(!0, !0, !0, !0),
            a.selection.restore();
          for (var e = a.selection.blocks(), i = 0; i < e.length; i++)
            l(e[i]).css('text-align', n).removeClass('fr-temp-div'),
              '' === l(e[i]).attr('class') && l(e[i]).removeAttr('class'),
              '' === l(e[i]).attr('style') && l(e[i]).removeAttr('style');
          a.selection.save(), a.html.unwrap(), a.selection.restore();
        }
      },
      refresh: function i(n) {
        var t = a.selection.blocks();
        if (t.length) {
          var e = a.helpers.getAlignment(l(t[0]));
          n.find('> *')
            .first()
            .replaceWith(a.icon.create('align-'.concat(e)));
        }
      },
      refreshOnShow: function o(n, t) {
        var e = a.selection.blocks();
        if (e.length) {
          var i = a.helpers.getAlignment(l(e[0]));
          t.find('a.fr-command[data-param1="'.concat(i, '"]'))
            .addClass('fr-active')
            .attr('aria-selected', !0);
        }
      },
      refreshForToolbar: function s(n) {
        var t = a.selection.blocks();
        if (t.length) {
          var e = a.helpers.getAlignment(l(t[0]));
          (e = e.charAt(0).toUpperCase() + e.slice(1)),
            'align'.concat(e) === n.attr('data-cmd') && n.addClass('fr-active');
        }
      }
    };
  }),
    i.DefineIcon('align', { NAME: 'align-left', SVG_KEY: 'alignLeft' }),
    i.DefineIcon('align-left', { NAME: 'align-left', SVG_KEY: 'alignLeft' }),
    i.DefineIcon('align-right', { NAME: 'align-right', SVG_KEY: 'alignRight' }),
    i.DefineIcon('align-center', {
      NAME: 'align-center',
      SVG_KEY: 'alignCenter'
    }),
    i.DefineIcon('align-justify', {
      NAME: 'align-justify',
      SVG_KEY: 'alignJustify'
    }),
    i.RegisterCommand('align', {
      type: 'dropdown',
      title: 'Align',
      options: {
        left: 'Align Left',
        center: 'Align Center',
        right: 'Align Right',
        justify: 'Align Justify'
      },
      html: function () {
        var n = '<ul class="fr-dropdown-list" role="presentation">',
          t = i.COMMANDS.align.options;
        for (var e in t)
          t.hasOwnProperty(e) &&
            (n += '<li role="presentation"><a class="fr-command fr-title" tabIndex="-1" role="option" data-cmd="align"data-param1="\n        '
              .concat(e, '" title="')
              .concat(this.language.translate(t[e]), '">')
              .concat(
                this.icon.create('align-'.concat(e)),
                '<span class="fr-sr-only">\n        '
              )
              .concat(this.language.translate(t[e]), '</span></a></li>'));
        return (n += '</ul>');
      },
      callback: function (n, t) {
        this.align.apply(t);
      },
      refresh: function (n) {
        this.align.refresh(n);
      },
      refreshOnShow: function (n, t) {
        this.align.refreshOnShow(n, t);
      },
      plugin: 'align'
    }),
    i.RegisterCommand('alignLeft', {
      type: 'button',
      icon: 'align-left',
      title: 'Align Left',
      callback: function () {
        this.align.apply('left');
      },
      refresh: function (n) {
        this.align.refreshForToolbar(n);
      },
      plugin: 'align'
    }),
    i.RegisterCommand('alignRight', {
      type: 'button',
      icon: 'align-right',
      title: 'Align Right',
      callback: function () {
        this.align.apply('right');
      },
      refresh: function (n) {
        this.align.refreshForToolbar(n);
      },
      plugin: 'align'
    }),
    i.RegisterCommand('alignCenter', {
      type: 'button',
      icon: 'align-center',
      title: 'Align Center',
      callback: function () {
        this.align.apply('center');
      },
      refresh: function (n) {
        this.align.refreshForToolbar(n);
      },
      plugin: 'align'
    }),
    i.RegisterCommand('alignJustify', {
      type: 'button',
      icon: 'align-justify',
      title: 'Align Justify',
      callback: function () {
        this.align.apply('justify');
      },
      refresh: function (n) {
        this.align.refreshForToolbar(n);
      },
      plugin: 'align'
    });
});
