import { useEnvironment } from '../../../../../../netlify_functions';

const { API, QUESTIONNAIRE } = useEnvironment();
const BASE_API = 'api_customer_dashboard';
const SIGNATURES_API = 'api_signatures';
const QUESTIONNAIRE_API = `${API}api_questionnaire/`;
const MEMBER_AUTH = `${API}api/member_auth`;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const RESOURCES = {
  BASE: API,
  LOGIN: `${API}api/member_auth/sign_in`,
  LOGOUT: `${API}api/member_auth/sign_out`,
  AUTH: MEMBER_AUTH,
  REGISTER: MEMBER_AUTH,
  REFRESH: 'member_auth/validate_token',
  GOOGLEOAUTH: `${API}api/member_auth/google_oauth2/?ligo_flow=api_flow&auth_origin_url=https://`,
  LINKEDINOAUTH: `${API}api/member_auth/linkedin/?ligo_flow=api_flow&auth_origin_url=https://`,
  CONFIRMATION: 'https://ligobv.netlify.app/#/confirm/',
  RETRY_CONFIRMATION: `${API}api/member_auth/confirmation`,
  ME: `${BASE_API}/me`,
  ME_ONBOARDING_COMPLETED: `${BASE_API}/me/onboarding_completed`,
  CLEAN_PROFILE_IMAGE: `/${BASE_API}/me/clean_profile_image`,
  CHANGE_PASSWORD: `${BASE_API}/me/change_password`,
  RESET_PASSWORD: '#',
  PASSWORD_CONFIRM: '#',
  MEMBERSHIPS: `${BASE_API}/membership_types`,
  MEMBERSHIP_CHANGE: `${BASE_API}/me/change_membership`,
  MEMBERSHIP_CANCEL: `${BASE_API}/me/cancel_membership`,
  PAYMENTS: `${BASE_API}/finalized_payments`,
  CONTRACTS: `${BASE_API}/contracts/`,
  CREATE_CONTRACT: (contract_type_id: number) =>
    `/${BASE_API}/contract_types/${contract_type_id}/new_contract`,
  CLAIM_CONTRACT: (uuid: string) => `/${BASE_API}/contracts/${uuid}/claim`,
  CONTRACT_SIGNING: (uuid: string) =>
    `${BASE_API}/contracts/${uuid}/signatures`,
  CREATE_CONTRACT_PAYMENT: (uuid: string) =>
    `${BASE_API}/contracts/${uuid}/payments`,
  CONTRACT_TYPES: `${BASE_API}/contract_types`,
  CONTRACT_TYPE: (slug: string) => `${BASE_API}/contract_types/${slug}`,
  CONTRACT_STATS: `${BASE_API}/me/contract_stats`,
  NOTIFICATION: `${BASE_API}/notifications`,
  NOTARY_PRODUCT: `${BASE_API}/notary_products/`,
  NOTARY_PRODUCT_DOCUMENT: (idNotaryProduct: string) =>
    `${BASE_API}/notary_products/${idNotaryProduct}/notary_product_documents`,
  NOTARY_PRODUCT_DOCUMENT_ACCEPT: (
    idNotaryProduct: string,
    idNotaryProductDocument: string
  ) =>
    `${BASE_API}/notary_products/${idNotaryProduct}/notary_product_documents/${idNotaryProductDocument}/accept`,
  NOTARY_PRODUCT_DOCUMENT_REJECT: (
    idNotaryProduct: string,
    idNotaryProductDocument: string
  ) =>
    `${BASE_API}/notary_products/${idNotaryProduct}/notary_product_documents/${idNotaryProductDocument}/reject`,
  NOTARY_PRODUCT_DOCUMENT_GET: (
    idNotaryProduct: string,
    idNotaryProductDocument: string
  ) =>
    `${BASE_API}/notary_products/${idNotaryProduct}/notary_product_documents/${idNotaryProductDocument}`,
  QUESTIONNAIRE: QUESTIONNAIRE,
  COMPANIES: `${BASE_API}/companies/`,
  OLD_CUSTOMER_DASHBOARD: '/dashboard',
  BILLING: `${BASE_API}/billings`,
  PAYMENT: (paymentId: string) => `${BASE_API}/payments/${paymentId}`,
  ALL_PAYMENTS: `${BASE_API}/payments/`,
  PARTENER_OFFERS: `${BASE_API}/partner_offers`,
  PARTENER_OFFER_LEAD: `${BASE_API}/partner_offer_leads`,
  LABELS: `${BASE_API}/labels `,
  UPLOAD_CONTRACT: (uuid: string) =>
    `${BASE_API}/contracts/${uuid}/upload_document`,
  GTM_PAYMENT_DATA: (uuid: string) =>
    `${API}/${BASE_API}/payments/${uuid}/gtm_format`,
  CONTRACT_CATEGORIES: `${BASE_API}/contract_categories`,
  ADYEN_PAYMENT: (uuid: string) =>
    `${BASE_API}/payments/${uuid}/adyen_sessions`,
  CONTRACT_CREDIT: (uuid: string) =>
    `${BASE_API}/contracts/${uuid}/use_membership_credit`,
  NOTARY_QUESTIONNAIRE: (slug: string) =>
    `${QUESTIONNAIRE_API}notaries?service_slug=${slug}`,
  NOTARY_CUSTOMER: (uuid: string) =>
    `${BASE_API}/notary_products//${uuid}/notaries`,
  CITIES_LIST: `${QUESTIONNAIRE_API}cities`,
  UPDATE_NOTARY_PRODUCT: (uuid: string) =>
    `${BASE_API}/notary_products/${uuid}/`,
  CLONE_CONTRACT: (uuid: string) => `${BASE_API}/contracts/${uuid}/clone`,
  PAGE_INFORMATION: `${API}public/page_informations`,
  CANCEL_AMENDMENT_REQUEST: (notaryProductUuid: string, docUuid: string) =>
    `${BASE_API}/notary_products/${notaryProductUuid}/notary_product_documents/${docUuid}/cancel_rejection`,
  EVENTS: `${BASE_API}/events`,
  CONTRACT_EVENTS: (uuid: string) => `${BASE_API}/contracts/${uuid}/events/`,
  UPDATE_EVENT: (uuid: string, id: number) =>
    `${BASE_API}/contracts/${uuid}/events/${id}`,
  TOGGLE_COMPLETE_EVENT: (uuid: string, id: number) =>
    `${BASE_API}/contracts/${uuid}/events/${id}/toggle_complete`,
  AI_GENERATOR_ACTIVITIES: `${BASE_API}/ai_generators/business_activities/`,
  REQUEST_CHANGES: (uuid: string) =>
    `${BASE_API}/notary_products/${uuid}/request_change`,
  EXTRA_SERVICES: `${BASE_API}/extra_services`,
  EXTRA_SERVICE_TYPES: `${BASE_API}/extra_service_types`,
  BANKING_REQUESTS: `${BASE_API}/banking_requests`,
  LAWYERS: `${BASE_API}/lawyers`,
  LAWYER: (slug: string) => `${BASE_API}/lawyers/${slug}`
};

export const SIGNATURE_RESOURCES = {
  GET_CONTRACT: (uuid: string) => `${SIGNATURES_API}/contracts/${uuid}`,
  ARCHIVE_SIGNATURE: `${SIGNATURES_API}/signatures/archive`,
  ADD_SIGNER: (contractUuid: string) =>
    `${SIGNATURES_API}/contracts/${contractUuid}/signers`,
  GET_SIGNERS: (contractUuid: string) =>
    `${SIGNATURES_API}/contracts/${contractUuid}/signers`,
  DELETE_SIGNER: (contractUuid: string, signerUuid: string) =>
    `${SIGNATURES_API}/contracts/${contractUuid}/signers/${signerUuid}`,
  UPDATE_SIGNER: (contractUuid: string, signerUuid: string) =>
    `${SIGNATURES_API}/contracts/${contractUuid}/signers/${signerUuid}`,
  SIGNERS_COMPLETE: (contractUuid: string) =>
    `${SIGNATURES_API}/contracts/${contractUuid}/signers/complete`,
  GET_SIGNER_SESSION: (
    contractUuid: string,
    signerUuid: string,
    sessionUuid: string
  ) =>
    `${SIGNATURES_API}/contracts/${contractUuid}/signers/${signerUuid}/signing_sessions/${sessionUuid}`,
  SIGN_DOCUMENT: (
    contractUuid: string,
    signerUuid: string,
    sessionUuid: string
  ) =>
    `${SIGNATURES_API}/contracts/${contractUuid}/signers/${signerUuid}/signing_sessions/${sessionUuid}/sign`,
  DECLINE_DOCUMENT: (
    contractUuid: string,
    signerUuid: string,
    sessionUuid: string
  ) =>
    `${SIGNATURES_API}/contracts/${contractUuid}/signers/${signerUuid}/signing_sessions/${sessionUuid}/decline`
};

export const EXTERNAL_RESOURCES = {
  CONTRACTS: (contract_slug: string, uuid: string) =>
    `/contract_types/${contract_slug}/contracts/${uuid}`,
  AUTHENTICATED_REDIRECTION: (redirect_url: string) =>
    `${API}api_redirections?redirect_url=${redirect_url}`,
  NOTARY_PRODUCT_CREATE: (slug: string) =>
    `${API}public/notary_product_types/${slug}/new_notary_product`,
  MAIN_CHECKUP: (slug: string) => `${QUESTIONNAIRE}legal-scan/${slug}`,
  WORD_EDITOR_SERVICE: 'https://ligo.nl/api/documenteditor',
  QUESTIONNAIRE: QUESTIONNAIRE,
  QUESTIONNAIRE_BV: `${QUESTIONNAIRE}bv/`
};
