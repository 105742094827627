






































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { BaseSimpleForm } from '../../../base';
import { useSimpleFormConfig } from '../../../base/SimpleForm/hooks/simpleForm.hooks';
import { NotaryProductSlug } from '@ligo/bv-flow/store';
import { useFormConfig } from './form-configuration.hook';
import { SimpleField } from '../../../base/SimpleForm/models/simpleForm.models';
import WarningModal from './WarningModal.vue';
import ASecondaryBtn from '../../../base/app/buttons/ASecondaryBtn.vue';

export default defineComponent({
  name: 'MenuFormCard',
  components: { ASecondaryBtn, BaseSimpleForm, WarningModal },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    notaryProductUuid: {
      type: String,
      required: true
    },
    productType: {
      type: String as PropType<NotaryProductSlug>
    },
    productData: {
      type: Object
    },
    fields: {
      type: Array as PropType<SimpleField[]>
    },
    slug: {
      type: String
    }
  },
  setup(props, { emit }) {
    const { formConfig, t, onNext } = useFormConfig(
      props.notaryProductUuid,
      props.fields,
      props.slug
    );

    const confirmationModal = ref(false);

    const { formValue, getFormValueReducer } = useSimpleFormConfig(
      formConfig,
      props.productData
    );

    const close = () => {
      confirmationModal.value = false;
      emit('input', false);
    };

    const onSave = async () => {
      const data = getFormValueReducer();
      await onNext(data);
      emit('submit', data);
      emit('input', false);
    };

    return {
      t,
      formConfig,
      formValue,
      onNext,
      onSave,
      close,
      getFormValueReducer,
      confirmationModal
    };
  }
});
