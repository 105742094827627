


































import { defineComponent, PropType } from '@vue/composition-api';
import { AssistantState, useLigoAssistant } from './SLigoAssistant';
import SLigoAssistantAvatar from './components/SLigoAssistantAvatar.vue';
import SLigoAssistantChatBalloon from './components/SLigoAssistantChatBalloon.vue';
export default defineComponent({
  name: 'SLigoAssistant',
  components: { SLigoAssistantAvatar, SLigoAssistantChatBalloon },
  props: {
    states: {
      type: Array as PropType<AssistantState[]>,
      required: true
    },
    initialState: {
      type: Number,
      default: 0
    },
    externalTransition: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, root }) {
    const {
      title,
      info,
      x,
      y,
      loadingTransition,
      state,
      end
    } = useLigoAssistant(
      props.states,
      emit,
      root.$nextTick,
      props.initialState,
      props.externalTransition
    );
    return { title, info, x, y, loadingTransition, state, end };
  }
});
