export interface StepperInterface {
  showStepper: boolean;
  step: string | null;
  stepTemporal: string | null;
  update: boolean;
}

const state: StepperInterface = {
  showStepper: false,
  step: localStorage.getItem('step'),
  stepTemporal: localStorage.getItem('step'),
  update: false
};

export default state;
