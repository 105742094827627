import {
  NotaryProduct,
  LiteNotaryProduct,
  NotaryProductStage,
  NotaryProductTypeSlug
} from '@ligo/dashboard/store';

const indigoMix = {
  color: 'indigo-1',
  textColor: 'indigo'
};

export const NotaryProductClassification = {
  open_mix: {
    color: 'deep-purple-1',
    textColor: 'deep-purple'
  },
  extra_questions: indigoMix,
  submitted_mix: indigoMix,
  checking_documents: {
    ...indigoMix,
    stages: [
      NotaryProductStage.CHECKING_DOCUMENTS,
      NotaryProductStage.LS_CHECKING_DOCUMENTS
    ]
  },
  uploading_fca: indigoMix,
  scheduling_meeting: indigoMix,
  executing_deed: indigoMix,
  extracting_kvk: {
    color: 'green-1',
    textColor: 'green'
  },
  finalized: {
    color: 'green-1',
    textColor: 'green'
  },
  on_hold: {
    color: 'deep-orange-1',
    textColor: 'deep-orange'
  },
  canceled: {
    color: 'red-1',
    textColor: 'red'
  }
};

export const NotaryProductTypes: { [key in NotaryProductTypeSlug]: string } = {
  'incorporate-holding': 'Incorporate Personal Holding',
  merkregistratie: 'Merkregistratie',
  'algemene-voorwaarden-opstellen': 'Algemene voorwaarden opstellen',
  merkbewaking: 'Merkbewaking',
  'stichting-oprichten': 'Stichting oprichten',
  'algemene-voorwaarden-controleren': 'Algemene voorwaarden controleren',
  aandelenoverdracht: 'Aandelenoverdracht',
  'statutenwijziging-naam': 'Statutenwijziging Naam',
  'bv-oprichten': 'BV oprichten',
  'anbi-stichting-oprichten': 'ANBI stichting oprichten',
  'persoonlijke-holding-oprichten': 'Persoonlijke holding oprichten',
  'incorporate-dutch-bv': 'Incorporate Dutch BV',
  'omzetting-naar-bv': 'Omzetting naar BV ',
  'stichting-derdengelden': 'Stichting derdengelden'
};

export type productClassification = {
  clickEvent: 'questionnaire' | 'pay' | 'details' | 'none';
  icon: string;
  status?: {
    label: string;
    textColor: string;
    color: string;
  };
  rowClass?: string;
  deletable?: boolean;
};

export function classifyProducts(
  notaryProduct: NotaryProduct | LiteNotaryProduct
): productClassification {
  let actions: productClassification = {
    clickEvent: 'details',
    icon: 'img:/dashboard/icons/eye.svg',
    deletable: false
  };
  switch (notaryProduct.stage) {
    case NotaryProductStage.OPEN:
      if (notaryProduct.upsell_product) {
        actions = {
          clickEvent: 'none',
          icon: '',
          deletable: false
        };
        break;
      } else {
        if (notaryProduct.payment_link) {
          actions = {
            deletable: true,
            clickEvent: 'pay',
            icon: 'img:/dashboard/icons/credit_card.png'
          };
          break;
        } else {
          actions = {
            deletable: true,
            clickEvent: 'questionnaire',
            icon: 'img:/dashboard/icons/continue.svg'
          };
          break;
        }
      }
    case NotaryProductStage.SUBMITTED:
      if (notaryProduct.paid_at) {
        actions = {
          clickEvent: 'details',
          icon: 'img:/dashboard/icons/eye.svg'
        };
        break;
      } else {
        actions = {
          deletable: true,
          clickEvent: 'questionnaire',
          icon: 'img:/dashboard/icons/continue.svg'
        };
        break;
      }
    case NotaryProductStage.CANCELLED:
      actions.clickEvent = 'none';
      actions.rowClass = 'action-table-row-cancelled';
      break;
  }

  let status = NotaryProductClassification[notaryProduct.stage];
  if (notaryProduct.stage == NotaryProductStage.LS_CHECKING_DOCUMENTS) {
    status = NotaryProductClassification.checking_documents;
  }

  return {
    status,
    ...actions
  };
}

export function isOpen(notaryProduct: NotaryProduct): boolean {
  return notaryProduct.stage == NotaryProductStage.OPEN;
}

export function isSubmitted(notaryProduct: NotaryProduct): boolean {
  return (
    notaryProduct.stage == NotaryProductStage.EXTRA_QUESTIONS ||
    notaryProduct.stage == NotaryProductStage.SUBMITTED
  );
}

export function isPendingPayment(notaryProduct: NotaryProduct): boolean {
  return isSubmitted(notaryProduct) && !notaryProduct.paid_at;
}

export function isSubmittedAndPaid(notaryProduct: NotaryProduct): boolean {
  return isSubmitted(notaryProduct) && !!notaryProduct.paid_at;
}
