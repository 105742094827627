























import { defineComponent } from '@vue/composition-api';

const STAGES = [
  'product.state.extra_questions',
  'product.state.read_upload',
  'product.state.check_doc',
  'product.state.deed',
  'product.state.meeting',
  'product.state.finished'
];

export default defineComponent({
  name: 'StateStepper',
  props: {
    currentStage: {
      type: Number
    }
  },
  setup() {
    return { STAGES };
  }
});
