import { ApiService } from './../../../../../../shared/utils/src/lib/services/api.service';
import { useGtm } from '@gtm-support/vue2-gtm';
import { RESOURCES } from '../resources';

export async function sendGTMPaymentEvent(paymentUuid: string) {
  const gtm = useGtm();
  try {
    const response = await ApiService.get(
      RESOURCES.GTM_PAYMENT_DATA(paymentUuid)
    );
    gtm.trackEvent(response.data);
  } catch {
    (error) => console.error(error);
  }
}
