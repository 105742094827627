/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import 'quasar/dist/quasar.ie.polyfills.js';

import './styles';

import iconSet from 'quasar/icon-set/mdi-v5';
import Vue, { VueConstructor } from 'vue';
import { Notify, QuasarPluginOptions, GlobalQuasarIconSet, Meta } from 'quasar';

import createApp from './app';
import { globalInstall } from './installGlobalComponents';

import { BootFunction, QuasarAppOptions } from './options';

//Import Froala Editor
import 'froala-editor/js/froala_editor.pkgd.min.js';

Vue.config.devtools = true;
Vue.config.productionTip = false;

const publicPath = `/`;
const quasarDefaultOptions = {
  config: {},
  css: ['app.sass'],
  iconSet: iconSet as GlobalQuasarIconSet,
  plugins: { Notify, Meta }
};

export async function startQuasarApp<S>(
  App: VueConstructor,
  bootFiles: Array<BootFunction>,
  options: QuasarAppOptions<S>,
  quasar: Partial<QuasarPluginOptions> = quasarDefaultOptions
) {
  globalInstall();

  const { app, store, router } = await createApp(App, options, quasar);

  Vue['Store'] = store;
  Vue['Router'] = router;

  let hasRedirected: any = false;
  const redirect = (url) => {
    hasRedirected = true;
    const normalized =
      Object(url) === url ? router.resolve(url).route.fullPath : url;

    window.location.href = normalized;
  };

  const urlPath = window.location.href.replace(window.location.origin, '');

  for (let i = 0; hasRedirected === false && i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue;
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null,
        redirect,
        urlPath,
        publicPath
      });
    } catch (err) {
      if (err && err.url) {
        window.location.href = err.url;
        return;
      }
      console.error('[Quasar] boot error:', err);
      return;
    }
  }

  if (hasRedirected === true) {
    return;
  }

  new Vue(app);
}
