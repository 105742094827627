import { initCanvas, getCanvasFont, fitTextToWidth, fitTextToHeight } from './';

export class CanvasEngine {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  fontType: string;

  constructor(width: number, height: number, fontType = 'Serif') {
    const { canvas, context } = initCanvas(width * 10, height * 10);
    this.canvas = canvas;
    this.context = context;
    this.fontType = fontType;
  }

  setDimensions(width: number, height: number) {
    this.canvas.width = width;
    this.canvas.height = height;
  }

  setFontType(fontType: string) {
    this.fontType = fontType;
    const { fontSize } = getCanvasFont(this.context);
    this.context.font = `${fontSize}px ${fontType}`;
  }

  setText(text: string) {
    this.context.textBaseline = 'middle';
    this.context.textAlign = 'center';
    this.context.fillText(
      text,
      this.canvas.width / 2,
      this.canvas.height / 2 + 2
    );
  }

  setTextFullWidth(text: string) {
    const fontSize = fitTextToWidth(this.canvas, text);
    this.context.font = `${fontSize}px ${this.fontType}`;
    this.setText(text);
  }

  setTextFullHeight(text: string) {
    const fontSize = fitTextToHeight(this.canvas, text);
    this.context.font = `${fontSize}px ${this.fontType}`;

    // readjusting the canvas width to fit the text
    this.canvas.width = this.context.measureText(text).width;

    // reapplying the font size after changing the canvas width
    this.context.font = `${fontSize}px ${this.fontType}`;

    this.setText(text);
  }

  getTextMetrics(text: string) {
    return this.context.measureText(text);
  }

  drawImage(image: CanvasImageSource) {
    this.context.drawImage(image, 0, 0, this.canvas.width, this.canvas.height);
  }

  getImage(imageFormat = 'image/png') {
    return this.canvas.toDataURL(imageFormat);
  }

  clearCanvas() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }
}

export function useCanvasEngine(width: number, height: number) {
  const canvasEngine = new CanvasEngine(width, height);
  return canvasEngine;
}
