import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '../resources';
import { getLocaleMapped } from './internationalization.service';
import { Option } from '@ligo/shared/mvc';

export interface CountryModel {
  uuid: string;
  code: string;
  risk_level: {
    level: string;
  };
  en: {
    name: string;
    nationality: string;
  };
  nl: {
    name: string;
    nationality: string;
  };
  priority: number;
}

const BLACKLIST_KEY = 'blacklist';

const apiResponse = {
  name: {
    nl: [],
    en: []
  },
  nationality: {
    nl: [],
    en: []
  }
};

const SEPARATOR = '---';

export const getBaseCountryList = (
  orderField: 'name' | 'nationality' = 'name',
  filter = false
) => {
  const locale = getLocaleMapped();

  const responseHandler = (data: CountryModel[]) => {
    const transformedData = data.map((country) => ({
      value: country.code,
      label: country[locale][orderField],
      priority: country.priority
    }));

    const front = transformedData.filter((country) => country.priority > 0);
    const tail = transformedData.filter((country) => country.priority == 0);

    front.push(
      {
        value: SEPARATOR,
        label: SEPARATOR,
        priority: 1
      },
      ...tail
    );
    return front;
  };

  if (apiResponse[orderField][locale].length > 0) {
    return new Promise<Option[]>((resolve) => {
      resolve(apiResponse[orderField][locale]);
    });
  }

  return ApiService.get<CountryModel[]>(RESOURCES.COUNTRIES, {
    order_field: orderField,
    locale: locale
  }).then((response) => {
    apiResponse[orderField][locale] = responseHandler(
      filter
        ? response.data.filter(
            (country) => !(country.risk_level.level === BLACKLIST_KEY)
          )
        : response.data
    );
    return apiResponse[orderField][locale];
  });
};

export const getCountryList = (filter = false) => {
  return getBaseCountryList('name', filter);
};

export const getNationalityList = (filter = false) => {
  return getBaseCountryList('nationality', filter);
};
