






















































import { defineComponent } from '@vue/composition-api';
import { APrimaryBtn } from './app/buttons';

export default defineComponent({
  name: 'BasePage',
  components: { APrimaryBtn },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    rightBtnText: {
      type: String
    },
    goBack: {
      type: String
    }
  }
});
