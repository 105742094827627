







































































import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import NavItemList from './drawer-components/NavItemList.vue';
import { ABtn } from '../components/base/app/buttons/';
import { Screen } from 'quasar';
import { useCustomerAssistant } from '../components/base/utils/assistant/assistant-setup';
import CustomerAssistant from '../components/base/utils/assistant/CustomerAssistant.vue';
import { useRouter } from '../composables';

const BREAK_POINT = 750;
const BOOKKEEPING_URL = 'TaxServices';

export default defineComponent({
  name: 'CustomerDrawer',
  components: {
    NavItemList,
    ABtn,
    CustomerAssistant
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const mini = ref(false);
    const router = useRouter();
    const logoMini = 'img:/dashboard/icons/sidebar/logo_mini.svg';
    const logo = 'img:/dashboard/icons/sidebar/new_logo.svg';

    const onMobile = computed(() => {
      return Screen.width <= BREAK_POINT;
    });

    /* Assistant */
    const bookkeepingLinkClicked = ref(false);
    const bookkeepingPageLeaved = ref(false);
    const bookkeepingUrlIgnored = ref(false);
    const assistantConditions = {
      drawer_bookkeeping_click: computed(() => bookkeepingLinkClicked.value),
      bookkeeping_page_leaved: computed(() => bookkeepingPageLeaved.value),
      bookkeeping_url_ignored: computed(() => bookkeepingUrlIgnored.value)
    };

    const {
      assistantStates,
      isActive: isAssistantActive,
      hiddenAssistant,
      loadingAssistant,
      finalizeAssistant,
      hideAssistant,
      handleNextClick: handleAssistantNextClick,
      handleDismissClick: handleAssistantDismissClick
    } = useCustomerAssistant(
      'assistant/getBookkeepingAssistantState',
      'assistant/activateBookkeepingAssistant',
      'assistant/deactivateBookkeepingAssistant',
      'bookkeeping_tour',
      assistantConditions,
      {
        bookkeeping_page_leaved: () => {
          bookkeepingPageLeaved.value = false;
          bookkeepingLinkClicked.value = false;
        }
      }
    );

    const showAssistant = computed(
      () =>
        isAssistantActive.value &&
        !loadingAssistant.value &&
        !hiddenAssistant.value
    );

    /* ******************** */

    function changeState(state: boolean) {
      emit('change', state);
    }

    function navigateHome() {
      if (router.currentRoute.name !== 'Home') {
        router.push({ name: 'Home' });
      }
    }

    watch(
      () => router.currentRoute,
      (newRoute, oldRoute) => {
        if (newRoute.name === BOOKKEEPING_URL)
          bookkeepingLinkClicked.value = true;
        else if (oldRoute && oldRoute.name === BOOKKEEPING_URL)
          bookkeepingPageLeaved.value = true;
        else if (oldRoute && newRoute.name !== BOOKKEEPING_URL)
          bookkeepingUrlIgnored.value = true;
      },
      { immediate: true }
    );

    return {
      logo,
      logoMini,
      mini,
      onMobile,
      BREAK_POINT,
      assistantStates,
      showAssistant,
      finalizeAssistant,
      hideAssistant,
      handleAssistantNextClick,
      handleAssistantDismissClick,
      changeState,
      navigateHome
    };
  }
});
