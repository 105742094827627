import { Dictionary } from '@ligo/shared/utils';

export interface pageInformationState {
  pages: Dictionary<{ data: Dictionary<any> }>;
  localePrefix: string;
}

const state: pageInformationState = {
  pages: {},
  localePrefix: ''
};

export default state;
