import { Dictionary, nully } from '@ligo/shared/utils';
import { FieldDcl, SelectableOptions, FieldType } from './FieldDcl';

// Classes are functions in js (look at the typeof of a Class)
//
export type GConstructor<T = any> = new (...args: any[]) => T;

export interface BasicFieldInterface {
  key: string;
  type: FieldType;
  value: any;
  label: string;
  localePrefix: string;
  labelTooltip: string;
  defaultValue: any;
  rules?: Array<any>;
}

// Basic Field class, to handle what it's common to all fields
class BasicField<T> implements BasicFieldInterface {
  key: string;
  type: FieldType;
  value: any = null;
  label: string;
  localePrefix: string;
  labelTooltip: string;
  defaultValue: T;
  rules?: Array<any>;

  constructor(
    key: string,
    locale: string,
    field_dcl: FieldDcl<T>,
    field_val?: T
  ) {
    this.key = key;
    this.localePrefix = locale;
    this.rules = [];
    this.initializeLocaleValues();
    Object.assign(this, field_dcl);
    if (field_dcl.defaultValue != null) {
      this.value = field_dcl.defaultValue;
    }
    this.value = !nully(field_val) ? field_val : null;
  }

  processValue(value) {
    return value;
  }

  initializeLocaleValues() {
    this.label = `${this.localePrefix}.labels.${this.key}`;
    this.labelTooltip = `${this.localePrefix}.label_tooltip.${this.key}`;
  }
}

type Fieldable<T> = GConstructor<BasicField<T>>;
// Field type mixins
function TextField<TBase extends Fieldable<string>>(Base: TBase) {
  return class extends Base {
    type: 'text' | 'textarea';
    mask?: string;
    placeholder?: string;
    filterable = false;
    filter?: (v) => any;
    suffix?: string;

    initializeLocaleValues() {
      super.initializeLocaleValues();
      this.placeholder = `${this.localePrefix}.placeholders.${this.key}`;
    }

    processValue(value: string, onInput = false): string {
      if (onInput) {
        return this.filterable && this.filter ? this.filter(value) : value;
      } else {
        let result = value;
        if (!nully(value)) {
          if (this.filter) {
            result = this.filter(value);
          }
          if (this.suffix) {
            result += this.suffix;
          }
        }
        return result;
      }
    }
  };
}

function NumericField<TBase extends Fieldable<any>>(Base: TBase) {
  return class extends Base {
    type: 'number';
    step?: number;
    mask?: string;

    processValue(value) {
      return this.mask == 'integer' && value != null
        ? value.toString().replace('.', '')
        : value;
    }
  };
}

function CheckboxField<TBase extends Fieldable<boolean>>(Base: TBase) {
  return class extends Base {
    type: 'checkbox';
    checkbox_label?: string;

    initializeLocaleValues() {
      super.initializeLocaleValues();
      this.checkbox_label = `${this.localePrefix}.checkboxes.${this.key}`;
    }
  };
}

function DateTimeField<TBase extends Fieldable<string>>(Base: TBase) {
  return class extends Base {
    type: 'datepicker' | 'timepicker';
    defaultDateValue: string;
    datetimeOptions?: (date: any) => boolean;
    defaultYearMonth?: string;
  };
}

function SelectableField<TBase extends Fieldable<any>>(Base: TBase) {
  return class extends Base {
    type: 'select' | 'multi-select' | 'options' | 'bool-options';
    options?: SelectableOptions;
    ids?: boolean;
    filter?: (v: any) => any;
    filterable?: boolean;

    initializeLocaleValues() {
      super.initializeLocaleValues();
      this.filterable = this.filterable ?? false;
    }
  };
}

// Basic Field types in case no customization is needed
export class BasicTextField extends TextField(BasicField)<string> {}

export class BasicNumericField extends NumericField(BasicField)<number> {}

export class BasicCheckboxField extends CheckboxField(BasicField)<boolean> {}

export class BasicDateTimeField extends DateTimeField(BasicField)<string> {}

export class BasicSelectableField extends SelectableField(BasicField)<any> {}

export type BasicFieldType =
  | BasicField<any>
  | BasicTextField
  | BasicNumericField
  | BasicCheckboxField
  | BasicDateTimeField
  | BasicSelectableField;

export const BasicFieldClassMap: Dictionary<
  GConstructor<BasicFieldInterface>
> = {
  text: BasicTextField,
  phone: BasicTextField,
  textarea: BasicTextField,
  number: BasicNumericField,
  timepicker: BasicDateTimeField,
  datepicker: BasicDateTimeField,
  'bool-options': BasicSelectableField,
  select: BasicSelectableField,
  'multi-select': BasicSelectableField,
  options: BasicSelectableField,
  password: BasicTextField,
  checkbox: BasicCheckboxField
};

export {
  BasicField,
  TextField,
  NumericField,
  CheckboxField,
  DateTimeField,
  SelectableField
};
