













































































































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { BasicField, BasicTextField } from '../models/Field';
import OptionsField from './field-types/OptionsField.vue';
import { useAsyncOptions } from './base-field.hooks';
import TrieSearch from 'trie-search';
import LabelTooltip from './label-tooltip/LabelTooltip.vue';
import LabelButton from './label-tooltip/LabelButton.vue';
import { nully } from '@ligo/shared/utils';
import PhoneField from './field-types/PhoneField.vue';

export default defineComponent({
  name: 'BaseField',
  components: { OptionsField, LabelTooltip, LabelButton, PhoneField },
  props: {
    value: {
      type: null as any,
      required: true
    },
    field: {
      type: BasicField,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    manualError: {
      type: Boolean,
      default: false
    },
    lazyRules: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, root }) {
    const passwordVisibility = ref(true);
    const updateValue = (a: any) => {
      if (nully(props.field.type)) {
        const field = props.field as BasicTextField;
        a = field.processValue(a, true);
      } else a = props.field.processValue(a);
      emit('input', a);
    };

    const { options, load, loading } = useAsyncOptions(props.field, { root });

    let optionsTrie;
    load().then(() => {
      if (
        props.field.type === 'select' ||
        props.field.type === 'multi-select'
      ) {
        optionsTrie = new TrieSearch('label', { min: 1 });
        optionsTrie.addAll(options.value);
      }
    });

    const getOptions = computed(() => {
      return options.value;
    });

    const filterTerm = ref('');
    const getFilteredOptions = computed(() => {
      if (filterTerm.value) {
        return optionsTrie.get(filterTerm.value);
      } else {
        return getOptions.value;
      }
    });

    const filterOptions = (val, update) => {
      update(() => {
        filterTerm.value = val;
      });
      return;
    };

    const getType = computed(() => {
      if (props.field.type == 'password') {
        return passwordVisibility.value ? 'password' : 'text';
      }
      return props.field.type != 'datepicker' &&
        props.field.type != 'timepicker'
        ? props.field.type
        : 'text';
    });

    const parentError = computed(() => (props.manualError ? true : null));

    const labelTooltip = ref(false);

    return {
      props,
      updateValue,
      passwordVisibility,
      getType,
      getOptions,
      getFilteredOptions,
      filterOptions,
      emit,
      parentError,
      labelTooltip,
      loading,
      onToggleTooltip() {
        labelTooltip.value = !labelTooltip.value;
      }
    };
  }
});
