import { TokenService, AuthToken } from '@ligo/shared/utils';
import { DefaultUser, AuthError } from '../models';

export interface AuthState {
  authenticating: boolean;
  accessToken: AuthToken | null;
  checkToken: boolean;
  authenticationError: AuthError | null;
  openDialog: boolean;
  authMethod: boolean;
  user: DefaultUser | null;
}

const state: AuthState = {
  authenticating: false,
  accessToken: TokenService.getToken(),
  checkToken: TokenService.checkToken(),
  authenticationError: null,
  openDialog: false,
  authMethod: false,
  user: null
};

export default state;
