import { ActionTree, Commit } from 'vuex';
import VueI18n from 'vue-i18n';
import Router from 'vue-router';
import { Notify } from 'quasar';

import {
  ApiService,
  TokenInterface,
  AuthToken,
  TokenService
} from '@ligo/shared/utils';

import { AuthState } from './state';
import { AuthActionHandlers, AuthResources, DefaultUserInfo } from '../models';
import { UserService } from '../services/user.service';

function mount401Interceptor(router: Router, commit: Commit) {
  ApiService.mount401Interceptor((error: any) => {
    if (error.request.status == 401) {
      ApiService.removeHeader();
      TokenService.removeToken();
      commit('logoutSuccess');
    }
    throw error;
  });
}

export const actionsFactory = <StateInterface, UserInfo = DefaultUserInfo>(
  userService: UserService,
  actionHandlers: AuthActionHandlers,
  resources: AuthResources,
  router: Router,
  i18n: VueI18n
): ActionTree<AuthState, StateInterface> => ({
  mount401Interceptor({ commit }) {
    mount401Interceptor(router, commit);
  },
  async auth(
    { commit, dispatch },
    { data, registration = false }
  ): Promise<boolean> {
    commit('authRequest');
    try {
      const token = await userService.auth(data, registration);

      if (token) {
        commit('authSuccess', token);
        actionHandlers &&
          actionHandlers.onAuth &&
          actionHandlers.onAuth(dispatch, commit, userService);
        return token;
      }
    } catch (e) {
      commit('authError', {
        errorCode: e.errorCode || 'not_confirmed',
        errorMessage: e.message,
        errorData: e.data
      });
      return false;
    }
  },
  async auth_redirection({ commit, dispatch }, data) {
    commit('authRequest');
    const token = await userService.redirection_auth(data);
    mount401Interceptor(router, commit);
    commit('authSuccess', token);
    dispatch('loadUserInfo');
    return token;
  },
  logout({ commit, dispatch }) {
    void userService.logout().then(() => {
      commit('unsetUser');
      actionHandlers &&
        actionHandlers.onLogout &&
        actionHandlers.onLogout(dispatch, commit);
      commit('logoutSuccess');
    });
  },
  async saveToken({ commit }, tokenData: TokenInterface) {
    const token = new AuthToken(undefined, tokenData);
    token.save();
    commit('authSuccess', token);
    Notify.create({
      message: i18n.t('auth.login.succeeded') as string,
      color: 'dark',
      position: 'top',
      icon: 'mdi-emoticon'
    });
    const response = await ApiService.get<UserInfo>(resources.ME);
    token.firstname = response.data['data'].firstname;
    token.lastname = response.data['data'].lastname;
    token.save();
  },
  resetPassword(_, { data }) {
    const response = userService.resetPassword(data);
    return response;
  },
  changePassword(_, data) {
    const response = userService.changePassword(data);
    return response;
  },
  async confirm({ commit }, token): Promise<boolean> {
    commit('authRequest');
    const result = await userService.confirm(token);
    if (result) {
      commit('confirmRequest');
    }
    return result;
  },
  openLogin({ commit }) {
    commit('openLogin');
  },
  cleanErrors({ commit }) {
    commit('cleanErrors');
  },
  openRegister({ commit }) {
    commit('openRegister');
  },
  closeDialog({ commit }) {
    commit('closeDialog');
  },
  async loadUserInfo({ commit }) {
    commit('setUser', await userService.me());
  },
  restrictFromFreeContract({ commit }) {
    commit('restrictFromFreeContract');
  }
});
