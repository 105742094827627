






import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'StartsRating',
  props: {
    rating: {
      type: Number,
      default: 0
    },
    width: {
      type: String,
      default: '85px'
    },
    height: {
      type: String,
      default: '35px'
    }
  },
  setup(props) {
    const stars = ref(props.rating);

    if (isNaN(stars.value)) {
      stars.value = 0;
    }
    const iconName = `img:/icons/${stars.value.toString()}_stars.svg`;
    return { iconName };
  }
});
