




































































































































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  Ref,
  ref
} from '@vue/composition-api';
import { BasicForm, buildRule } from '@ligo/shared/mvc';
import { SocialBtn, LoginTexts } from '../../models';
import { Dictionary } from '@ligo/shared/utils';
import { AuthError } from '@ligo/bv-flow/store';
import BannerError from '../BannerError.vue';
import SocialCard from './SocialCard.vue';
import { useHandleLoginSteps, AUTH_STEPS, emailRules } from '../hooks/auth';
import FormCard from './FormCard.vue';

export default defineComponent({
  name: 'NewLogin',
  components: { BannerError, SocialCard, FormCard },
  props: {
    socialBtns: {
      type: Array as PropType<Array<SocialBtn>>,
      default: () => []
    },
    texts: {
      type: Object as PropType<LoginTexts>,
      default: () => ({
        userLabel: 'user.labels.email',
        userPlaceholder: 'user.placeholders.email',
        passLabel: 'user.labels.password',
        passPlaceholder: 'user.placeholders.password'
      })
    },
    appRoot: String,
    providerLinks: { type: Object as PropType<Dictionary> },
    brand: {
      type: String,
      default: 'ligo'
    },
    brandIcon: {
      type: String,
      required: true
    }
  },
  setup(props, { root, emit }) {
    const baseForm: Ref<any> = ref();

    const loginError: ComputedRef<AuthError> = computed(() => {
      return root.$store.state.authentication.authenticationError;
    });

    const performLogin = function () {
      root.$store.dispatch('authentication/cleanErrors');
      emit('perform-login', email, password);
    };

    const formMailRef = ref();

    const socialAuth = function (socialBtn: SocialBtn) {
      emit('social-auth', socialBtn);
    };

    const email = ref('');
    const password = ref('');
    const passwordVisibility = ref(false);

    const passwordRules = [
      buildRule('required', 'validations.required_generic')
    ];

    const {
      currentStep,
      moveStep,
      backStep,
      goToFirstStep
    } = useHandleLoginSteps();

    const emailForReset = ref('');

    return {
      performLogin,
      baseForm,
      socialAuth,
      email,
      password,
      passwordVisibility,
      emailRules,
      passwordRules,
      loginError,
      currentStep,
      moveStep,
      backStep,
      goToFirstStep,
      AUTH_STEPS,
      emailForReset,
      formMailRef
    };
  }
});
