import { EnglishBVTypes } from '../definitions/english_bv';
import { LocalResource, NotaryProductResource } from '@ligo/bv-flow/store';
import {
  commonProductFields,
  sharedBVProductFields,
  sharedCompanyFields,
  sharedEmployeeFields
} from '../../shared/commonProductFields';
import { typeBvOptions } from '../../../share';

export const bvOprichten: LocalResource<EnglishBVTypes> = {
  url: '',
  locale: 'main',
  fieldsDescriptions: {
    bv_type: {
      type: 'select',
      options: typeBvOptions,
      ids: true,
      hidden: true
    },
    ...sharedBVProductFields,
    ...commonProductFields,
    ...sharedCompanyFields,
    ...sharedEmployeeFields
  }
};

export function useMainResource() {
  return { main: new NotaryProductResource(bvOprichten, 'main', 'english_bv') };
}
