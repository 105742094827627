import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import { Notify } from 'quasar';
import {
  TokenService,
  AuthToken,
  TokenInterface,
  ApiService
} from '@ligo/shared/utils';
import { AuthResources, DefaultUser } from '../models';

class AuthenticationError extends Error {
  constructor(public errorCode, public message, public data) {
    super(message);
    this.name = this.constructor.name;
    this.data = data;
  }
}

function cleanData(): void {
  TokenService.removeToken();
  ApiService.removeHeader();
  ApiService.unmount401Interceptor();
}

export const userServiceFactory = (
  router: VueRouter,
  resources: AuthResources,
  i18n: VueI18n
) => ({
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
   **/
  auth: async function (data: any, registration: boolean): Promise<any> {
    const message = registration ? 'alerts.register.' : 'alerts.login.';
    try {
      cleanData();
      const url = registration ? resources.REGISTER : resources.LOGIN;
      const response = await ApiService.post(url, data);
      const token = new AuthToken(response);
      token.save();
      ApiService.setAuth();

      const meResponse: any = await ApiService.get(resources.ME);

      token.userHash = meResponse.data.user_hash;
      token.save();

      const notification = i18n.t(`${message}success`) as string;

      Notify.create({
        message: notification,
        color: 'dark',
        position: 'top',
        icon: 'mdi-emoticon'
      });

      return token;
    } catch (error) {
      const errorCode: string = error.response.data.error_code;
      const errorMessage: string = registration
        ? error.response.data.errors.full_messages[0]
        : error.response.data.errors[0];
      if (registration) {
        Notify.create({
          message: errorMessage,
          color: 'negative',
          position: 'top',
          icon: 'mdi-alert'
        });
      }

      throw new AuthenticationError(
        errorCode,
        errorMessage,
        error.response.data
      );
    }
  },
  async redirection_auth(data: TokenInterface) {
    cleanData();
    const token = new AuthToken(undefined, data);
    token.save();
    ApiService.setAuth();
    const response: any = await ApiService.get(resources.ME);
    token.firstname = response.data.firstname;
    token.lastname = response.data.lastname;
    token.email = response.data.email;
    token.userHash = response.data.user_hash;
    token.userUuid = response.data.uuid;
    token.save();
    ApiService.setAuth();
    return token;
  },
  confirm: async function (token: any): Promise<boolean> {
    try {
      await ApiService.post(resources.CONFIRMATION, {
        token: token
      });
      return true;
    } catch (error) {
      return false;
    }
  },
  resetPassword: async function (data: any) {
    const response = ApiService.post(resources.RESET_PASSWORD, data);
    return response;
  },
  changePassword: async function (data: any) {
    const response = ApiService.post(resources.PASSWORD_CONFIRM, data);
    return response;
  },

  me: async function () {
    const response = await ApiService.get<DefaultUser>(resources.ME);
    const user: DefaultUser = {
      id: response.data.id,
      firstname: response.data.firstname,
      lastname: response.data.lastname,
      phone: response.data.phone,
      email: response.data.email,
      uuid: response.data.uuid,
      locale: response.data.locale,
      membership: response.data.membership,
      did_receive_contract_for_free:
        response.data.did_receive_contract_for_free,
      confirmed_at: response.data.confirmed_at,
      downgrade_date: response.data.downgrade_date
    };
    return user;
  },

  /**
   * Logout the current user by removing the token from storage.
   **/
  async logout() {
    const response = await ApiService.delete(resources.LOGOUT).catch(
      (error) => {
        console.log('__LOGOUT ERROR', error);
      }
    );
    cleanData();
    return response;
  },

  interceptCallback() {
    const logout = this.logout;
    return (error: any) => {
      if (error.request.status == 401) {
        ApiService.removeHeader();
        TokenService.removeToken();
        void router.push({ name: 'login' });
        logout();
      }
      throw error;
    };
  }
});

export type UserService = ReturnType<typeof userServiceFactory>;
