export interface PdfPage {
  page_number: number;
  preview_image: string;
}

export interface LastRevision {
  id: number;
  pdf_document?: string;
  word_document?: string;
  pdf_signed_document?: string;
  signed_document?: boolean;
  png_preview?: string;
  signing_log: string;
  pdf_pages?: PdfPage[];
  waiting_for_documents: boolean;
}

interface Payment {
  uuid: string;
}

export interface Contract {
  id: number;
  uuid: string;
  name: string;
  status: ContractStatus;
  badge_status: ContractBadgeStatus;
  contract_status: ContractBadgeStatus;
  contract_type?: ContractType;
  created_at: string;
  updated_at: string;
  last_revision?: LastRevision | null;
  paid?: boolean;
  prepare_url: string | null;
  signrequest_sent_at: string;
  payment: Payment;
  act_as_paid: boolean;
  free: boolean;
  signed: boolean;
  signers: Signer[];
  signing_expiration_date: string;
  signing_expired: boolean;
  uploaded: boolean;
}

export interface LiteContract {
  uuid: string;
  name: string;
  status: ContractStatus;
  contract_status: ContractBadgeStatus;
  contract_type?: ContractType;
  updated_at: string;
}

export interface Signer {
  email: string;
  name: string;
  aasm_state: SignerAASMState;
  signed_at: string;
  declined_at: string;
  uuid?: string;
  signing_session?: SigningSession;
}

export interface SigningSession {
  uuid?: string;
}

export enum ContractStatus {
  OPEN = 'open',
  INTERMEDIATE = 'intermediate',
  SUBMITTED = 'submitted'
}

export enum ContractBadgeStatus {
  EDITING = 'editing', // just created, not sent for approval/signing
  AWAITING_APPROVAL = 'awaiting_approval', // sent for approval but not approved
  APPROVED = 'approved', // approval sent and approved
  SENT_FOR_SIGNING = 'sent_for_signing', // sent to SignRequest, if approvals required then all approved
  ACTIVE = 'active', // signed
  EXPIRED = 'expired',
  DECLINED = 'declined'
}

export enum SignerAASMState {
  OPEN = 'open',
  SIGNED = 'signed',
  DECLINED = 'declined'
}

export interface ContractType {
  id: number;
  name: string;
  slug: string;
  description: string;
  locale: string;
  price: string;
  allow_for_free: boolean;
}

export interface ContractCategory {
  id: number;
  name: string;
  slug: string;
}

export interface Employee {
  uuid: string;
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  infix?: string;
  locale?: string;
  phone?: string;
  dob?: string;
  login_enabled?: boolean;
  seat_holder: boolean;
}
